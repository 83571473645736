import { ISitterReview } from "../models";
import { HttpClient } from "../../HttpClient";

export default class SitterReviewsHttpClient {
  static list = (): Promise<Array<ISitterReview>> => {
    return HttpClient.get<Array<ISitterReview>>("sitter_reviews/");
  };

  static retrieve = (id: number): Promise<ISitterReview> => {
    return HttpClient.get<ISitterReview>(`sitter_reviews/${id}/`);
  };

  static create = (review: ISitterReview): Promise<ISitterReview> => {
    return HttpClient.post<ISitterReview>("sitter_reviews/", review);
  };

  static update = (review: ISitterReview): Promise<ISitterReview> => {
    return HttpClient.put<ISitterReview>(
      `sitter_reviews/${review.id}/`,
      review
    );
  };

  static updateOrCreate = (review: ISitterReview): Promise<ISitterReview> => {
    return review.id === null
      ? SitterReviewsHttpClient.create(review)
      : SitterReviewsHttpClient.update(review);
  };
}
