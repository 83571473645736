import React, { useEffect, useState } from "react";
import VacancyListStore from "../../storeroom/vacancies/VacancyListStore";
import { useDocumentTitle, useListingStore } from "../hooks";
import { useObserver } from "mobx-react";
import PageToolbar from "../base/PageToolbar";
import { ICrumbProps } from "../base/Crumbs";
import { IPageToolbarAction } from "../base/PageToolbarActions";
import { IVacancyListItem } from "../../storeroom/vacancies/models";
import LoadingSection from "../base/LoadingSection";
import VacancySummarySection from "./sections/VacancySummarySection";
import VacancyAddForm from "./VacancyAddForm";
import { IVacancy } from "../../storeroom/homes/models";
import { useHistory } from "react-router-dom";
import { useURLParams } from "../../hooks";
import VacancyStore from "../../storeroom/vacancies/VacancyStore";
import VacancyEditModal from "./modals/VacancyEditModal";

export default function VacancyListPage() {
  const history = useHistory();
  const listingStore = useListingStore();
  const vacancyListStore: VacancyListStore = listingStore.vacancyListStore;

  const urlParams = useURLParams();
  const viewCompleted = urlParams.get("completed") === "true";

  const [showAddForm, setShowAddForm] = useState(false);
  const [vacancyStore, setVacancyStore] = useState<VacancyStore>(null);

  useDocumentTitle("Vacancies");

  useEffect(() => {
    listingStore.load();
  }, [listingStore]);

  useEffect(() => {
    vacancyListStore.load(viewCompleted);
  }, [vacancyListStore, viewCompleted]);

  const crumbs: ICrumbProps = {
    currentLabel: "Vacancies",
    links: [
      {
        label: "My Home",
        url: "/home",
      },
      {
        label: "Listing",
        url: "/listing",
      },
    ],
  };

  if (viewCompleted) {
    crumbs.currentLabel = "Completed";
    crumbs.links.push({
      label: "Vacancies",
      url: "/vacancies",
    });
  }

  const actions: Array<IPageToolbarAction> = viewCompleted
    ? []
    : [
        {
          label: "View Completed",
          icon: "history",
          onClick: () => history.push("/vacancies/?completed=true"),
        },
        {
          label: "Add Vacancy",
          icon: "add",
          disabled: listingStore.listing === undefined,
          onClick: () => {
            setVacancyStore(new VacancyStore(null, listingStore.listing.id));
            setShowAddForm(true);
          },
        },
      ];

  const onClose = (newVacancy: null | IVacancy) => {
    if (newVacancy !== null) {
      history.push(`/vacancies/${newVacancy.id}`);
    } else {
      setShowAddForm(false);
    }
  };

  const afterEdit = (vacancyID: number) => {
    if (Boolean(vacancyID)) {
      history.push(`/vacancies/${vacancyID}`);
    }

    setShowAddForm(false);
  };

  return useObserver(() => (
    <div>
      <VacancyAddForm
        listingID={listingStore.listing?.id}
        show={false}
        onClose={onClose}
      />

      {vacancyStore && (
        <VacancyEditModal
          store={vacancyStore}
          show={showAddForm}
          onClose={afterEdit}
        />
      )}

      <PageToolbar crumbs={crumbs} actions={actions} />

      {vacancyListStore.loading ? (
        <LoadingSection header={"Vacancies"} cards={true} />
      ) : (
        vacancyListStore.vacancies.map((vacancy: IVacancyListItem) => (
          <VacancySummarySection vacancy={vacancy} key={vacancy.id} />
        ))
      )}
    </div>
  ));
}
