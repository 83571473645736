import React, { useEffect, useState } from "react";
import TripStore from "../../../storeroom/trips/TripStore";
import { useObserver } from "mobx-react";
import { Button } from "@material-ui/core";
import { TextFieldChangeEvent } from "../../models";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Modal from "../../misc/Modal";
import { Alert } from "@material-ui/lab";

export interface ITripEditModalProps {
  store: TripStore;
  show: boolean;
  onClose: (tripID: number) => void;
}

interface IActionProps extends ITripEditModalProps {
  onError: (error: string) => void;
}

export default function TripEditModal(props: ITripEditModalProps) {
  const { store, show } = props;
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.show) {
      setError("");
    }
  }, [props.show]);

  const isNew = !Boolean(store.trip.id);
  const modalTitle = isNew ? "Add Vacancy" : "Edit Vacancy";

  const onError = (error: string) => {
    setError(error);
  };

  return (
    <Modal
      show={show}
      secondary={true}
      maxWidth={"xs"}
      headerChildren={<strong>{modalTitle}</strong>}
      contentChildren={
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity={"error"}>{error}</Alert>
            </Grid>
          )}

          <DestinationField {...props} />
          <StartDateField {...props} />
          <EndDateField {...props} />
        </Grid>
      }
      footerChildren={
        <React.Fragment>
          <CancelButton {...props} onError={onError} />
          <SubmitButton {...props} onError={onError} />
        </React.Fragment>
      }
    />
  );
}

function CancelButton(props: IActionProps) {
  return useObserver(() => {
    const loading = props.store.loading;

    return (
      <Button
        onClick={() => props.onClose(null)}
        disabled={loading}
        size={"small"}
      >
        Cancel
      </Button>
    );
  });
}

function SubmitButton(props: IActionProps) {
  const onClick = () => {
    props.store
      .save()
      .then(() => props.onClose(props.store.trip.id))
      .catch((error) => props.onError(error.response.data.non_field_errors[0]));
  };

  return useObserver(() => {
    const loading = props.store.loading;

    return (
      <Button
        variant={"contained"}
        color={"secondary"}
        disabled={loading}
        size={"small"}
        onClick={onClick}
      >
        Submit
      </Button>
    );
  });
}

function StartDateField(props: ITripEditModalProps) {
  const onChange = (event: TextFieldChangeEvent) => {
    props.store.setStartDate(event.target.value);
  };

  return useObserver(() => {
    const store = props.store;
    const startDate = store.trip.start_date;

    return (
      <Grid item xs={6}>
        <TextField
          label={"Start"}
          type={"date"}
          value={startDate}
          onChange={onChange}
          fullWidth={true}
          color={"secondary"}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  });
}

function EndDateField(props: ITripEditModalProps) {
  const onChange = (event: TextFieldChangeEvent) => {
    props.store.setEndDate(event.target.value);
  };

  return useObserver(() => {
    const store = props.store;
    const endDate = store.trip.end_date;

    return (
      <Grid item xs={6}>
        <TextField
          label={"End"}
          type={"date"}
          value={endDate}
          onChange={onChange}
          fullWidth={true}
          color={"secondary"}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  });
}

function DestinationField(props: ITripEditModalProps) {
  const onChange = (event: TextFieldChangeEvent) => {
    props.store.setDestination(event.target.value);
  };

  return useObserver(() => {
    const store = props.store;
    const destination = store.trip.location;

    return (
      <Grid item xs={12}>
        <TextField
          label={"Destination"}
          value={destination}
          onChange={onChange}
          fullWidth={true}
          color={"secondary"}
          autoFocus={true}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  });
}
