import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import { Section, sectionBodyStyle } from "../../../base/Section";
import ProfileFormDialog from "../forms/ProfileFormDialog";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import PhoneVerificationFormDialog from "../forms/PhoneVerificationFormDialog";
import EmailVerificationFormDialog from "../forms/EmailVerificationFormDialog";

export default function BasicInfoSection() {
  const history = useHistory();
  const store = useContext(StoreContext);
  const profile = store.profileStore.profile;
  const [showEditForm, setShowEditForm] = useState(false);
  const [showPhoneVerify, setShowPhoneVerify] = useState(false);
  const [showEmailVerify, setShowEmailVerify] = useState(false);

  const fullName = `${profile?.user.first_name} ${profile?.user.last_name}`;

  const refresh = () => {
    history.replace("/profile");
    history.go(0);
  };

  const unHidePhoneVerify = () => {
    setShowPhoneVerify(true);
  };

  const hidePhoneVerify = (canceled: boolean) => {
    setShowPhoneVerify(false);

    if (!canceled) {
      refresh();
    }
  };

  const unHideEmailVerify = () => {
    setShowEmailVerify(true);
  };

  const hideEmailVerify = (canceled: boolean) => {
    setShowEmailVerify(false);

    if (!canceled) {
      refresh();
    }
  };

  const needsPhoneVerification = Boolean(profile)
    ? !profile.verification.phone_number_verified
    : false;

  const needsEmailVerification = Boolean(profile)
    ? !profile.verification.email_verified
    : false;

  return useObserver(() => (
    <Section
      header={fullName}
      actionIcon={"edit"}
      actionLabel={"Edit Basic Info"}
      onActionClick={() => setShowEditForm(true)}
    >
      <ProfileFormDialog
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />

      <PhoneVerificationFormDialog
        show={showPhoneVerify}
        onClose={hidePhoneVerify}
      />

      <EmailVerificationFormDialog
        show={showEmailVerify}
        onClose={hideEmailVerify}
      />

      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Typography variant={"body1"} style={sectionBodyStyle}>
            Phone:
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {profile?.phone}
          {needsPhoneVerification && (
            <Link
              onClick={unHidePhoneVerify}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Verify
            </Link>
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography variant={"body1"} style={sectionBodyStyle}>
            Email:
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {profile?.user.email}
          {needsEmailVerification && (
            <Link
              onClick={unHideEmailVerify}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Verify
            </Link>
          )}
        </Grid>
      </Grid>
    </Section>
  ));
}
