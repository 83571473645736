import { useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Button,
  Container,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { humanizeDateRange } from "../../../formattingService";
import { useObserver } from "mobx-react";
import OwnerPreferencesForm from "../../owners/OwnerPreferencesForm";
import { useDocumentTitle } from "../../hooks";

/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }),
);
*/

export default function SitterSearchToolbar() {
  const theme = useTheme();
  const mediumWidth = useMediaQuery(theme.breakpoints.up("md"));

  const store = useContext(StoreContext);
  const searchManager = store.sitterSearchManager;
  const searchParams = searchManager.searchParams;

  const [dateAnchor, setDateAnchor] = useState<null | HTMLElement>(null);
  const [showPrefForm, setShowPrefForm] = useState(false);

  useDocumentTitle("Find Sitters");

  const dateString = Boolean(searchParams?.start_date)
    ? humanizeDateRange(searchParams.start_date, searchParams.end_date)
    : "Select Dates";

  const onPrefFormClose = (accepted: boolean) => {
    if (accepted) {
      searchManager.search();
    }

    setShowPrefForm(false);
  };

  return useObserver(() => (
    <AppBar
      position={"sticky"}
      style={{ marginBottom: "2em" }}
      elevation={4}
      color={"primary"}
    >
      <Container maxWidth={"md"} disableGutters={true}>
        <Toolbar>
          <OwnerPreferencesForm show={showPrefForm} onClose={onPrefFormClose} />

          <Typography
            variant={"h6"}
            style={{ marginRight: "1em", whiteSpace: "nowrap" }}
          >
            {mediumWidth ? "Find Sitters:" : "Sitters:"}
          </Typography>

          <Button
            color={"inherit"}
            style={{ whiteSpace: "nowrap" }}
            startIcon={<Icon>date_range</Icon>}
            onClick={(event) => setDateAnchor(event.currentTarget)}
          >
            {dateString}
          </Button>

          <div style={{ flexGrow: 1 }} />

          {mediumWidth ? (
            <Button
              color={"inherit"}
              style={{ whiteSpace: "nowrap", marginRight: "1em" }}
              startIcon={<Icon>filter_list</Icon>}
              onClick={() => setShowPrefForm(true)}
            >
              Preferences
            </Button>
          ) : (
            <IconButton color={"inherit"} onClick={() => setShowPrefForm(true)}>
              <Icon>filter_list</Icon>
            </IconButton>
          )}

          {mediumWidth ? (
            <Button
              color={"inherit"}
              variant={"outlined"}
              style={{ whiteSpace: "nowrap" }}
              startIcon={<Icon>search</Icon>}
              onClick={() => searchManager.search()}
            >
              Search
            </Button>
          ) : (
            <IconButton
              color={"inherit"}
              onClick={() => searchManager.search()}
            >
              <Icon>search</Icon>
            </IconButton>
          )}

          <Menu
            anchorEl={dateAnchor}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(dateAnchor)}
            onClose={() => setDateAnchor(null)}
          >
            <MenuItem>
              <TextField
                label={"Start Date"}
                type={"date"}
                value={searchParams.start_date}
                onChange={(event) =>
                  searchManager.setStartDate(event.target.value)
                }
              />
            </MenuItem>

            <MenuItem>
              <TextField
                label={"End Date"}
                type={"date"}
                value={searchParams.end_date}
                onChange={(event) =>
                  searchManager.setEndDate(event.target.value)
                }
              />
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  ));
}
