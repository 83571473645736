import React from "react";
import { IVacancyListItem } from "../../../storeroom/vacancies/models";
import { Section, SectionProps } from "../../base/Section";
import { humanizeDateRange } from "../../../formattingService";
import { useHistory } from "react-router-dom";
import { Icon, Typography } from "@material-ui/core";

interface Props {
  vacancy: IVacancyListItem;
}

export default function VacancySummarySection({ vacancy }: Props) {
  const history = useHistory();

  const sectionProps: SectionProps = {
    header: humanizeDateRange(vacancy.start_date, vacancy.end_date),
    actionLabel: "Details",
    actionIcon: "launch",
    onActionClick: () => history.push(`/vacancies/${vacancy.id}`),
  };

  return (
    <Section {...sectionProps}>
      {vacancy.is_reserved ? (
        <Typography variant={"h6"}>
          <Icon>check_circle</Icon> Reserved
        </Typography>
      ) : (
        <Typography variant={"h6"}>
          Requests: {vacancy.reservation_count}
        </Typography>
      )}
    </Section>
  );
}
