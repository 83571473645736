import React from "react";
import { useGuestReservation } from "../../hooks";
import {
  CheckInInstructionsSection,
  DescriptionSection,
  HomeAddressSection,
  HomeAlbumSection,
  HomeAmenitiesSection,
  HomeRulesSection,
  RoomAndBedSection,
} from "../sections";
import { ReservationStatus } from "../../../../storeroom/reservations/models";

export default function HomeTab() {
  const reservation = useGuestReservation();
  const showCheckIn = reservation.status === ReservationStatus.accepted;

  return (
    <div>
      {showCheckIn && <CheckInInstructionsSection />}
      <HomeAddressSection />
      <DescriptionSection />
      <HomeRulesSection />
      <HomeAmenitiesSection />
      <HomeAlbumSection />
      <RoomAndBedSection />
    </div>
  );
}
