import { IVacancyListItem, IVacancyListRequestParams } from "./models";
import { HttpClient } from "../HttpClient";
import { IVacancy } from "../homes/models";

export default class VacancyHttpClient {
  static list = (
    params?: IVacancyListRequestParams
  ): Promise<Array<IVacancyListItem>> => {
    return HttpClient.get<Array<IVacancyListItem>>("vacancies/", params);
  };

  static retrieve = (id: number) => {
    return HttpClient.get<IVacancy>(`vacancies/${id}/`);
  };

  static create = (vacancy: IVacancy) => {
    return HttpClient.post<IVacancy>("vacancies/", vacancy);
  };

  static update = (vacancy: IVacancy) => {
    return HttpClient.put<IVacancy>(`vacancies/${vacancy.id}/`, vacancy);
  };

  static delete = (id: number): Promise<void> => {
    return HttpClient.delete(`vacancies/${id}/`);
  };
}
