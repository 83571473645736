import React, { useEffect, useState } from "react";
import AlbumGalleryStore from "../../storeroom/albums/AlbumGalleryStore";
import { useObserver } from "mobx-react";
import { Skeleton } from "@material-ui/lab";
import Gallery from "react-photo-gallery";

interface IProps {
  albumID: number;
}

export default function AlbumGallery(props: IProps) {
  const [store, setStore] = useState<AlbumGalleryStore>(null);

  useEffect(() => {
    setStore(new AlbumGalleryStore(props.albumID));
  }, [props.albumID]);

  return useObserver(() => {
    return store === null ? null : store.loading ? (
      <Skeleton variant={"rect"} height={250} animation={"wave"} />
    ) : (
      <Gallery photos={[...store.pictures]} />
    );
  });
}
