import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { Section, SectionProps } from "../../base/Section";
import EditAmenityFormDialog from "../../amenities/EditAmenityFormDialog";
import { IHomeAmenity } from "../../../storeroom/amenities/models";
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { useObserver } from "mobx-react";

interface HomeAmenityProps {
  homeAmenity: IHomeAmenity;
  onSelect: () => void;
}

function HomeAmenity(props: HomeAmenityProps) {
  const { homeAmenity } = props;

  return (
    <ListItem alignItems={"flex-start"} button onClick={props.onSelect}>
      <ListItemIcon>
        <Icon>{homeAmenity.icon}</Icon>
      </ListItemIcon>
      <ListItemText
        primary={homeAmenity.display_name}
        secondary={
          <React.Fragment>
            <span>Private Details: {homeAmenity.private_details}</span>
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
        <Icon fontSize={"small"}>edit</Icon>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default function AmenitiesSection() {
  const store = useContext(StoreContext);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editID, setEditID] = useState(null);

  store.amenityStore.loadHomeAmenityList();

  const sectionProps: SectionProps = {
    header: "Amenities",
    actionIcon: "add",
    actionLabel: "Add Amenity",
    onActionClick: () => setShowAddForm(true),
  };

  const closeForm = () => {
    setShowAddForm(false);
    setEditID(null);
  };

  return useObserver(() => (
    <Section {...sectionProps}>
      <EditAmenityFormDialog
        show={showAddForm || editID !== null}
        homeAmenityID={editID}
        onClose={closeForm}
      />

      <List>
        {store.amenityStore.homeAmenityList?.map((amenity: IHomeAmenity) => (
          <HomeAmenity
            homeAmenity={amenity}
            onSelect={() => setEditID(amenity.id)}
            key={amenity.id}
          />
        ))}
      </List>
    </Section>
  ));
}
