import { action, observable } from "mobx";
import { IProfile } from "./models";
import { HttpClient } from "../HttpClient";
import ProfileHttpClient from "./ProfileHttpClient";
import BillingStore from "../billing/BillingStore";

export class ProfileStore {
  @observable profile: IProfile = undefined;
  @observable coverPhotoURL: string = null;

  billingStore: BillingStore = null;

  constructor() {
    this.load();
  }

  @action
  private setProfile = (newProfile: IProfile) => {
    this.profile = newProfile;
    this.billingStore = new BillingStore(newProfile.user.billing_profile);
  };

  load = (error?: (error: Response) => void) => {
    if (this.profile === undefined) {
      HttpClient.get<IProfile>("profile/")
        .then((response: IProfile) => {
          this.setProfile(response);
          this.loadCoverPhoto();
        })
        .catch(error);
    }
  };

  update = (updatedProfile: IProfile, error?: (error: Response) => void) => {
    HttpClient.put<IProfile>("profile/", updatedProfile)
      .then((response: IProfile) => this.setProfile(response))
      .catch(error);
  };

  loadCoverPhoto = () => {
    if (Boolean(this.profile)) {
      ProfileHttpClient.coverPhoto().then((url) => (this.coverPhotoURL = url));
    }
  };

  sendPhoneVerification = () => {
    return ProfileHttpClient.sendPhoneVerification();
  };

  verifyPhone = (otp: string) => {
    return ProfileHttpClient.verifyPhoneOTP(otp);
  };

  sendEmailVerification = () => {
    return ProfileHttpClient.sendEmailVerification();
  };

  verifyEmail = (otp: string) => {
    return ProfileHttpClient.verifyEmailOTP(otp);
  };
}
