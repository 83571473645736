import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import LoadingSection from "../../base/LoadingSection";
import { Container, Grid } from "@material-ui/core";
import VacancySearchForm from "./sections/VacancySearchForm";
import {
  useSitterStore,
  useTabletOrLarger,
  useVacancySearchStore,
} from "../../hooks";
import VacancySearchResultsSection from "./sections/VacancySearchResultsSection";
import { ICrumbProps } from "../../base/Crumbs";
import PageToolbar from "../../base/PageToolbar";

export default function VacancySearchPage() {
  const searchStore = useVacancySearchStore();
  const sitterStore = useSitterStore();
  const isBigScreen = useTabletOrLarger();

  useEffect(() => {
    searchStore.load();
    sitterStore.preferencesManager.loadPreferences().then();
  });

  const crumbs: ICrumbProps = {
    currentLabel: "Find Homes",
  };

  return useObserver(() => {
    const searchParams = searchStore.searchParams;
    const searchPrefs = sitterStore.preferencesManager.preferences;
    const gridSpan = 12;

    return Boolean(searchParams) && Boolean(searchPrefs) ? (
      <div>
        <PageToolbar crumbs={crumbs} actions={[]} secondary={true} />
        <Container maxWidth={"md"}>
          {isBigScreen && <div style={{ height: "1rem" }} />}
          <Grid container spacing={4}>
            <Grid item xs={gridSpan}>
              <VacancySearchForm />
            </Grid>
            <Grid item xs={gridSpan}>
              <VacancySearchResultsSection />
            </Grid>
          </Grid>
        </Container>
      </div>
    ) : (
      <div>
        <LoadingSection header={"Search Vacancies"} cards={true} />
      </div>
    );
  });
}
