import React from "react";
import {
  Container,
  createStyles,
  Grid,
  Paper,
  TableBody,
  TableContainer,
  Theme,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useObserver } from "mobx-react";
import { useConversationListStore } from "../../hooks";
import { IConversationListItem } from "../../../storeroom/conversations/models";
import { humanizeDate, humanizeTimestamp } from "../../../formattingService";
import withStyles from "@material-ui/core/styles/withStyles";
import { useHistory } from "react-router-dom";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
  })
)(TableCell);

function ConversationTableHead() {
  return (
    <TableHead>
      <TableRow style={{ whiteSpace: "nowrap" }}>
        <StyledTableCell>From</StyledTableCell>
        <StyledTableCell>Sent</StyledTableCell>
        <StyledTableCell>Message</StyledTableCell>
        <StyledTableCell style={{ textAlign: "right" }}>
          Reservation
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

function ConversationListItem({
  conversation,
}: {
  conversation: IConversationListItem;
}) {
  const history = useHistory();
  const mostRecentMessage = conversation.most_recent_message;

  const from = conversation.partner.name;
  const sent = humanizeTimestamp(mostRecentMessage.timestamp);
  const message = Boolean(mostRecentMessage.message)
    ? mostRecentMessage.message
    : "Picture";
  const reserved_for = humanizeDate(conversation.reservation.start_date);
  const fontWeight = conversation.unread ? "bold" : "initial";

  const onRowClick = () =>
    history.push(privateRoutePaths.conversation(conversation.id));

  return (
    <TableRow hover style={{ cursor: "pointer" }} onClick={onRowClick}>
      <TableCell style={{ whiteSpace: "nowrap", fontWeight: fontWeight }}>
        {from}
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap", fontWeight: fontWeight }}>
        {sent}
      </TableCell>
      <TableCell style={{ fontWeight: fontWeight }}>{message}</TableCell>
      <TableCell
        style={{
          whiteSpace: "nowrap",
          textAlign: "right",
          fontWeight: fontWeight,
        }}
      >
        {reserved_for}
      </TableCell>
    </TableRow>
  );
}

export default function ConversationListSection() {
  const conversationListStore = useConversationListStore();

  return useObserver(() => {
    const conversations = conversationListStore.conversations;

    return (
      <Container maxWidth={"lg"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <ConversationTableHead />
                <TableBody>
                  {conversations.map((conversation: IConversationListItem) => (
                    <ConversationListItem
                      conversation={conversation}
                      key={conversation.id}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    );
  });
}
