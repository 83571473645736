import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../storeroom/StoreContext";
import { ListingStatus } from "../../../../storeroom/homes/models";
import { Alert } from "@material-ui/lab";
import { Button, Container, Typography } from "@material-ui/core";
import Modal from "../../../misc/Modal";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react";
import PublishErrorModal from "../modals/PublishErrorModal";

export default function ListingStatusSection() {
  const store = useContext(StoreContext);
  const listing = store.listingStore.listing;
  const status = listing.status;
  const history = useHistory();

  const [showPublishError, setShowPublishError] = useState(false);
  const [publishErrors, setPublishErrors] = useState<Array<string>>(null);

  const onRevert = () => store.listingStore.revertToDraft();

  return useObserver(() => {
    const onPublish = () => {
      store.listingStore.validateAndPublish().catch((error) => {
        setPublishErrors(error.response.data.errors);
      });
    };

    return (
      <Container maxWidth={"md"}>
        <Modal
          show={showPublishError}
          maxWidth={"sm"}
          headerChildren={<strong>Publish Blocked</strong>}
          contentChildren={
            <React.Fragment>
              <Typography variant={"body1"}>
                You must have an active pet parent subscription to list a home
              </Typography>
            </React.Fragment>
          }
          footerChildren={
            <React.Fragment>
              <Button onClick={() => setShowPublishError(false)}>Close</Button>
              <Button
                onClick={() => history.push("/profile")}
                color={"primary"}
                variant={"contained"}
                size={"small"}
              >
                Go to Billing
              </Button>
            </React.Fragment>
          }
        />

        <PublishErrorModal
          show={Boolean(publishErrors)}
          onClose={() => setPublishErrors(null)}
          errors={publishErrors}
        />

        {status === ListingStatus.draft ? (
          <Alert
            severity={"error"}
            action={
              <Button
                color={"inherit"}
                variant={"outlined"}
                onClick={onPublish}
                size={"small"}
              >
                Publish
              </Button>
            }
          >
            <Typography style={{ fontWeight: "bold" }}>
              Status: Draft
            </Typography>
            <Typography variant={"body2"}>
              Your home is not visible to sitters. Click 'Publish' to add your
              listing to the search service.
            </Typography>
          </Alert>
        ) : status === ListingStatus.submitted_for_review ? (
          <Alert
            severity={"warning"}
            action={
              <Button
                color={"inherit"}
                variant={"outlined"}
                onClick={onPublish}
                size={"small"}
              >
                Approve and Publish
              </Button>
            }
          >
            Status: In Review
          </Alert>
        ) : (
          <Alert
            severity={"success"}
            action={
              <Button
                color={"inherit"}
                variant={"outlined"}
                onClick={onRevert}
                size={"small"}
              >
                Revert to Draft
              </Button>
            }
          >
            Status: Published
          </Alert>
        )}
      </Container>
    );
  });
}
