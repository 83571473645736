import { action, observable } from "mobx";
import { ISitter } from "./models";
import { HttpClient } from "../HttpClient";
import SitterPreferencesManager from "../sitters/SitterPreferencesManager";

export class SitterStore {
  preferencesManager: SitterPreferencesManager = new SitterPreferencesManager();

  @observable sitter: ISitter = undefined;

  @action
  private setSitter = (newSitter: ISitter): ISitter => {
    this.sitter = newSitter;
    return this.sitter;
  };

  load = (error?: (error: Response) => void) => {
    if (this.sitter === undefined) {
      HttpClient.get<ISitter>("profile/sitter/")
        .then(this.postLoad)
        .catch(error);
    }
  };

  postLoad = (sitter: ISitter) => {
    this.preferencesManager
      .loadPreferences()
      .then(() => this.setSitter(sitter));
  };

  update = (updatedSitter: ISitter): Promise<ISitter> => {
    return HttpClient.put<ISitter>(
      "profile/sitter/",
      updatedSitter
    ).then((response: ISitter) => this.setSitter(response));
  };

  register = (error?: (error: Response) => void): void => {
    HttpClient.get<ISitter>("profile/sitter/register/")
      .then((response: ISitter) => this.setSitter(response))
      .catch(error);
  };
}
