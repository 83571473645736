import React from "react";
import { ICrumbProps } from "../../base/Crumbs";
import { humanizeDateRange } from "../../../formattingService";
import { useReservationStore } from "../../hooks";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar from "../../base/PageToolbar";

export default function HostReservationToolbar() {
  const reservation = useReservationStore().reservation;

  const crumbs: ICrumbProps = {
    currentLabel: "Reservation",
    links: [
      {
        label: "Vacancies",
        url: "/vacancies",
      },
      {
        label: humanizeDateRange(reservation.start_date, reservation.end_date),
        url: `/vacancies/${reservation.vacancy}`,
      },
    ],
  };

  const actions: Array<IPageToolbarAction> = [];

  return <PageToolbar crumbs={crumbs} actions={actions} />;
}
