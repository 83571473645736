import React from "react";
import { Section } from "../../../base/Section";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import Grid from "@material-ui/core/Grid";
import { useBillingStore, useProfileStore } from "../../../hooks";
import { useObserver } from "mobx-react";
import LoadingSection from "../../../base/LoadingSection";
import {
  IStripeSessionInfo,
  ISubscriptionOption,
} from "../../../../storeroom/billing/models";
import { useURLParams } from "../../../../hooks";
import { useHistory } from "react-router-dom";
import Modal from "../../../misc/Modal";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const BillingUpdateForm = () => {
  const billingStore = useBillingStore();
  const params = useURLParams();
  const history = useHistory();
  const updatedPurchases = params.get("updated_purchases");

  let show = false;

  const postUpdate = () => {
    history.replace("/profile");
    history.go(0);
  };

  if (updatedPurchases === "true") {
    show = true;

    billingStore
      .updatePurchases()
      .then(postUpdate)
      .catch(() => alert("Update failed"));
  }

  return (
    <Modal
      show={show}
      maxWidth={"xs"}
      contentChildren={<div>Updating Billing Profile...</div>}
    />
  );
};

const SubscriptionOption = (props: { option: ISubscriptionOption }) => {
  const stripe = useStripe();
  const billingStore = useBillingStore();

  const { id, cost, frequency_count, frequency_interval } = props.option;

  const startSubscription = () => {
    billingStore
      .startSubscription(id)
      .then((sessionInfo: IStripeSessionInfo) => {
        stripe
          .redirectToCheckout({ sessionId: sessionInfo.session_id })
          .catch(console.log);
      });
  };

  return (
    <div style={{ marginBottom: "0.5rem" }}>
      <Link onClick={startSubscription} style={{ cursor: "pointer" }}>
        Subscribe:&nbsp; ${cost}
        <span style={{ fontSize: "0.8rem" }}>
          &nbsp;
          {frequency_count > 1 ? `every ${frequency_count}` : "per"}
          &nbsp;
          {frequency_interval}
          {frequency_count > 1 && "s"}
        </span>
        &nbsp;
        <Icon style={{ fontSize: "0.8rem" }}>launch</Icon>
      </Link>
    </div>
  );
};

const SubscriptionSection = () => {
  const billingStore = useBillingStore();

  return useObserver(() => {
    const billingProfile = billingStore.billingProfile;

    const rightColumn = billingProfile.pet_parent_subscription_active ? (
      <Link
        href={billingProfile.customer_portal_url}
        style={{ cursor: "pointer" }}
      >
        Manage Subscription &nbsp;
        <Icon style={{ fontSize: "0.8rem" }}>launch</Icon>
      </Link>
    ) : (
      <div>
        {billingProfile.subscription_options.map((option) => (
          <SubscriptionOption option={option} key={option.id} />
        ))}
      </div>
    );

    return (
      <React.Fragment>
        <Grid item xs={6}>
          Subscription:
        </Grid>
        <Grid item xs={6}>
          {rightColumn}
        </Grid>
      </React.Fragment>
    );
  });
};

export default function BillingSection() {
  const billingStore = useBillingStore();
  const profileStore = useProfileStore();

  return useObserver(() => {
    const profile = profileStore.profile;
    const billingProfile = billingStore.billingProfile;

    return billingProfile && stripePromise && Boolean(profile.owner) ? (
      <Section header={"Billing"}>
        <BillingUpdateForm />

        <Elements stripe={stripePromise}>
          <Grid container spacing={2}>
            <SubscriptionSection />
          </Grid>
        </Elements>
      </Section>
    ) : (
      <LoadingSection header={"Billing"} />
    );
  });
}
