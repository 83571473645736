import React, { useContext, useState } from "react";
import {
  PetBuilderContext,
  PetBuilderSteps,
} from "../../../storeroom/pets/builder/PetBuilderStore";
import { useObserver } from "mobx-react";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import { usePetBuilderStore } from "./hooks";
import { IPet } from "../../../storeroom/pets/models";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

interface IProps {
  onClose: (pet: IPet | null) => void;
}

interface IMenuItem {
  label: string;
  onClick: () => void;
}

export default function PetBuilderFooter(props: IProps) {
  const store = useContext(PetBuilderContext);

  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down("sm"));

  return useObserver(() => {
    let leftButton = null;
    let middleButton = null;
    let rightButton;

    if (store.isFinalStep()) {
      leftButton = <AddAnotherButton />;
      rightButton = <FinishButton {...props} />;
    } else {
      leftButton = <BackButton />;
      middleButton = <CancelButton {...props} />;
      rightButton = <NextButton {...props} />;
    }

    return isNarrow ? (
      <DialogActions>
        <MobileStepper
          style={{ flexGrow: 1 }}
          position={"static"}
          variant={"dots"}
          steps={3}
          activeStep={store.currentStep}
          backButton={leftButton}
          nextButton={rightButton}
        />
      </DialogActions>
    ) : (
      <DialogActions style={{ padding: "8px 1.5rem 1rem 1.5rem" }}>
        {leftButton}
        <div style={{ flex: 1 }} />
        {middleButton}
        {rightButton}
      </DialogActions>
    );
  });
}

function BackButton() {
  const store = usePetBuilderStore();
  const disabled = store.currentStep === PetBuilderSteps.BASIC_INFO;

  const [anchor, setAnchor] = useState(null);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const menuItems: Array<IMenuItem> = [
    {
      label: "Basic Information",
      onClick: () => {
        setAnchor(null);
        store.goBack(PetBuilderSteps.BASIC_INFO);
      },
    },
  ];

  if (store.currentStep > PetBuilderSteps.DESCRIPTION) {
    menuItems.push({
      label: "Description",
      onClick: () => {
        setAnchor(null);
        store.goBack(PetBuilderSteps.DESCRIPTION);
      },
    });
  }

  return (
    <div>
      <Button
        size={"small"}
        color={"default"}
        variant={"outlined"}
        disabled={disabled}
        onClick={onMenuClick}
      >
        Go Back
      </Button>

      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        {menuItems.map((menuItem) => (
          <MenuItem onClick={menuItem.onClick} key={menuItem.label}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

function CancelButton(props: IProps) {
  const onClick = () => props.onClose(null);

  return (
    <Button
      size={"small"}
      color={"default"}
      variant={"outlined"}
      onClick={onClick}
    >
      Cancel
    </Button>
  );
}

function NextButton(props: IProps) {
  const store = usePetBuilderStore();

  const onClick = () => {
    store.submit();
  };

  return useObserver(() => {
    const disabled = !store.currentStepStore.canSubmit;

    return (
      <Button
        size={"small"}
        color={"primary"}
        variant={"contained"}
        onClick={onClick}
        disabled={disabled}
      >
        Next
      </Button>
    );
  });
}

function FinishButton(props: IProps) {
  const store = usePetBuilderStore();

  const onClick = () => {
    props.onClose(store.getFinishStepStore().getPet());
  };

  return useObserver(() => {
    const disabled = !store.currentStepStore.canSubmit;

    return (
      <Button
        size={"small"}
        color={"primary"}
        variant={"contained"}
        onClick={onClick}
        disabled={disabled}
      >
        Finish
      </Button>
    );
  });
}

function AddAnotherButton() {
  const store = usePetBuilderStore();

  const onClick = () => {
    store.reset();
  };

  return useObserver(() => {
    const disabled = !store.currentStepStore.canSubmit;

    return (
      <Button
        size={"small"}
        color={"primary"}
        variant={"contained"}
        onClick={onClick}
        disabled={disabled}
      >
        Add Another Pet
      </Button>
    );
  });
}
