import React, { useMemo } from "react";
import { Toolbar } from "@material-ui/core";
import { IStoreContext, StoreProvider } from "../../storeroom/StoreContext";
import ProfileMenu from "./navbar/ProfileMenu";
import MainMenu from "./navbar/MainMenu";
import { ElevationAppBar } from "./navbar/ElevationAppBar";
import { AmenityStore } from "../../storeroom/amenities/AmenityStore";
import { HomeStore } from "../../storeroom/homes/HomeStore";
import { ListingStore } from "../../storeroom/homes/ListingStore";
import { OwnerStore } from "../../storeroom/users/OwnerStore";
import { PetStore } from "../../storeroom/pets/PetStore";
import { ProfileStore } from "../../storeroom/users/ProfileStore";
import { ReservationStore } from "../../storeroom/reservations/ReservationStore";
import { SitterStore } from "../../storeroom/users/SitterStore";
import { TripStoreOld } from "../../storeroom/trips/TripStoreOld";
import { VacancySearchStore } from "../../storeroom/homes/searchService/VacancySearchStore";
import { VacancyStore } from "../../storeroom/homes/VacancyStore";
import { SitterSearchManager } from "../../storeroom/sitters/SitterSearchManager";
import ConversationListStore from "../../storeroom/conversations/ConversationListStore";
import { Route, Switch } from "react-router-dom";
import {
  defaultRouteComponents,
  ownerRouteComponents,
  sitterRouteComponents,
} from "../../routes/PrivateRoutes";
import { useObserver } from "mobx-react";
import { IRoute } from "../../routes/AppRouter";
import { IProfile } from "../../storeroom/users/models";
import { useProfileStore } from "../hooks";
import Page404 from "./Page404";
import SignUpBanner from "../users/setUp/SignUpBanner";

interface IProps {
  children?: any;
}

function buildRoutes(profile: IProfile) {
  const routes: Array<IRoute> = defaultRouteComponents;
  if (profile?.owner) {
    routes.push(...ownerRouteComponents);
  }
  if (profile?.sitter) {
    routes.push(...sitterRouteComponents);
  }
  return routes;
}

export default function BasePage(props: IProps) {
  const defaultStore = useMemo<IStoreContext>(() => {
    return {
      amenityStore: new AmenityStore(),
      homeStore: new HomeStore(),
      listingStore: new ListingStore(),
      ownerStore: new OwnerStore(),
      petStore: new PetStore(),
      profileStore: new ProfileStore(),
      reservationStore: new ReservationStore(),
      sitterStore: new SitterStore(),
      tripStore: new TripStoreOld(),
      vacancySearchStore: new VacancySearchStore(),
      vacancyStore: new VacancyStore(),
      sitterSearchManager: new SitterSearchManager(),
      conversationListStore: new ConversationListStore(),
    };
  }, []);

  return (
    <StoreProvider value={defaultStore}>
      <SignUpBanner />
      <ElevationAppBar>
        <Toolbar>
          <MainMenu />
          <ProfileMenu />
        </Toolbar>
      </ElevationAppBar>
      <ContentSwitch />
    </StoreProvider>
  );
}

function ContentSwitch() {
  const profileStore = useProfileStore();

  return useObserver(() => {
    const profile = profileStore.profile;
    const routes = buildRoutes(profile);

    return profile ? (
      <Switch>
        {routes.map((route, index) => (
          <Route exact path={route.path} key={index}>
            {route.component}
          </Route>
        ))}

        <Route path={"*"}>
          <Page404 />
        </Route>
      </Switch>
    ) : null;
  });
}
