import { PetStore } from "../storeroom/pets/PetStore";
import { useContext } from "react";
import {
  AuthStoreContext,
  ConversationStoreContext,
  StoreContext,
} from "../storeroom/StoreContext";
import { ReservationStore } from "../storeroom/reservations/ReservationStore";
import { TripStoreOld } from "../storeroom/trips/TripStoreOld";
import { ProfileStore } from "../storeroom/users/ProfileStore";
import { ListingStore } from "../storeroom/homes/ListingStore";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SitterStore } from "../storeroom/users/SitterStore";
import StripeStore from "../storeroom/billing/StripeStore";
import BillingStore from "../storeroom/billing/BillingStore";
import RegistrationStore from "../storeroom/users/registration/RegistrationStore";

export function useAmenityStore() {
  return useContext(StoreContext).amenityStore;
}

export function useAuthStore() {
  return useContext(AuthStoreContext).authStore;
}

export function useBillingStore(): BillingStore {
  return useProfileStore()?.billingStore;
}

export function useConversationStore() {
  return useContext(ConversationStoreContext).conversationStore;
}

export function useConversationListStore() {
  return useContext(StoreContext).conversationListStore;
}

export function useHomeStore() {
  return useContext(StoreContext).homeStore;
}

export function useListingStore(): ListingStore {
  return useContext(StoreContext).listingStore;
}

export function usePetStore(): PetStore {
  return useContext(StoreContext).petStore;
}

export function useProfileStore(): ProfileStore {
  return useContext(StoreContext).profileStore;
}

export function useRegistrationStore(): RegistrationStore {
  return useContext(AuthStoreContext).registrationStore;
}

export function useReservationStore(): ReservationStore {
  return useContext(StoreContext).reservationStore;
}

export function useSitterStore(): SitterStore {
  return useContext(StoreContext).sitterStore;
}

export function useStripeStore(): StripeStore {
  return useBillingStore()?.stripeStore;
}

export function useTripStore(): TripStoreOld {
  return useContext(StoreContext).tripStore;
}

export function useVacancyStore() {
  return useContext(StoreContext).vacancyStore;
}

export function useVacancySearchStore() {
  return useContext(StoreContext).vacancySearchStore;
}

export function useMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("xs"));
}

export function useTabletOrLarger() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("md"));
}

/* Functional hooks */
export function useDocumentTitle(title: string) {
  const host: string = process.env.REACT_APP_HOST;

  let prefix = "";
  if (host.includes("localhost")) {
    prefix = "Local -";
  } else if (host.includes("staging")) {
    prefix = "Staging -";
  }

  document.title = `${prefix} ${title}`;
}
