import { action, observable } from "mobx";
import IBasePetBuilderStepStore from "./IBasePetBuilderStepStore";

export default class PetBasicInfoStepStore implements IBasePetBuilderStepStore {
  @observable name: string = "";
  @observable petType: string = "";
  @observable breed: string = "";
  @observable canSubmit: boolean = false;

  @action setName = (name: string) => {
    this.name = name;
    this.postUpdate();
  };

  @action setType = (type: string) => {
    this.petType = type;
    this.postUpdate();
  };

  @action setBreed = (breed: string) => {
    this.breed = breed;
    this.postUpdate();
  };

  private postUpdate = () => {
    this.canSubmit =
      Boolean(this.name) && Boolean(this.petType) && Boolean(this.breed);
  };

  submit = (): Promise<null> => Promise.resolve(null);
}
