import React, { useEffect } from "react";
import { usePetBuilderStore } from "../hooks";
import { useObserver } from "mobx-react";
import { Typography } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Icon from "@material-ui/core/Icon";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function PetBuilderFinishForm() {
  const builderStore = usePetBuilderStore();
  const stepStore = builderStore.getFinishStepStore();

  useEffect(() => {
    if (stepStore !== null) {
      stepStore.submit();
    }
  }, [stepStore]);

  return (
    <div>
      <PetCreationProgress />
      <div style={{ height: "3rem" }} />
      <ImageUploadProgress />
      <div style={{ height: "1.5rem" }} />
    </div>
  );
}

function PetCreationProgress() {
  const stepStore = usePetBuilderStore().getFinishStepStore();

  return useObserver(() => {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
              Creating Profile
            </Typography>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "right" }}>
            <ProgressIndicator progress={stepStore.petCreationProgress} />
          </Grid>
        </Grid>

        <div style={{ height: "1rem" }} />

        <Grid container spacing={2}>
          <Grid item xs={2}>
            Name:
          </Grid>
          <Grid item xs={10}>
            {stepStore.name}
          </Grid>

          <Grid item xs={2}>
            Type:
          </Grid>
          <Grid item xs={10}>
            {stepStore.type}
          </Grid>

          <Grid item xs={2}>
            Breed:
          </Grid>
          <Grid item xs={10}>
            {stepStore.breed}
          </Grid>
        </Grid>
      </div>
    );
  });
}

function ImageUploadProgress() {
  const stepStore = usePetBuilderStore().getFinishStepStore();

  return useObserver(() => {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
              Uploading Images
            </Typography>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "right" }}>
            <ProgressIndicator progress={stepStore.imageUploadProgress} />
          </Grid>
        </Grid>
      </div>
    );
  });
}

function ProgressIndicator({ progress }: { progress: number }) {
  switch (progress) {
    case null:
      return <DialogContentText>Not Started</DialogContentText>;

    case 0:
      return (
        <Chip
          icon={<CircularProgress color={"inherit"} size={"1rem"} />}
          label={"Processing"}
          color={"primary"}
        />
      );

    case 100:
      return (
        <Chip icon={<Icon>done_all</Icon>} label={"Done"} color={"primary"} />
      );

    default:
      return (
        <Chip
          icon={
            <CircularProgress
              color={"inherit"}
              size={"1rem"}
              variant={"determinate"}
              value={progress}
            />
          }
          label={"Processing"}
          color={"primary"}
        />
      );
  }
}
