import { action, observable } from "mobx";
import { IVacancy } from "./models";
import { HttpClient } from "../HttpClient";

export class VacancyStore {
  @observable vacancyList: Array<IVacancy> = undefined;
  @observable vacancy: IVacancy = undefined;

  @action
  private setVacancyList = (newList: Array<IVacancy>) => {
    this.vacancyList = newList;
  };

  @action
  private setVacancy = (newVacancy: IVacancy) => {
    this.vacancy = newVacancy;
  };

  @action
  private postAdd = (newVacancy: IVacancy) => {
    this.setVacancy(newVacancy);
  };

  @action
  private postUpdate = (updatedVacancy: IVacancy) => {
    this.setVacancy(updatedVacancy);
    this.vacancyList = this.vacancyList.map((vacancy) =>
      vacancy.id === updatedVacancy.id ? updatedVacancy : vacancy
    );
  };

  @action
  private postRemove = (id: number) => {
    if (this.vacancyList) {
      this.vacancyList = this.vacancyList.filter(
        (vacancy) => vacancy.id !== id
      );
    }
  };

  loadList = (error?: (error: Response) => void) => {
    if (this.vacancyList === undefined) {
      HttpClient.get<Array<IVacancy>>("vacancies/")
        .then((response: Array<IVacancy>) => this.setVacancyList(response))
        .catch(error);
    }
  };

  loadVacancy = (id: number, error?: (error: Response) => void) => {
    HttpClient.get<IVacancy>(`vacancies/${id}/`)
      .then((response: IVacancy) => this.setVacancy(response))
      .catch(error);
  };

  add = (newVacancy: IVacancy): Promise<IVacancy> => {
    return HttpClient.post<IVacancy>("vacancies/", newVacancy).then(
      (response: IVacancy) => {
        this.postAdd(response);
        return response;
      }
    );
  };

  update = (updatedVacancy: IVacancy, error?: (error: Response) => void) => {
    HttpClient.put<IVacancy>(`vacancies/${updatedVacancy.id}/`, updatedVacancy)
      .then((response: IVacancy) => this.postUpdate(response))
      .catch(error);
  };

  remove = (id: number): Promise<void> => {
    return HttpClient.delete(`vacancies/${id}/`).then(() =>
      this.postRemove(id)
    );
  };
}
