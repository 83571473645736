import React, { useEffect, useState } from "react";
import { Section, SectionProps } from "../base/Section";
import AlbumModal, { IAlbumModalProps } from "./modals/AlbumModal";
import {
  AlbumStore,
  AlbumStoreProvider,
} from "../../storeroom/albums/AlbumStore";
import AlbumPreviewGallery from "./AlbumPreviewGallery";
import AlbumModalViewOnly from "./modals/AlbumModalViewOnly";

interface Props {
  album: number;
  viewOnly?: boolean;
  headerOverride?: string;
  prevURL?: string;
  secondary?: boolean;
  grouped?: boolean;
  onAlbumChange?: () => void;
}

export default function AlbumSection(props: Props) {
  const [store, setStore] = useState<AlbumStore>(null);
  const [showModal, setShowModal] = useState(false);

  const { viewOnly } = props;

  useEffect(() => {
    setStore(new AlbumStore(props.album));
  }, [props.album]);

  const header = props.headerOverride ? props.headerOverride : "Album";

  const openModal = () => {
    setShowModal(true);
  };

  const onModalClose = (updated: boolean) => {
    setShowModal(false);
    if (updated && props.onAlbumChange) {
      props.onAlbumChange();
    }
  };

  const sectionProps: SectionProps = {
    header: header,
    onActionClick: openModal,
  };

  sectionProps.secondaryColor = props.secondary;
  sectionProps.groupedSection = props.grouped;

  if (viewOnly) {
    sectionProps.actionIcon = "photo_library";
    sectionProps.actionLabel = "View Full Album";
  } else {
    sectionProps.actionIcon = "edit";
    sectionProps.actionLabel = "Manage Album";
  }

  const albumModalProps: IAlbumModalProps = {
    show: showModal,
    onClose: onModalClose,
    header: props.headerOverride,
    secondary: props.secondary,
  };

  const modal = props.viewOnly ? (
    <AlbumModalViewOnly {...albumModalProps} />
  ) : (
    <AlbumModal {...albumModalProps} />
  );

  return (
    <Section {...sectionProps}>
      {Boolean(store) && (
        <AlbumStoreProvider value={store}>
          {modal}
          <AlbumPreviewGallery />
        </AlbumStoreProvider>
      )}
    </Section>
  );
}
