import React from "react";
import { Container, createStyles, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { useTabletOrLarger } from "../../hooks";
import Avatar from "@material-ui/core/Avatar";
import { ReactComponent as InstagramIcon } from "../../../images/icons/instagramIcon.svg";
import { ReactComponent as LinkedInIcon } from "../../../images/icons/linkedInIcon.svg";
import facebookIcon from "../../../images/icons/facebookIcon.png";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { publicRoutePaths } from "../../../routes/PublicRoutes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: "2.5rem",
      paddingBottom: "2rem",
      backgroundColor: theme.palette.primary.main,
    },
    readyToGetStartedText: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
      fontSize: 36,
      color: "white",
    },
    socialMediaContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    socialMediaIcon: {
      width: "2rem",
      height: "2rem",
      fill: "white",
      cursor: "pointer",
    },
  })
);

export default function PublicFooter() {
  const classes = useStyles();
  const isWide = useTabletOrLarger();
  const history = useHistory();

  const readyToGetStartedStyle: React.CSSProperties = {
    textAlign: isWide ? "left" : "center",
  };

  const socialMediaContainerWidth = 4;

  return (
    <Container className={classes.container} maxWidth={false}>
      <Container maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            className={classes.readyToGetStartedText}
            style={readyToGetStartedStyle}
          >
            Ready to start?
            <br />
            Sign Up!
          </Grid>

          <Grid item xs={12} md={4} style={{ minHeight: "6rem" }}>
            <Grid container spacing={2} style={{ height: "100%" }}>
              <Grid
                item
                xs={socialMediaContainerWidth}
                className={classes.socialMediaContainer}
              >
                <a
                  href={"https://www.linkedin.com/company/jetsyt"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <LinkedInIcon className={classes.socialMediaIcon} />
                </a>
              </Grid>

              <Grid
                item
                xs={socialMediaContainerWidth}
                className={classes.socialMediaContainer}
              >
                <a
                  href={"https://www.instagram.com/jetsyt/"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <InstagramIcon className={classes.socialMediaIcon} />
                </a>
              </Grid>

              <Grid
                item
                xs={socialMediaContainerWidth}
                className={classes.socialMediaContainer}
              >
                <Avatar
                  src={facebookIcon}
                  className={classes.socialMediaIcon}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={1} />

          <Grid item xs={12} md={3}>
            <Button
              variant={"contained"}
              style={{ width: "100%", backgroundColor: "white" }}
              endIcon={<Icon>arrow_right_alt</Icon>}
              onClick={() => history.push(publicRoutePaths.register())}
            >
              Sign Up
              <div style={{ flexGrow: 1 }} />
            </Button>

            <div style={{ height: "1rem" }} />

            <a
              href={"mailto:hello@jetsyt.com"}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant={"outlined"}
                style={{ width: "100%", borderColor: "white", color: "white" }}
                endIcon={<Icon>arrow_right_alt</Icon>}
              >
                Contact Us
                <div style={{ flexGrow: 1 }} />
              </Button>
            </a>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant={"body2"} style={{ color: "#FFF" }}>
              Jetsyt, LLC
            </Typography>
            <Typography variant={"caption"}>
              <a
                href={
                  "https://sites.google.com/jetsyt.com/help-center/privacy-policy"
                }
                target={"_blank"}
                rel={"noopener noreferrer"}
                style={{ color: "#FFF" }}
              >
                Privacy
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                href={
                  "https://sites.google.com/jetsyt.com/help-center/terms-of-service"
                }
                target={"_blank"}
                rel={"noopener noreferrer"}
                style={{ color: "#FFF" }}
              >
                Terms of Service
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
