import { Typography } from "@material-ui/core";
import React from "react";
import Container from "@material-ui/core/Container";
import BasePublicPage from "../BasePublicPage";
import { ICrumbProps } from "../../base/Crumbs";
import PageToolbar from "../../base/PageToolbar";

const petParentPromises: Array<string> = [
  "I will create a comprehensive and accurate home and pet owner listing, including photos and clear information about what I expect from a Sytter. I will include detailed information about my pet(s), its/their needs, routine and always ensure this is kept up-to-date.",
  "I will ensure that all vaccinations (if applicable) are up-to-date for each pet.",
  "I will do my best to respond to messages as soon as I am able to do so.",
  "I will agree to all responsibilities with my Sytter before a sit is confirmed, including any costs associated with pet care or home maintenance.",
  "I will make sure that the Sytter and I are clear about arrangements so that they can then make travel arrangements accordingly.",
  "I will ensure my sitter has all the information needed for a successful sit by completing and always updating my profile and being responsive to messages prior to and during my time away from home. This includes: Vet information for the pet(s), Emergency contacts, and Medication information.",
  "I will create a clean, comfortable, and secure environment for my Sytter, including making sure that other people do not enter the home without consent and no third parties are to be present during the duration of the Sytter’s stay.",
  "I agree to refund my Sytter for any vet costs which may arise during a house sit.",
  "I will only cancel a sit or return home before the end of a house sit if there are extraordinary circumstances. If this happens, I will provide proof of extraordinary circumstances.",
  "If I need to come home early, I will be responsible for finding accommodations for the Sytter.",
];

const sitterPromises: Array<string> = [
  "I will offer my house and/or pet sitting services free of charge to the owners, in exchange for accommodations.",
  "I will not seek monetary compensation for my sits, however, if the owners wants to offer tip, then it is up to me to accept or not accept.",
  "I will create a comprehensive and accurate sitter profile, including photos and clear information about me and my experiences, where I am looking to sit and my availability to do so.",
  "I promise to be truthful about my skill sets when it comes to pet sitting and commit to keeping my profile up-to-date.",
  "I will respond to messages from other members as soon as I am able to. ",
  "I will agree to all responsibilities with the home and pet owner before a sit is confirmed and will follow the home and pet owner’s instructions.",
  "I promise to follow up and notify the Owners immediately in the event of an emergency.",
  "I will ensure I am able to travel to the sit and am available for all required dates before applying for a sit. Once the sit has been confirmed, I will not cancel my sit unless there are extraordinary circumstances. Once on a sit, I will never arrive later or leave earlier than the agreed dates. In the event of an emergency, I will provide proof of the extraordinary circumstances.",
  "I will make my travel arrangements only once a sit has been confirmed with the Owner. ",
  "I will make every effort to leave the home as I found it and ensure everything is taken with the owner’s pet(s), property, home, and garden.",
  "Should a pet fall ill under my care or get hurt, I will seek veterinary support as quickly as possible and will inform the pet owner as quickly as possible. If there are any maintenance issues with the home, I will contact the Owner as quickly as possible for advice and next steps. I understand that the owner may have to return before the end date of a sit or cancel a sit if there are extraordinary circumstances. ",
  "I promise to abide by the all the Owners house rules and pet routine.",
  "I promise not to bring non-members on my stay. If another confirmed Sytter/Member wants to come with me during the sit, the Owners must be made aware of and must confirm also.",
];

export default function CodeOfConductPage() {
  const crumbs: ICrumbProps = { currentLabel: "Member Code of Conduct" };

  return (
    <BasePublicPage>
      <PageToolbar crumbs={crumbs} actions={[]} />

      <Container maxWidth={"md"}>
        <Typography variant={"body1"}>
          As a Jetsyt member, you are agreeing to partake in our community of
          travel and pet lovers. Our community is rooted in mutual respect for
          each other and the homes and pets we care for.
        </Typography>

        <div style={{ height: "1rem" }} />

        <Typography variant={"body1"}>
          To ensure that we remain in a safe environment and committed to our
          mission, you must agree to our Code of Conduct when you sign up.
          Before becoming a member, please ensure that you have read and agreed
          to this Code of Conduct. We are on a mission to creating a safe
          environment for all of our members and will not tolerate any behavior
          that goes against our code of conduct.
        </Typography>

        <div style={{ height: "2rem" }} />

        <Typography variant={"body1"} style={{ fontWeight: 700 }}>
          By becoming a member, you agree to abide by the following:
        </Typography>

        <Typography variant={"body1"}>
          <ul style={{ margin: 0 }}>
            <li>I confirm that I am over 18 years of age.</li>
            <li>
              I will communicate clearly and respectfully with the entire Jetsyt
              community, including other members and staff, regardless of their
              race, religion, national, origin ethnicity, disability, sex,
              gender identity, sexual orientation, or age. I confirm that I am
              over 18 years of age.
            </li>
          </ul>
        </Typography>

        <div style={{ height: "2rem" }} />

        <Typography variant={"body1"} style={{ fontWeight: 700 }}>
          Pet Parents Promise to:
        </Typography>

        <Typography variant={"body1"}>
          <ul>
            {petParentPromises.map((parentPromise) => (
              <li>{parentPromise}</li>
            ))}
          </ul>
        </Typography>

        <div style={{ height: "2rem" }} />

        <Typography variant={"body1"} style={{ fontWeight: 700 }}>
          Sytters Promise to:
        </Typography>

        <Typography variant={"body1"}>
          <ul>
            {sitterPromises.map((sitterPromise) => (
              <li>{sitterPromise}</li>
            ))}
          </ul>
        </Typography>

        <div style={{ height: "2rem" }} />
      </Container>
    </BasePublicPage>
  );
}
