import React from "react";
import { RouterProps } from "../../base/baseModels";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../../storeroom/StoreContext";
import VacancySearchResultPageToolbar, {
  VacancySearchResultPageLoadingToolbar,
} from "./VacancySearchResultPageToolbar";
import { IVacancySearchResultDetails } from "../../../storeroom/homes/searchService/models";
import { VacancySearchResultProvider } from "../../listings/contexts";
import ListingPreviewTabs from "../../listings/preview/ListingPreviewTabs";

interface Props extends RouterProps {}

interface State {
  vacancy: IVacancySearchResultDetails;
}

class VacancySearchResultPage extends React.Component<Props, State> {
  static contextType = StoreContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      vacancy: null,
    };
  }

  initialize = () => {
    this.context.vacancySearchStore
      .loadResultDetails(this.props.match.params.id)
      .then((response: IVacancySearchResultDetails) =>
        this.setState({ vacancy: response })
      );
  };

  componentDidMount(): void {
    this.initialize();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initialize();
    }
  }

  render() {
    const { vacancy } = this.state;

    return vacancy ? (
      <VacancySearchResultProvider value={vacancy}>
        <VacancySearchResultPageToolbar vacancy={vacancy} />
        <ListingPreviewTabs />
      </VacancySearchResultProvider>
    ) : (
      <div>
        <VacancySearchResultPageLoadingToolbar />
      </div>
    );
  }
}

export default withRouter(VacancySearchResultPage);
