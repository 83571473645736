import React from "react";
import { useDropzone } from "react-dropzone";
import { Typography } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { usePetBuilderStore } from "../hooks";
import { useObserver } from "mobx-react";
import Gallery from "react-photo-gallery";

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export default function PetBuilderAlbumForm() {
  const stepStore = usePetBuilderStore().getAlbumStepStore();

  const onDropAccept = (acceptedFiles: Array<File>) => {
    acceptedFiles.forEach((file) => stepStore.addFile(file));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 3 - stepStore.pictures.length,
    onDropAccepted: onDropAccept,
    disabled: stepStore.pictures.length === 3,
  });

  return useObserver(() => {
    const dropZoneLabel =
      stepStore.pictures.length >= 3
        ? "Limit reached"
        : "Drag 'n' drop your pictures here, or click to open selector";

    return (
      <div>
        <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
          Add up to 3 pictures
        </Typography>

        <div {...getRootProps({ style: baseStyle })}>
          <input {...getInputProps()} />
          <p style={{ textAlign: "center" }}>{dropZoneLabel}</p>
        </div>

        <div style={{ height: "2.5rem" }} />
        {stepStore.previews.length > 0 && <PreviewSection />}
      </div>
    );
  });
}

function PreviewSection() {
  const stepStore = usePetBuilderStore().getAlbumStepStore();

  const onClick = (event: React.MouseEvent, photos: { index: number }) => {
    stepStore.remove(photos.index);
  };

  return (
    <div>
      <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
        Selected Images
      </Typography>

      <DialogContentText>Click to remove</DialogContentText>

      <Gallery photos={[...stepStore.previews]} onClick={onClick} />
    </div>
  );
}
