import { action, observable } from "mobx";
import { IListing } from "./models";
import { HttpClient } from "../HttpClient";
import { IVacancySearchResultDetails } from "./searchService/models";
import VacancyListStore from "../vacancies/VacancyListStore";
import ListingHttpClient from "../listings/ListingHttpClient";

export class ListingStore {
  @observable listing: IListing = undefined;

  vacancyListStore: VacancyListStore = new VacancyListStore();

  @action
  private setListing = (newListing: IListing) => {
    this.listing = newListing;
  };

  load = (error?: (error: Response) => void) => {
    if (this.listing === undefined) {
      HttpClient.get<IListing>("listing/")
        .then((response: IListing) => this.setListing(response))
        .catch(error);
    }
  };

  loadListing = (id: number) => {
    return HttpClient.get<IListing>(`listing/${id}/`);
  };

  update = (updatedListing: IListing): Promise<void> => {
    return HttpClient.put<IListing>(
      `listing/${updatedListing.id}/`,
      updatedListing
    ).then((response: IListing) => this.setListing(response));
  };

  submitForReview = (error?: (error: Response) => void): void => {
    HttpClient.get<IListing>("listing/submit_for_review/")
      .then((response: IListing) => this.setListing(response))
      .catch(error);
  };

  validateAndPublish = (): Promise<void> => {
    return ListingHttpClient.canPublish().then(this.publish);
  };

  publish = (): Promise<void> => {
    return ListingHttpClient.publish().then(this.setListing);
  };

  revertToDraft = (error?: (error: Response) => void): void => {
    HttpClient.get<IListing>("listing/revert_to_draft/")
      .then((response: IListing) => this.setListing(response))
      .catch(error);
  };

  preview = () => {
    return HttpClient.get<IVacancySearchResultDetails>("listing/preview/");
  };
}
