import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { Typography } from "@material-ui/core";
import OwnerDescriptionFormDialog from "../forms/OwnerDescriptionFormDialog";

export default function OwnerDescriptionSection() {
  const store = useContext(StoreContext);
  const owner = store.ownerStore.owner;

  const [showForm, setShowForm] = useState(false);

  const sectionProps: SectionProps = {
    header: "About Me",
    actionIcon: "edit",
    actionLabel: "Edit About Me",
    onActionClick: () => setShowForm(true),
  };

  return useObserver(() => (
    <Section {...sectionProps}>
      <OwnerDescriptionFormDialog
        show={showForm}
        onClose={() => setShowForm(false)}
      />
      <Typography style={sectionBodyStyle}>
        {owner.owner_description}
      </Typography>
    </Section>
  ));
}
