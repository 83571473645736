import { observable } from "mobx";
import { IHome } from "./models";
import { HttpClient } from "../HttpClient";
import HomeHttpClient from "./HomeHttpClient";
import { ISelectOption } from "../models";

export class HomeStore {
  private _homeTypeOptions: Array<ISelectOption> = [];

  @observable loading: boolean = true;
  @observable home: IHome = null;

  constructor() {
    this.load(true);
  }

  private setHome = (newHome: IHome) => {
    this._homeTypeOptions = [...newHome.home_type_options];
    this.home = newHome;
    this.loading = false;
  };

  getHomeTypeOptions = (): Array<ISelectOption> => {
    return this._homeTypeOptions;
  };

  getHomeTypeDisplayName = (descriptor: string) => {
    const matchingOptions = this.getHomeTypeOptions().filter(
      (option) => option.value === descriptor
    );

    return matchingOptions.length > 0 ? matchingOptions[0].label : "";
  };

  load = (skipLoadCheck = false) => {
    if (this.home === null && (skipLoadCheck || !this.loading)) {
      this.loading = true;
      HomeHttpClient.retrieve()
        .then(this.setHome)
        .catch(() => (this.loading = false));
    }
  };

  update = (updatedHome: IHome, error?: (error: Response) => void) => {
    HttpClient.put<IHome>("home/", updatedHome)
      .then((response: IHome) => this.setHome(response))
      .catch(error);
  };

  submitUpdates = (updatedHome: IHome) => {
    return HttpClient.put<IHome>("home/", updatedHome).then((response: IHome) =>
      this.setHome(response)
    );
  };

  save = (updatedHome: IHome): Promise<void> => {
    return HttpClient.put<IHome>("home/", updatedHome).then(this.setHome);
  };
}
