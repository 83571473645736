import React, { useEffect, useState } from "react";
import { useHomeStore } from "../../hooks";
import { TextFieldChangeEvent } from "../../models";
import { IHome } from "../../../storeroom/homes/models";
import { deepCopy } from "../../../utils";
import { useObserver } from "mobx-react";
import Modal from "../../misc/Modal";
import { Alert } from "@material-ui/lab";
import { DialogContentText, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export interface IPetCareModalProps {
  show: boolean;
  onClose: () => void;
}

export default function PetCareModal(props: IPetCareModalProps) {
  const homeStore = useHomeStore();

  const [care, setCare] = useState(homeStore.home?.pet_care_requirements);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.show) {
      setSubmitted(false);
      setError("");
      setCare(homeStore.home?.pet_care_requirements);
    }
  }, [props.show, homeStore.home]);

  const onChange = (event: TextFieldChangeEvent) => {
    setCare(event.target.value);
  };

  const onSave = () => {
    const updatedHome: IHome = deepCopy(homeStore.home);
    updatedHome.pet_care_requirements = care;
    setSubmitted(true);
    homeStore
      .submitUpdates(updatedHome)
      .then(props.onClose)
      .catch(() => setError("Error saving routine."));
  };

  return useObserver(() => {
    const home = homeStore.home;

    return home ? (
      <Modal
        show={props.show}
        maxWidth={"sm"}
        headerChildren={<strong>Pet Care Routine</strong>}
        contentChildren={
          <React.Fragment>
            <DialogContentText>
              Include detailed instructions for your sitter on how to care for
              your pet while you are away, such as tasks and responsibilities.
              This information will be available to all prospective sitters to
              set expectations before booking. Be sure to include important
              health and wellness information, vet contact information in case
              of emergencies, prescription, definite do’s and don’ts, etc.
            </DialogContentText>

            <div style={{ height: "2em" }} />

            {error && (
              <Alert severity={"error"} style={{ marginBottom: "1rem" }}>
                {error}
              </Alert>
            )}

            <TextField
              label={"Routine"}
              fullWidth
              value={care}
              onChange={onChange}
              multiline={true}
              rows={18}
              autoFocus={true}
            />
          </React.Fragment>
        }
        footerChildren={
          <React.Fragment>
            <Button onClick={props.onClose} disabled={submitted}>
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              disabled={submitted}
              onClick={onSave}
            >
              Submit
            </Button>
          </React.Fragment>
        }
      />
    ) : null;
  });
}
