import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { Typography } from "@material-ui/core";
import CheckInInstructionFormDialog from "../forms/CheckInInstructionFormDialog";

export default function CheckInSection() {
  const [showEditForm, setShowEditForm] = useState(false);
  const store = useContext(StoreContext);

  const sectionProps: SectionProps = {
    header: "Check-In Instructions",
    actionIcon: "edit",
    actionLabel: "Edit Instructions",
    onActionClick: () => setShowEditForm(true),
  };

  return (
    <Section {...sectionProps}>
      <CheckInInstructionFormDialog
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <Typography style={sectionBodyStyle}>
        {store.homeStore.home.check_in_instructions}
      </Typography>
    </Section>
  );
}
