import { IPicture } from "../albums/models";
import { IReservation, ReservationStatus } from "../reservations/models";
import { ISelectOption } from "../models";

export interface IHome {
  id: number;
  check_in_instructions: string;
  pet_care_requirements: string;
  album: number;
  location: string;
  longitude: string;
  latitude: string;
  owner: number;
  listing_status: number;
  beds: number;
  bedrooms: number;
  bathrooms: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  home_type: string;
  home_rules: string;
  home_type_options?: Array<ISelectOption>;
}

export interface IHomePreview {
  beds: number;
  bedrooms: number;
  bathrooms: number;
  home_rules: string;
}

export interface IVacancySummary {
  id: number;
  start_date: string;
  end_date: string;
}

export interface IVacancyAcceptedReservation {
  id: number;
  album: Array<IPicture>;
}

export interface IVacancyReservationListItem {
  id: number;
  name: string;
  photo_url: string;
}

export interface IVacancy {
  id: number;
  start_date: string;
  end_date: string;
  full_rental_only: boolean;
  listing: number;
  is_reserved?: boolean;
  reservations?: Array<IReservation>;
  accepted_reservation?: null | IVacancyAcceptedReservation;
  current_requests?: Array<IVacancyReservationListItem>;
  past_requests?: Array<IVacancyReservationListItem>;
  completed?: boolean;
}

export enum ListingStatus {
  draft = 0,
  submitted_for_review = 1,
  published = 2,
}

export interface IListing {
  id: number;
  vacancies: Array<IVacancySummary>;
  title: string;
  description: string;
  neighborhood_description: string;
  other_notes: string;
  status: ListingStatus;
  home: number;
  album?: number;
}

export function calcRequestCount(vacancy: IVacancy): number {
  const requestStatusList = [
    ReservationStatus.requested,
    ReservationStatus.in_review,
  ];
  return vacancy.reservations.filter((reservation: IReservation) =>
    requestStatusList.includes(reservation.status)
  ).length;
}
