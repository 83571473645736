import React from "react";
import { useVacancySearchResult } from "../../contexts";
import { IVacancySearchResultListing } from "../../../../storeroom/homes/searchService/models";
import { Section, sectionBodyStyle } from "../../../base/Section";
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ITestimonial } from "../../../../storeroom/reviews/models";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import AlbumSection from "../../../albums/AlbumSection";

interface ISectionProps {
  listing: IVacancySearchResultListing;
}

interface IReadOnlyTab {
  title: string;
  icon: string;
  subtitle: string | number;
  color: "disabled" | "primary" | "secondary";
}

interface IReadOnlyTabsProps {
  tabs: Array<IReadOnlyTab>;
}

export default function ListingPreviewHomeTab() {
  const listing = useVacancySearchResult().listing;

  return (
    <div>
      <ListingPreviewHomeAlbumSection listing={listing} />
      <ListingPreviewLocationSection listing={listing} />
      <ListingPreviewHomeSection listing={listing} />
      <ListingPreviewHomeRulesSection listing={listing} />
      <ListingPreviewBedsAndRoomsSection listing={listing} />
      <ListingPreviewAmenitiesSection listing={listing} />
      <ListingPreviewReviewsSection listing={listing} />
    </div>
  );
}

function ListingPreviewHomeAlbumSection(props: ISectionProps) {
  return (
    <AlbumSection
      album={props.listing.album}
      viewOnly={true}
      headerOverride={props.listing.title}
      secondary={true}
    />
  );
}

function ListingPreviewHomeSection(props: ISectionProps) {
  return (
    <Section header={"Home"}>
      <Typography style={sectionBodyStyle}>
        {props.listing.description}
      </Typography>
    </Section>
  );
}

function ListingPreviewLocationSection(props: ISectionProps) {
  return (
    <Section header={"Location"}>
      <Typography style={sectionBodyStyle}>{props.listing.location}</Typography>
    </Section>
  );
}

function ReadOnlyTabs(props: IReadOnlyTabsProps) {
  const tabs = props.tabs;
  const gridSize = tabs.length === 3 ? 4 : 3;

  return (
    <Grid container spacing={0} style={{ textAlign: "center" }}>
      {tabs.map((tab: IReadOnlyTab, index: number) => (
        <Grid item xs={gridSize} key={index}>
          <Typography variant={"body2"}>{tab.title}</Typography>
          <div style={{ height: "1rem" }} />
          <div>
            <Icon fontSize={"large"} color={tab.color}>
              {tab.icon}
            </Icon>
          </div>
          {tab.subtitle}
        </Grid>
      ))}
    </Grid>
  );
}

function ListingPreviewBedsAndRoomsSection(props: ISectionProps) {
  const { beds, bedrooms, bathrooms } = props.listing.home;

  const tabs: Array<IReadOnlyTab> = [
    {
      title: "Beds",
      icon: "king_bed",
      subtitle: beds,
      color: beds === 0 ? "disabled" : "secondary",
    },
    {
      title: "Bedrooms",
      icon: "meeting_room",
      subtitle: bedrooms,
      color: bedrooms === 0 ? "disabled" : "secondary",
    },
    {
      title: "Bathrooms",
      icon: "bathtub",
      subtitle: bathrooms,
      color: bathrooms === 0 ? "disabled" : "secondary",
    },
  ];

  return (
    <Section header={"Beds and Rooms"}>
      <ReadOnlyTabs tabs={tabs} />
    </Section>
  );
}

function ListingPreviewAmenitiesSection(props: ISectionProps) {
  return (
    <Section header={"Amenities"}>
      <List>
        {props.listing.amenities.map((amenity) => (
          <ListItem key={amenity.id}>
            <ListItemIcon>
              <Icon>{amenity.icon}</Icon>
            </ListItemIcon>
            <ListItemText>{amenity.display_name}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Section>
  );
}

function ListingPreviewReviewsSection(props: ISectionProps) {
  const { averages, testimonials } = props.listing.review_summary;

  const averagesList: Array<IReadOnlyTab> = [
    {
      title: "Rating",
      icon: "star",
      subtitle: `${averages.rating} / 5`,
      color: "secondary",
    },
    {
      title: "Home Accuracy",
      icon: "thumb_up",
      subtitle: `${averages.home_accuracy}%`,
      color: averages.home_accuracy > 50 ? "secondary" : "disabled",
    },
    {
      title: "Pet Accuracy",
      icon: "thumb_up",
      subtitle: `${averages.pet_accuracy}%`,
      color: averages.pet_accuracy > 50 ? "secondary" : "disabled",
    },
    {
      title: "Communication",
      icon: "thumb_up",
      subtitle: `${averages.communication}%`,
      color: averages.communication > 50 ? "secondary" : "disabled",
    },
  ];

  return averages.rating === null ? (
    <Section header={"Reviews"}>This home has not been reviewed yet.</Section>
  ) : (
    <Section header={"Reviews"}>
      <ReadOnlyTabs tabs={averagesList} />

      <Typography variant={"body1"} style={{ marginTop: "3rem" }}>
        Recent testimonials:
      </Typography>

      <List>
        {testimonials.map((testimonial: ITestimonial, index: number) => (
          <ListItem alignItems={"flex-start"} key={index}>
            <ListItemAvatar>
              <Avatar src={testimonial.reviewer.avatar} />
            </ListItemAvatar>

            <ListItemText
              primary={
                <Typography variant={"body1"}>
                  {testimonial.comments}
                </Typography>
              }
              secondary={testimonial.reviewer.name}
            />
          </ListItem>
        ))}
      </List>
    </Section>
  );
}

function ListingPreviewHomeRulesSection(props: ISectionProps) {
  return (
    <Section header={"Home Rules"}>
      <Typography style={sectionBodyStyle}>
        {props.listing.home.home_rules}
      </Typography>
    </Section>
  );
}
