import React from "react";
import { useVacancySearchResult } from "../../contexts";
import { IVacancySearchResultListing } from "../../../../storeroom/homes/searchService/models";
import { Section, sectionBodyStyle } from "../../../base/Section";
import { Typography } from "@material-ui/core";

interface ISectionProps {
  listing: IVacancySearchResultListing;
}

export default function ListingPreviewOwnerTab() {
  const listing = useVacancySearchResult().listing;

  return (
    <div>
      <ListingPreviewOwnerSection listing={listing} />
    </div>
  );
}

function ListingPreviewOwnerSection(props: ISectionProps) {
  return (
    <Section header={`About ${props.listing.owner.name}`}>
      <Typography style={sectionBodyStyle}>
        {props.listing.owner.owner_description}
      </Typography>
    </Section>
  );
}
