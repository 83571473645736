import React from "react";
import { Dialog, DialogProps, DialogTitleProps } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useOwnerStyles, useSitterStyles } from "../base/styles";

export interface IModalProps {
  show: boolean;
  headerChildren?: any;
  contentChildren?: any;
  footerChildren?: any;
  secondary?: boolean;
  onFormSubmit?: any;
  maxWidth?: "xs" | "sm" | "md";
}

export default function Modal(props: IModalProps) {
  const dialogProps: DialogProps = {
    open: props.show,
    fullWidth: true,
    maxWidth: props.maxWidth ? props.maxWidth : "md",
  };

  return (
    <Dialog {...dialogProps}>
      {props.onFormSubmit ? (
        <form onSubmit={props.onFormSubmit}>
          <ModalContent {...props} />
        </form>
      ) : (
        <ModalContent {...props} />
      )}
    </Dialog>
  );
}

function ModalContent(props: IModalProps) {
  const ownerClasses = useOwnerStyles();
  const sitterClasses = useSitterStyles();

  const dialogTitleProps: DialogTitleProps = {
    disableTypography: true,
    children: props.headerChildren,
    className: props.secondary
      ? sitterClasses.modalHeader
      : ownerClasses.modalHeader,
  };

  return (
    <React.Fragment>
      <DialogTitle {...dialogTitleProps} />

      <DialogContent>
        <div style={{ height: "1.5rem" }} />
        {props.contentChildren}
      </DialogContent>

      <DialogActions>{props.footerChildren}</DialogActions>
    </React.Fragment>
  );
}
