import { IPicture } from "./models";
import { HttpClient } from "../HttpClient";

export default class PictureHttpClient {
  static post(picture: IPicture, file: File): Promise<IPicture> {
    const formData = new FormData();
    formData.append("picture", file);
    formData.append("subject", `${picture.subject}`);
    formData.append("sequence", `${picture.sequence}`);
    formData.append("uploaded_by", `${picture.uploaded_by}`);

    return HttpClient.postFile<IPicture>("pictures/", formData);
  }

  static update(picture: IPicture): Promise<IPicture> {
    return HttpClient.put<IPicture>(`pictures/${picture.id}/`, picture);
  }

  static delete(id: number): Promise<any> {
    return HttpClient.delete(`pictures/${id}/`);
  }
}
