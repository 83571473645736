import React, { FormEvent, useState } from "react";
import styles from "./auth.module.sass";
import logo from "../../jetsyt-logo-orange.png";
import {
  Button,
  ButtonGroup,
  createStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ICredentials } from "../../storeroom/auth/models";
import { TextFieldChangeEvent } from "../models";
import { useAuthStore, useTabletOrLarger } from "../hooks";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { useHistory } from "react-router-dom";
import { publicRoutePaths } from "../../routes/PublicRoutes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
    },
    loginPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
    },
    accentPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
      backgroundColor: theme.palette.secondary.main,
      textAlign: "center",
    },
    welcomeBackText: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
      textAlign: "center",
      fontSize: 64,
      color: "#15113b",
      lineHeight: "68px",
    },
    accentText: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
    },
  })
);

export default function LoginPage() {
  const authStore = useAuthStore();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const isWide = useTabletOrLarger();
  const classes = useStyles();

  const onEmailChange = (event: TextFieldChangeEvent) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: TextFieldChangeEvent) => {
    setPassword(event.target.value);
  };

  const onError = () => {
    setError("Invalid credentials");
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const credentials: ICredentials = {
      username: email,
      password: password,
    };

    authStore.authenticate(credentials, onError);
  };

  const onLogoClick = () => {
    history.push(publicRoutePaths.front());
  };

  const errorAlert = error ? (
    <Alert severity={"error"} style={{ width: "calc(100% - 2em)" }}>
      {error}
    </Alert>
  ) : null;

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} md={6} className={classes.loginPanel}>
        <form
          className={styles.loginForm}
          autoComplete={"off"}
          onSubmit={onSubmit}
        >
          <img
            src={logo}
            alt={"logo"}
            onClick={onLogoClick}
            style={{ cursor: "pointer" }}
          />

          <Typography variant={"h3"} className={classes.welcomeBackText}>
            Welcome back!
          </Typography>

          <div style={{ flexGrow: 1 }} />

          {errorAlert}

          <TextField
            label={"Email"}
            fullWidth
            color={"primary"}
            onChange={onEmailChange}
          />

          <TextField
            label={"Password"}
            type={"password"}
            fullWidth
            color={"primary"}
            onChange={onPasswordChange}
          />

          <ButtonGroup fullWidth>
            <Button
              type={"submit"}
              color={"primary"}
              variant={"contained"}
              endIcon={<Icon>arrow_right_alt</Icon>}
            >
              Log in
            </Button>
          </ButtonGroup>
        </form>
      </Grid>

      {isWide && (
        <Grid item xs={6} className={classes.accentPanel}>
          <Typography variant={"h3"} className={classes.accentText}>
            Connect with pet lovers now!
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
