import React, { FormEvent, useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import { Button, TextField } from "@material-ui/core";
import { deepCopy } from "../../../utils";
import { IOwner } from "../../../storeroom/users/models";
import Modal from "../../misc/Modal";

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function OwnerDescriptionFormDialog(props: Props) {
  const store = useContext(StoreContext);
  const owner = store.ownerStore.owner;

  const [pending, setPending] = useState(false);
  const [description, setDescription] = useState<string>(
    owner.owner_description
  );

  useEffect(() => {
    if (props.show) {
      setPending(false);
    }
  }, [props.show]);

  const onChange = (event: any) => {
    setDescription(event.target.value);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const updatedOwner: IOwner = deepCopy(owner);
    updatedOwner.owner_description = description;

    setPending(true);
    store.ownerStore
      .update(updatedOwner)
      .then(() => props.onClose())
      .catch(() => setPending(false));
  };

  return useObserver(() => (
    <Modal
      show={props.show}
      maxWidth={"sm"}
      onFormSubmit={onSubmit}
      headerChildren={<strong>About Me</strong>}
      contentChildren={
        <React.Fragment>
          <TextField
            label={"Description"}
            fullWidth
            defaultValue={description}
            onChange={onChange}
            multiline
            rows={18}
            autoFocus={true}
          />
        </React.Fragment>
      }
      footerChildren={
        <React.Fragment>
          <Button onClick={props.onClose} disabled={pending}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            variant={"contained"}
            color={"primary"}
            disabled={pending}
          >
            Submit
          </Button>
        </React.Fragment>
      }
    />
  ));
}
