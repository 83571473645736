import React, { FormEvent, useEffect, useState } from "react";
import { useProfileStore } from "../../../hooks";
import Modal from "../../../misc/Modal";
import { TextField } from "@material-ui/core";
import { TextFieldChangeEvent } from "../../../models";
import { Alert } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

export interface IPhoneVerificationFormDialogProps {
  show: boolean;
  onClose: (canceled: boolean) => void;
}

export default function PhoneVerificationFormDialog(
  props: IPhoneVerificationFormDialogProps
) {
  const profileStore = useProfileStore();
  const [otpSent, setOTPSent] = useState(false);
  const [otp, setOTP] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.show) {
      setError(null);
      profileStore
        .sendPhoneVerification()
        .then(() => setOTPSent(true))
        .catch(() => setError("Failed to send code"));
    }
  }, [props.show, profileStore]);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    profileStore
      .verifyPhone(otp)
      .then(() => props.onClose(false))
      .catch(() => setError("Invalid code"));
  };

  const onChange = (event: TextFieldChangeEvent) => {
    setOTP(event.target.value);
  };

  const sendOTP = () => {
    profileStore
      .sendPhoneVerification()
      .then(() => setOTPSent(true))
      .catch(() => setError("Failed to send code"));
  };

  return (
    <Modal
      show={props.show}
      maxWidth={"sm"}
      onFormSubmit={onSubmit}
      headerChildren={<strong>Verify Phone</strong>}
      contentChildren={
        <React.Fragment>
          <DialogContentText>
            A code was sent to the following number. Enter that code below.
          </DialogContentText>

          <TextField
            label={"Phone"}
            fullWidth
            value={profileStore.profile.phone}
            disabled={true}
          />

          <div style={{ height: "0.5rem" }} />

          <TextField
            label={"Code"}
            fullWidth
            value={otp}
            onChange={onChange}
            autoFocus={true}
            disabled={!otpSent}
          />

          {error && (
            <div style={{ marginTop: "2rem" }}>
              <Alert severity={"error"}>{error}</Alert>
            </div>
          )}
        </React.Fragment>
      }
      footerChildren={
        <React.Fragment>
          <Button onClick={sendOTP}>Send Code Again</Button>

          <div style={{ flex: 1 }} />

          <Button onClick={() => props.onClose(true)}>Cancel</Button>
          <Button type={"submit"} variant={"contained"} color={"primary"}>
            Submit
          </Button>
        </React.Fragment>
      }
    />
  );
}
