import React, { useContext } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import { Section } from "../../base/Section";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  IReservation,
  isInactiveReservation,
} from "../../../storeroom/reservations/models";
import { humanizeDateRange } from "../../../formattingService";

export default function CurrentRequestsSection() {
  const history = useHistory();
  const store = useContext(StoreContext);
  const trip = store.tripStore.trip;

  const onReservationClick = (reservation: IReservation) => {
    history.push(`/reservations/${reservation.id}`, {
      tripID: trip.id,
      tripLabel: humanizeDateRange(trip.start_date, trip.end_date),
    });
  };

  const filterOutAccepted = (reservation: IReservation) => {
    return reservation.id !== trip.accepted_reservation?.id;
  };

  return useObserver(() => (
    <Section header={"Current Requests"}>
      <Grid container spacing={2}>
        {trip.reservations
          .filter((reservation) => !isInactiveReservation(reservation))
          .filter((reservation) => filterOutAccepted(reservation))
          .map((reservation) => (
            <Grid item key={reservation.id} xs={12}>
              <Card>
                <CardActionArea onClick={() => onReservationClick(reservation)}>
                  <CardMedia
                    component={"img"}
                    image={reservation.album.cover_photo_url}
                    height={240}
                  />
                  <CardContent>
                    <Typography>{reservation.other_user.name}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Section>
  ));
}
