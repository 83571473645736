import React from "react";
import { useVacancyStore } from "../../hooks";
import { Section, SectionProps } from "../../base/Section";
import { useHistory } from "react-router-dom";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import { Typography } from "@material-ui/core";
import Gallery from "react-photo-gallery";
import { IPicture } from "../../../storeroom/albums/models";

function galleryPhoto(picture: IPicture) {
  return {
    src: picture.url,
    width: picture.width,
    height: picture.height,
  };
}

export default function AcceptedReservationSection() {
  const history = useHistory();
  const vacancyStore = useVacancyStore();
  const reservation = vacancyStore.vacancy.accepted_reservation;

  const sectionProps: SectionProps =
    reservation === null
      ? {
          header: "Accepted Reservation",
        }
      : {
          header: "Accepted Reservation",
          actionIcon: "launch",
          actionLabel: "Details",
          onActionClick: () =>
            history.push(privateRoutePaths.reservationDetails(reservation.id)),
        };

  return (
    <Section {...sectionProps}>
      {reservation === null ? (
        <Typography variant={"body1"}>No Accepted Requests</Typography>
      ) : (
        <Gallery photos={reservation.album.map(galleryPhoto)} />
      )}
    </Section>
  );
}
