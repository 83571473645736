import React from "react";
import { IReservationForHost } from "../../storeroom/reservations/models";
import { useReservationStore } from "../hooks";
import { HostReservationContext } from "./contexts";
import RecentConversationSection from "../conversations/sections/RecentConversationSection";
import HostReservationToolbar from "./toolbars/HostReservationToolbar";
import ReservationGuestSection from "./sections/ReservationGuestSection";
import { ReservationBannerForHost } from "./banners/ReservationBannerForHost";
import ReservationTimeline from "./ReservationTimeline";

export default function ReservationForHostPage() {
  const reservation: IReservationForHost = useReservationStore()
    .reservation as IReservationForHost;

  return (
    <HostReservationContext.Provider value={reservation}>
      <HostReservationToolbar />
      <ReservationBannerForHost />
      <ReservationTimeline reservation={reservation} />
      <RecentConversationSection conversationID={reservation.conversation} />
      <ReservationGuestSection />
    </HostReservationContext.Provider>
  );
}
