import React, { useContext } from "react";
import { AlbumStoreContext } from "../../../../storeroom/albums/AlbumStore";
import {
  createStyles,
  Icon,
  IconButtonProps,
  IconProps,
  Theme,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      pointerEvents: "none",
    },
  })
);

export interface IEditableImageProps {
  index: any;
  photo: any;
  direction?: string;
  margin?: any;
  top?: any;
  left?: any;
  onClick?: any;
}

export default function AlbumModalEditableImage(props: IEditableImageProps) {
  const store = useContext(AlbumStoreContext);
  const classes = useStyles();

  const { photo, margin } = props;

  const deleteClick = () => {
    store.removePictureBySrc(photo.src);
  };

  const divStyle: React.CSSProperties = {
    position: "relative",
    margin: margin,
    height: photo.height,
    width: photo.width,
    zIndex: 10000,
  };

  const deleteButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: 4,
    right: 4,
    zIndex: 10010,
  };

  const deleteButtonProps: IconButtonProps = {
    style: deleteButtonStyle,
    onClick: deleteClick,
    classes: classes,
    size: "small",
  };

  const deleteIconStyle: React.CSSProperties = {
    padding: 2,
    borderRadius: 4,
    backgroundColor: "white",
  };

  const deleteIconProps: IconProps = {
    style: deleteIconStyle,
  };

  return (
    <div style={divStyle}>
      <img {...photo} alt={"img"} />

      <IconButton {...deleteButtonProps}>
        <Icon {...deleteIconProps}>delete</Icon>
      </IconButton>
    </div>
  );
}
