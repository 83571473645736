import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoutes, { privateRoutes } from "./PrivateRoutes";
import PublicRoutes, { publicRoutes } from "./PublicRoutes";

export interface IRoute {
  path: string;
  component: any;
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={publicRoutes} exact={true}>
          <PublicRoutes />
        </Route>

        <Route path={privateRoutes} exact={true}>
          <PrivateRoutes />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
