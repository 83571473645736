import { action, observable } from "mobx";
import { IAuthStatus, ICredentials, ITokenSet } from "./models";
import { HttpClient } from "../HttpClient";
import AuthHttpClient from "./AuthHttpClient";

const ACCESS_TOKEN_DESCRIPTOR = `jetsyt-${process.env.REACT_APP_ENV_NAME}-access`;
const REFRESH_TOKEN_DESCRIPTOR = `jetsyt-${process.env.REACT_APP_ENV_NAME}-refresh`;

export class AuthStore {
  @observable authStatus: IAuthStatus;

  constructor() {
    this.resetStatus();
  }

  @action private setVerification = (verified: boolean) => {
    this.authStatus = { loading: false, verified: verified };
  };

  private resetStatus = () => {
    if (!this.authStatus?.loading) {
      this.authStatus = {
        loading: true,
        verified: false,
      };
    }
  };

  private postAuthenticate = (tokens: ITokenSet) => {
    localStorage.setItem(ACCESS_TOKEN_DESCRIPTOR, tokens.access);
    localStorage.setItem(REFRESH_TOKEN_DESCRIPTOR, tokens.refresh);
    this.setVerification(true);
  };

  static getAccessToken = (): string | null => {
    return localStorage.getItem(ACCESS_TOKEN_DESCRIPTOR);
  };

  verify = () => {
    if (!this.authStatus.verified) {
      this.resetStatus();
      AuthHttpClient.verify(AuthStore.getAccessToken())
        .then(() => this.setVerification(true))
        .catch(() => this.setVerification(false));
    }
  };

  authenticate = (
    credentials: ICredentials,
    error?: (error: Response) => void
  ) => {
    this.resetStatus();
    HttpClient.post<ITokenSet>("users/jwt/create/", credentials)
      .then((response: ITokenSet) => this.postAuthenticate(response))
      .catch(error);
  };

  clear = () => {
    localStorage.removeItem(ACCESS_TOKEN_DESCRIPTOR);
    localStorage.removeItem(REFRESH_TOKEN_DESCRIPTOR);
    this.setVerification(false);
  };
}
