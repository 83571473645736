import React from "react";
import { AppBar, Container, useScrollTrigger } from "@material-ui/core";

export function ElevationAppBar(props: { children: any }) {
  const trigger = useScrollTrigger({ threshold: 0 });

  return (
    <AppBar position={"sticky"} color={"default"} elevation={trigger ? 4 : 0}>
      <Container maxWidth={"md"} disableGutters={true}>
        {props.children}
      </Container>
    </AppBar>
  );
}
