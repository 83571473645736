import { AppBar, Container, Toolbar } from "@material-ui/core";
import React from "react";
import { IPet } from "../../../storeroom/pets/models";
import Crumbs, { ICrumbProps } from "../../base/Crumbs";

interface Props {
  pet: IPet;
}

export default function PetPageToolbar(props: Props) {
  const crumbProps: ICrumbProps = {
    currentLabel: props.pet ? props.pet.name : "Loading...",
    links: [
      {
        label: "My Home",
        url: "/home",
      },
      {
        label: "My Pets",
        url: "/pets",
      },
    ],
  };

  return (
    <AppBar position={"sticky"} style={{ marginBottom: "2em" }} elevation={4}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Toolbar>
          <Crumbs {...crumbProps} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
