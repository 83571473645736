import React from "react";
import BasePublicPage from "../BasePublicPage";
import officeJPG from "../../../images/aboutPage/office.jpg";
import { createStyles, Theme, Typography, Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTabletOrLarger } from "../../hooks";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      textAlign: "center",
      color: "white",
      backgroundColor: "rgba(21, 17, 59, 0.8)",
    },
  })
);

const MissionSection = () => {
  return (
    <div style={{ backgroundColor: "white", padding: "3rem 0" }}>
      <Container maxWidth={"sm"}>
        <Typography variant={"body1"}>
          Our mission is to break down the financial barriers that people face
          when they travel. For travelers, it’s the high cost of accommodations.
          For pet parents, it’s the cost of hiring at-home pet care. As die-hard
          wanderers and pet lovers ourselves, we know that traveling is a key
          ingredient to better understanding each other. It is this notion that
          sparked jetsyt. Using the Jetsyt platform, members can find
          accommodations at no cost in exchange for pet sitting while pet
          parents get a sitter at about $1 per day.
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Link
              href={
                "https://sites.google.com/jetsyt.com/help-center/code-of-conduct"
              }
              target={"_blank"}
              style={{ textDecoration: "none" }}
            >
              <Button variant={"contained"} color={"primary"} fullWidth={true}>
                Code of Conduct
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default function AboutPage() {
  const bgStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    zIndex: -100,
  };

  return (
    <BasePublicPage>
      <img src={officeJPG} style={bgStyle} alt={"office"} />
      <HeaderSection />
      <MissionSection />
    </BasePublicPage>
  );
}

function HeaderSection() {
  const classes = useStyles();

  const isWide = useTabletOrLarger();
  const containerStyle: React.CSSProperties = isWide
    ? {
        padding: "8rem 1rem",
      }
    : {
        padding: "4rem 1rem",
      };

  const headingStyle: React.CSSProperties = {
    fontFamily: "Playfair Display",
    fontWeight: 700,
  };

  return (
    <div className={classes.headerContainer} style={containerStyle}>
      <Typography variant={"h3"} style={headingStyle}>
        We are Jetsyt
      </Typography>
    </div>
  );
}
