import { action, observable } from "mobx";
import IBasePetBuilderStepStore from "./IBasePetBuilderStepStore";

export default class PetDescStepStore implements IBasePetBuilderStepStore {
  @observable description: string = "";
  @observable canSubmit: boolean = true;

  @action setDescription = (desc: string) => (this.description = desc);

  submit = (): Promise<null> => Promise.resolve(null);
}
