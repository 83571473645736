import { action, observable } from "mobx";
import { IVacancyListItem } from "./models";
import VacancyHttpClient from "./VacancyHttpClient";

export default class VacancyListStore {
  @observable vacancies: Array<IVacancyListItem> = [];
  @observable loading: boolean = false;

  @action setList = (newList: Array<IVacancyListItem>) => {
    this.loading = false;
    this.vacancies = newList;
  };

  load = (completed?: boolean) => {
    this.loading = true;
    VacancyHttpClient.list({ completed: completed }).then(this.setList);
  };

  remove = (id: number): Promise<void> => {
    return VacancyHttpClient.delete(id).then(() => this.postRemove(id));
  };

  private postRemove = (id: number) => {
    this.vacancies = this.vacancies.filter((vacancy) => vacancy.id !== id);
  };
}
