import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../../jetsyt-logo-orange.png";
import styles from "../menu.module.sass";
import { Icon, IconButton } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import { useConversationListStore, useProfileStore } from "../../hooks";
import SitterMenu from "./menus/SitterMenu";
import OwnerMenu from "./menus/OwnerMenu";
import { IProfile } from "../../../storeroom/users/models";

function shouldForceDropDowns(profile: IProfile) {
  return Boolean(profile)
    ? Boolean(profile.owner) && Boolean(profile.sitter)
    : false;
}

function LogoItem() {
  const history = useHistory();

  const onLogoClick = () => {
    history.push("/");
  };

  return (
    <img
      src={logo}
      alt={"logo"}
      className={styles.homeLink}
      onClick={onLogoClick}
    />
  );
}

function ConversationsButton() {
  const history = useHistory();
  const conversationListStore = useConversationListStore();

  const goToConversationList = () => {
    history.push(privateRoutePaths.conversationList());
  };

  return useObserver(() => {
    const conversations = conversationListStore.conversations;
    const unread = conversations?.filter((convo) => convo.unread);
    const hasUnread = unread?.length > 0;

    const color = hasUnread ? "primary" : "default";
    const icon = hasUnread ? "mark_email_unread" : "email";

    return (
      <IconButton onClick={goToConversationList} color={color}>
        <Icon>{icon}</Icon>
      </IconButton>
    );
  });
}

export default function MainMenu() {
  const profileStore = useProfileStore();

  return useObserver(() => {
    const profile = profileStore.profile;
    const forceDropDowns = shouldForceDropDowns(profile);

    return (
      <React.Fragment>
        <LogoItem />
        {profile?.owner && <OwnerMenu forceList={forceDropDowns} />}
        {profile?.sitter && <SitterMenu forceList={forceDropDowns} />}

        <div style={{ flexGrow: 1 }} />

        <ConversationsButton />
      </React.Fragment>
    );
  });
}
