import { IChecklist } from "../checklists/models";

export interface IPetType {
  name: string;
  display_name: string;
}

export interface IPet {
  id: number;
  album: number;
  name: string;
  type: string;
  description: string;
  home: number;
  breed: string;
  care: IChecklist;
}

export function generateDefaultPet(): IPet {
  return {
    id: null,
    album: null,
    name: "",
    type: "",
    description: "",
    home: null,
    breed: "",
    care: null,
  };
}
