import React, { useContext } from "react";
import { ICrumbProps } from "../../base/Crumbs";
import {
  IReservationForGuest,
  ReservationStatus,
} from "../../../storeroom/reservations/models";
import { humanizeDateRange } from "../../../formattingService";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar from "../../base/PageToolbar";
import { GuestReservationContext } from "../contexts";
import { useReservationStore } from "../../hooks";
import { useHistory } from "react-router-dom";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import { ReservationStore } from "../../../storeroom/reservations/ReservationStore";

export default function GuestReservationToolbar() {
  const history = useHistory();
  const reservationStore = useReservationStore();
  const reservation: IReservationForGuest = useContext(GuestReservationContext);

  const actions = buildActions(history, reservationStore, reservation);

  const crumbs: ICrumbProps = {
    currentLabel: "Reservation",
    links: [
      {
        label: "My Trips",
        url: "/trips",
      },
      {
        label: humanizeDateRange(reservation.start_date, reservation.end_date),
        url: `/trips/${reservation.trip}`,
      },
    ],
  };

  return <PageToolbar crumbs={crumbs} actions={actions} secondary={true} />;
}

const buildActions = (
  history: any,
  reservationStore: ReservationStore,
  reservation: IReservationForGuest
) => {
  const readOnly = [
    ReservationStatus.canceled,
    ReservationStatus.completed,
    ReservationStatus.expired,
    ReservationStatus.rejected,
  ].includes(reservation.status);

  const actions: Array<IPageToolbarAction> = [];

  if (!readOnly) {
    if (reservation.status <= ReservationStatus.accepted) {
      actions.push(
        buildCancelAction(reservation.status, history, reservationStore)
      );
    }

    actions.push(buildMessengerActon(history, reservation));

    if (reservation.status === ReservationStatus.accepted) {
      actions.push(buildCheckInAction(reservationStore));
    }

    if (reservation.status === ReservationStatus.started) {
      actions.push(buildCheckoutAction(reservationStore));
    }
  }

  return actions;
};

const buildCancelAction = (
  status: ReservationStatus,
  history: any,
  store: ReservationStore
): IPageToolbarAction => {
  const onRetractClick = () => {
    store
      .retract()
      .then(() => history.push("/trips"))
      .catch(() => alert("Reservation could not be retracted"));
  };

  const onCancelClick = () => {
    store
      .cancel()
      .then(() => history.push("/trips"))
      .catch(() => alert("Reservation could not be canceled"));
  };

  const onClick = () => {
    switch (status) {
      case ReservationStatus.requested:
      case ReservationStatus.in_review:
        onRetractClick();
        break;

      default:
        onCancelClick();
        break;
    }
  };

  return {
    label: "Cancel",
    icon: "delete",
    forceIcon: true,
    onClick: onClick,
  };
};

const buildMessengerActon = (
  history: any,
  reservation: IReservationForGuest
): IPageToolbarAction => {
  const onClick = () => {
    history.push(privateRoutePaths.conversation(reservation.conversation));
  };

  return {
    label: "Messenger",
    icon: "launch",
    forceIcon: true,
    onClick: onClick,
  };
};

const buildCheckInAction = (store: ReservationStore): IPageToolbarAction => {
  const onError = () => {
    alert("Check in failed");
  };

  const onClick = () => {
    store.start(onError);
  };

  return {
    label: "Check In",
    icon: "login",
    forceIcon: true,
    onClick: onClick,
  };
};

const buildCheckoutAction = (
  reservationStore: ReservationStore
): IPageToolbarAction => {
  const onClick = () => {
    reservationStore.complete();
  };

  return {
    label: "Check Out",
    icon: "logout",
    forceIcon: true,
    onClick: onClick,
  };
};
