import React, { useContext, useEffect, useState } from "react";
import SitterReviewStore from "../../../storeroom/reviews/sitterReviews/SitterReviewStore";
import { ISitterReview } from "../../../storeroom/reviews/models";
import { useObserver } from "mobx-react";
import { Rating, RatingProps } from "@material-ui/lab";
import FieldRow from "./FieldRow";
import ThumbsField from "./ThumbsField";
import { TextFieldChangeEvent } from "../../models";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Modal from "../../misc/Modal";

const SitterReviewContext = React.createContext<SitterReviewStore>(null);

export interface ISitterReviewModalProps {
  show: boolean;
  onClose: (sitterReview: ISitterReview) => void;
  reservationID?: number;
  sitterID?: number;
  reviewID?: number;
}

export default function SitterReviewModal(props: ISitterReviewModalProps) {
  const [store, setStore] = useState<SitterReviewStore>(null);
  const { onClose } = props;

  useEffect(() => {
    if (props.show) {
      setStore(
        new SitterReviewStore(
          props.reviewID,
          props.reservationID,
          props.sitterID
        )
      );
    }
  }, [props.show, props.reviewID, props.reservationID, props.sitterID]);

  return useObserver(() => {
    return store ? (
      <SitterReviewContext.Provider value={store}>
        <Modal
          show={props.show}
          headerChildren={"Sitter Review"}
          contentChildren={<ModalContent />}
          footerChildren={<ModalActions onClose={onClose} />}
        />
      </SitterReviewContext.Provider>
    ) : null;
  });
}

function RatingField() {
  const store = useContext(SitterReviewContext);

  const onRatingChange = (event: any, rating: any) => {
    if ([1, 2, 3, 4, 5].includes(rating)) {
      store.setRating(rating);
    } else {
      store.setRating(null);
    }
  };

  return useObserver(() => {
    const ratingProps: RatingProps = {
      value: store.review.rating,
      onChange: onRatingChange,
      size: "large",
      name: "overallRating",
    };

    return (
      <FieldRow label={"Overall Experience"}>
        <Rating {...ratingProps} />
      </FieldRow>
    );
  });
}

function PetCareField() {
  const store = useContext(SitterReviewContext);

  return useObserver(() => {
    return (
      <ThumbsField
        label={"Pet Care"}
        thumbState={store.review.pet_care}
        onChange={store.setPetCare}
        helpText={
          "The sitter did a satisfactory job following the pet care routine."
        }
      />
    );
  });
}

function HouseRulesField() {
  const store = useContext(SitterReviewContext);

  return useObserver(() => {
    return (
      <ThumbsField
        label={"House Rules"}
        thumbState={store.review.house_rules}
        onChange={store.setHouseRules}
        helpText={
          "The sitter did a satisfactory job following the house rules."
        }
      />
    );
  });
}

function CommunicationField() {
  const store = useContext(SitterReviewContext);

  return useObserver(() => {
    return (
      <ThumbsField
        label={"Pet Care"}
        thumbState={store.review.communication}
        onChange={store.setCommunication}
        helpText={
          "The sitter was timely and helpful when responding to my questions."
        }
      />
    );
  });
}

function CommentsField() {
  const store = useContext(SitterReviewContext);

  const onChange = (event: TextFieldChangeEvent) => {
    store.setSummary(event.target.value);
  };

  return useObserver(() => {
    return (
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={store.review.summary}
          onChange={onChange}
          label={"Comments"}
          multiline
          rows={6}
        />
      </Grid>
    );
  });
}

function SpacingField() {
  return (
    <Grid item xs={12}>
      <div style={{ height: 0 }} />
    </Grid>
  );
}

function ModalContent() {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <RatingField />
        <SpacingField />
        <PetCareField />
        <HouseRulesField />
        <CommunicationField />
        <CommentsField />
      </Grid>
    </React.Fragment>
  );
}

interface IModalActionsProps {
  onClose: (review: ISitterReview) => void;
}

function ModalActions(props: IModalActionsProps) {
  const store = useContext(SitterReviewContext);
  const { onClose } = props;

  const onSubmit = () => {
    store.save().then(() => onClose(store.review));
  };

  const onCancel = () => {
    props.onClose(null);
  };

  return useObserver(() => {
    return (
      <React.Fragment>
        <Button disabled={store.loading} size={"small"} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          disabled={!store.canSubmit}
          size={"small"}
          variant={"contained"}
          color={"primary"}
          onClick={onSubmit}
        >
          Save
        </Button>
      </React.Fragment>
    );
  });
}
