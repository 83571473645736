import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import styles from "./page.module.sass";
import { headerStyle } from "./Section";
import { Skeleton } from "@material-ui/lab";

interface Props {
  header: string;
  fullWidth?: boolean;
  cards?: boolean;
}

export default function LoadingSection(props: Props) {
  return (
    <Container maxWidth={"md"} className={styles.section}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={props.fullWidth ? 12 : 5}>
          <Typography style={headerStyle} className={styles.header}>
            {props.header}
          </Typography>
        </Grid>

        <Grid item xs={12} md={props.fullWidth ? 12 : 7}>
          {props.cards && (
            <Skeleton variant={"rect"} animation={"wave"} height={120} />
          )}
          <Skeleton variant={"text"} animation={"wave"} />
        </Grid>
      </Grid>
    </Container>
  );
}
