import React, { FormEvent, useEffect } from "react";
import { useTabletOrLarger, useVacancySearchStore } from "../../../hooks";
import { useObserver } from "mobx-react";
import {
  Button,
  Container,
  Grid,
  Icon,
  Paper,
  TextField,
} from "@material-ui/core";

export default function VacancySearchForm() {
  const store = useVacancySearchStore();
  const isBigScreen = useTabletOrLarger();

  useEffect(() => {
    store.load();
  });

  const onSubmit = (formEvent: FormEvent) => {
    formEvent.preventDefault();
    store.search().then();
  };

  return useObserver(() => {
    const searchParams = store.searchParams;
    const canSubmit =
      Boolean(searchParams.destination) &&
      Boolean(searchParams.start_date) &&
      Boolean(searchParams.end_date);

    const destSpan = isBigScreen ? 4 : 12;
    const inSpan = isBigScreen ? 3 : 5;
    const outSpan = isBigScreen ? 3 : 5;
    const buttonSpan = 2;

    return (
      <Paper elevation={0}>
        <Container disableGutters={true}>
          <form onSubmit={onSubmit} style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} />

              <Grid item xs={destSpan}>
                <TextField
                  fullWidth
                  label={"Destination"}
                  value={searchParams.destination}
                  onChange={(event) =>
                    (searchParams.destination = event.target.value)
                  }
                  color={"secondary"}
                  autoFocus={true}
                />
              </Grid>

              <Grid item xs={inSpan}>
                <TextField
                  label={"Check in"}
                  type={"date"}
                  value={searchParams.start_date}
                  onChange={(event) =>
                    (searchParams.start_date = event.target.value)
                  }
                  fullWidth
                  color={"secondary"}
                />
              </Grid>

              <Grid item xs={outSpan}>
                <TextField
                  label={"Check out"}
                  type={"date"}
                  value={searchParams.end_date}
                  onChange={(event) =>
                    (searchParams.end_date = event.target.value)
                  }
                  fullWidth
                  color={"secondary"}
                />
              </Grid>

              <Grid item xs={buttonSpan} style={{ overflow: "hidden" }}>
                {isBigScreen ? (
                  <Button
                    color={"secondary"}
                    variant={"contained"}
                    type={"submit"}
                    disabled={!canSubmit}
                    startIcon={<Icon>search</Icon>}
                    style={{ width: "100%", height: "100%" }}
                    disableElevation={true}
                  >
                    Search
                  </Button>
                ) : (
                  <Button
                    color={"secondary"}
                    variant={"contained"}
                    type={"submit"}
                    disabled={!canSubmit}
                    style={{ width: "100%", height: "100%", minWidth: "48px" }}
                    disableElevation={true}
                  >
                    <Icon>search</Icon>
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} />
            </Grid>
          </form>
        </Container>
      </Paper>
    );
  });
}
