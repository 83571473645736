import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import AddressSection from "./sections/AddressSection";
import CheckInSection from "./sections/CheckInSection";
import AmenitiesSection from "./sections/AmenitiesSection";
import HomePageToolbar from "./HomePageToolbar";
import HomeConfigSection from "./sections/HomeConfigSection";
import LoadingSection from "../base/LoadingSection";
import { useAmenityStore, useDocumentTitle, useHomeStore } from "../hooks";
import HomeRulesSection from "./sections/HomeRulesSection";

function HomePageContent() {
  return (
    <div>
      <HomePageToolbar />

      <AddressSection />
      <HomeConfigSection />
      <AmenitiesSection />
      <CheckInSection />
      <HomeRulesSection />

      <div style={{ height: "3em" }} />
    </div>
  );
}

function HomePageLoadingContent() {
  return (
    <div>
      <HomePageToolbar />

      <LoadingSection header={"Address"} />
      <LoadingSection header={"Beds and Rooms"} />
      <LoadingSection header={"Amenities"} />
      <LoadingSection header={"Check-In Instructions"} />
      <LoadingSection header={"Home Rules"} />

      <div style={{ height: "3em" }} />
    </div>
  );
}

export default function HomePage() {
  const homeStore = useHomeStore();
  const amenityStore = useAmenityStore();

  useDocumentTitle("My Home");

  useEffect(() => {
    homeStore.load();
    amenityStore.loadAmenityList();
    amenityStore.loadHomeAmenityList();
  }, [homeStore, amenityStore]);

  return useObserver(() => {
    const { home } = homeStore;
    return Boolean(home) ? <HomePageContent /> : <HomePageLoadingContent />;
  });
}
