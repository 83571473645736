import React, { useContext } from "react";
import {
  IConversation,
  IConversationPartner,
  IMessage,
} from "../../storeroom/conversations/models";
import { ConversationContext } from "./contexts";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { humanizeTimestamp } from "../../formattingService";
import { useProfileStore } from "../hooks";

interface MessageProps {
  message: IMessage;
  partner: IConversationPartner;
  userPhotoURL: string;
}

export default function MessageList() {
  const conversation: IConversation = useContext(ConversationContext);
  const userPhotoURL: string = useProfileStore().coverPhotoURL;

  return (
    <List>
      {conversation.messages.map((message: IMessage) => (
        <MessageItem
          message={message}
          partner={conversation.partner}
          userPhotoURL={userPhotoURL}
          key={message.id}
        />
      ))}
    </List>
  );
}

function MessagePicture({ url }: { url: string }) {
  return <img src={url} height={160} alt={"message"} />;
}

function MessageItem({ message, partner, userPhotoURL }: MessageProps) {
  const authoredByPartner = message.author === partner.id;

  return (
    <ListItem>
      {authoredByPartner && (
        <ListItemAvatar>
          <Avatar
            src={
              message.author === partner.id ? partner.picture_url : userPhotoURL
            }
          />
        </ListItemAvatar>
      )}

      <ListItemText
        style={{ textAlign: authoredByPartner ? "left" : "right" }}
        primary={
          message.picture ? (
            <MessagePicture url={message.picture} />
          ) : (
            message.message
          )
        }
        secondary={humanizeTimestamp(message.timestamp)}
      />
    </ListItem>
  );
}
