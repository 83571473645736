import React, { useState } from "react";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import HomeConfigModal from "../forms/HomeConfigModal";
import { useHomeStore } from "../../hooks";

export default function HomeConfigSection() {
  const [showEditForm, setShowEditForm] = useState(false);
  const homeStore = useHomeStore();
  const home = homeStore.home;

  const sectionProps: SectionProps = {
    header: "Home Configuration",
    actionIcon: "edit",
    actionLabel: "Edit configuration",
    onActionClick: () => setShowEditForm(true),
  };

  return useObserver(() => (
    <Section {...sectionProps}>
      <HomeConfigModal
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />

      <div>
        <Typography style={sectionBodyStyle}>
          {homeStore.getHomeTypeDisplayName(home.home_type)}
        </Typography>
      </div>
      <div>
        <Typography style={sectionBodyStyle}>{home.beds} beds</Typography>
      </div>
      <div>
        <Typography style={sectionBodyStyle}>
          {home.bedrooms} bedrooms
        </Typography>
      </div>
      <div>
        <Typography style={sectionBodyStyle}>
          {home.bathrooms} bathrooms
        </Typography>
      </div>
    </Section>
  ));
}
