import React from "react";
import { AuthStore } from "./auth/AuthStore";
import { HomeStore } from "./homes/HomeStore";
import { ListingStore } from "./homes/ListingStore";
import { OwnerStore } from "./users/OwnerStore";
import { PetStore } from "./pets/PetStore";
import { ProfileStore } from "./users/ProfileStore";
import { ReservationStore } from "./reservations/ReservationStore";
import { SitterStore } from "./users/SitterStore";
import { TripStoreOld } from "./trips/TripStoreOld";
import { VacancySearchStore } from "./homes/searchService/VacancySearchStore";
import { VacancyStore } from "./homes/VacancyStore";
import { AmenityStore } from "./amenities/AmenityStore";
import { SitterSearchManager } from "./sitters/SitterSearchManager";
import ConversationListStore from "./conversations/ConversationListStore";
import { ConversationStore } from "./conversations/ConversationStore";
import RegistrationStore from "./users/registration/RegistrationStore";

export interface IAuthStoreContext {
  authStore: AuthStore;
  registrationStore: RegistrationStore;
}

export interface IConversationStoreContext {
  conversationStore: ConversationStore;
}

export interface IStoreContext {
  amenityStore: AmenityStore;
  homeStore: HomeStore;
  listingStore: ListingStore;
  ownerStore: OwnerStore;
  petStore: PetStore;
  profileStore: ProfileStore;
  reservationStore: ReservationStore;
  sitterStore: SitterStore;
  tripStore: TripStoreOld;
  vacancySearchStore: VacancySearchStore;
  vacancyStore: VacancyStore;
  sitterSearchManager: SitterSearchManager;
  conversationListStore: ConversationListStore;
}

const StoreContext = React.createContext<IStoreContext>(null);
const StoreProvider = StoreContext.Provider;

const AuthStoreContext = React.createContext<IAuthStoreContext>(null);
const AuthStoreProvider = AuthStoreContext.Provider;

const ConversationStoreContext = React.createContext<IConversationStoreContext>(
  null
);
const ConversationStoreProvider = ConversationStoreContext.Provider;

export {
  StoreContext,
  StoreProvider,
  AuthStoreContext,
  AuthStoreProvider,
  ConversationStoreContext,
  ConversationStoreProvider,
};
