import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react";
import PageToolbar from "../base/PageToolbar";
import { ICrumbProps } from "../base/Crumbs";
import { IPageToolbarAction } from "../base/PageToolbarActions";
import { useHistory } from "react-router-dom";
import CreateTripModal from "./modals/CreateTripModal";
import TripListStore from "../../storeroom/trips/TripListStore";
import { Grid } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { humanizeDateRange } from "../../formattingService";
import CardActionArea from "@material-ui/core/CardActionArea";
import { IListTrip } from "../../storeroom/trips/models";
import { privateRoutePaths } from "../../routes/PrivateRoutes";
import Icon from "@material-ui/core/Icon";
import { Skeleton } from "@material-ui/lab";
import { useDocumentTitle, useTripStore } from "../hooks";

export default function TripListPage() {
  const history = useHistory();

  const [store, setStore] = useState<TripListStore>(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useDocumentTitle("My Trips");

  useEffect(() => {
    setStore(new TripListStore());
  }, []);

  const crumbs: ICrumbProps = {
    currentLabel: "My Trips",
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "Add Trip",
      icon: "add",
      forceIcon: true,
      onClick: () => setShowAddForm(true),
    },
  ];

  const onTabChange = (event: any, newTab: number) => {
    setActiveTab(newTab);
  };

  const afterCreateCancel = () => {
    setShowAddForm(false);
  };

  const afterCreate = (tripID: number) => {
    setShowAddForm(false);
    history.push(`/trips/${tripID}`);
  };

  return useObserver(() => {
    return (
      <div>
        <CreateTripModal
          show={showAddForm}
          afterCreate={afterCreate}
          afterCancel={afterCreateCancel}
        />
        <PageToolbar crumbs={crumbs} actions={actions} secondary={true} />

        <Container maxWidth={"md"}>
          <Tabs
            value={activeTab}
            onChange={onTabChange}
            indicatorColor={"secondary"}
            textColor={"secondary"}
            variant={"fullWidth"}
          >
            <Tab label={"Future"} icon={<Icon>event</Icon>} />
            <Tab label={"Completed"} icon={<Icon>history</Icon>} />
          </Tabs>

          <div style={{ height: "2rem" }} />
          {store && <TripListContent store={store} activeTab={activeTab} />}
        </Container>
      </div>
    );
  });
}

interface IContentProps {
  store: TripListStore;
  activeTab: number;
}

function TripListContent(props: IContentProps) {
  const history = useHistory();

  const tripList =
    props.activeTab === 0 ? props.store.futureTrips : props.store.pastTrips;

  const onTripClick = (trip: IListTrip) => {
    history.push(privateRoutePaths.tripPage(trip.id));
  };

  return useObserver(() => {
    const store = props.store;

    return store.loading ? (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Skeleton variant={"rect"} height={250} animation={"wave"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Skeleton variant={"rect"} height={250} animation={"wave"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Skeleton variant={"rect"} height={250} animation={"wave"} />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2}>
        {tripList.map((trip) => (
          <Grid item xs={12} sm={6} md={4} key={trip.id}>
            <Card>
              <CardActionArea onClick={() => onTripClick(trip)}>
                <CardContent>
                  <Typography
                    variant={"body1"}
                    style={{ fontWeight: 700, whiteSpace: "nowrap" }}
                  >
                    {trip.destination}
                  </Typography>

                  <Typography variant={"body1"}>
                    {humanizeDateRange(trip.start_date, trip.end_date)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CardImage(props: { trip: IListTrip }) {
  const { trip } = props;
  const defaultURL = trip.picture?.url;

  const tripStore = useTripStore();
  const [image, setImage] = useState(defaultURL);

  useEffect(() => {
    if (!Boolean(defaultURL)) {
      tripStore
        .getGooglePicture(trip.id)
        .then((response: any) => setImage(URL.createObjectURL(response.data)))
        .catch(() => setImage(null));
    }
  }, [trip.id, defaultURL, tripStore]);

  return image === undefined ? (
    <Skeleton variant={"rect"} height={240} animation={"wave"} />
  ) : (
    <CardMedia component={"img"} image={image} height={"240"} />
  );
}
