import React, { FormEvent, useState } from "react";
import {
  useAuthStore,
  useRegistrationStore,
  useTabletOrLarger,
} from "../../hooks";
import { useHistory } from "react-router-dom";
import { TextFieldChangeEvent } from "../../models";
import { publicRoutePaths } from "../../../routes/PublicRoutes";
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import styles from "../../auth/auth.module.sass";
import logo from "../../../jetsyt-logo-orange.png";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  ButtonGroup,
  createStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
    },
    loginPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
    },
    accentPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
      backgroundColor: theme.palette.secondary.main,
      textAlign: "center",
    },
    welcomeBackText: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
      textAlign: "center",
      fontSize: 64,
      color: "#15113b",
      lineHeight: "68px",
    },
    accentText: {
      fontFamily: "Playfair Display",
      fontWeight: 700,
    },
  })
);

export default function RegistrationPage() {
  const authStore = useAuthStore();
  const registrationStore = useRegistrationStore();
  const history = useHistory();

  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<Array<string>>(null);

  const isWide = useTabletOrLarger();
  const classes = useStyles();

  const onError = (errors: any) => {
    const messages: Array<string> = Boolean(errors)
      ? errors.response.data
      : null;
    setPending(false);
    setError(messages);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!pending) {
      setPending(true);
      setError(null);
      registrationStore
        .submitRegistration()
        .then((credentials) => {
          authStore.authenticate(credentials, () => onError(null));
        })
        .catch(onError);
    }
  };

  const onEmailChange = (event: TextFieldChangeEvent) => {
    registrationStore.setEmail(event.target.value);
  };

  const onPhoneChange = (event: TextFieldChangeEvent) => {
    registrationStore.setPhone(event.target.value);
  };

  const onPasswordChange = (event: TextFieldChangeEvent) => {
    registrationStore.setPassword(event.target.value);
  };

  const onFirstNameChange = (event: TextFieldChangeEvent) => {
    registrationStore.setFirstName(event.target.value);
  };

  const onLastNameChange = (event: TextFieldChangeEvent) => {
    registrationStore.setLastName(event.target.value);
  };

  const onLogoClick = () => {
    history.push(publicRoutePaths.front());
  };

  const errorAlert = error ? (
    <Alert severity={"error"} style={{ width: "calc(100% - 2em)" }}>
      {error.map((message, index) => {
        return (
          <Typography variant={"body2"} key={index}>
            {message}
          </Typography>
        );
      })}
    </Alert>
  ) : null;

  const codeOfConductLink = (
    <a
      href={"https://sites.google.com/jetsyt.com/help-center/code-of-conduct"}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      Code of Conduct
    </a>
  );

  const privacyPolicyLink = (
    <a
      href={"https://sites.google.com/jetsyt.com/help-center/privacy-policy"}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      Privacy Policy
    </a>
  );

  const termsOfServiceLink = (
    <a
      href={"https://sites.google.com/jetsyt.com/help-center/terms-of-service"}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      Terms of Service
    </a>
  );

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} md={6} className={classes.loginPanel}>
        <form
          className={styles.loginForm}
          autoComplete={"off"}
          onSubmit={onSubmit}
        >
          <img
            src={logo}
            alt={"logo"}
            onClick={onLogoClick}
            style={{ cursor: "pointer" }}
          />

          <Typography variant={"h3"} className={classes.welcomeBackText}>
            Welcome!
          </Typography>

          <div style={{ flexGrow: 1 }} />

          {errorAlert}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={"Email"}
                fullWidth
                color={"primary"}
                onChange={onEmailChange}
                disabled={pending}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={"Phone"}
                fullWidth
                color={"primary"}
                onChange={onPhoneChange}
                disabled={pending}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={"Password"}
                type={"password"}
                fullWidth
                color={"primary"}
                onChange={onPasswordChange}
                disabled={pending}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={"First Name"}
                fullWidth
                color={"primary"}
                onChange={onFirstNameChange}
                disabled={pending}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={"Last Name"}
                fullWidth
                color={"primary"}
                onChange={onLastNameChange}
                disabled={pending}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant={"caption"}>
                By clicking Sign Up, I agree to abide by Jetsyt, LLC's{" "}
                {codeOfConductLink}, {termsOfServiceLink}, and agree to Jetsyt
                LLC's {privacyPolicyLink}.
              </Typography>
            </Grid>
          </Grid>

          <ButtonGroup fullWidth>
            <Button
              type={"submit"}
              color={"primary"}
              variant={"contained"}
              endIcon={<Icon>arrow_right_alt</Icon>}
              disabled={pending}
            >
              {pending ? (
                <CircularProgress variant={"indeterminate"} size={12} />
              ) : (
                "Sign Up"
              )}
            </Button>
          </ButtonGroup>
        </form>
      </Grid>

      {isWide && (
        <Grid item xs={6} className={classes.accentPanel}>
          <Typography variant={"h3"} className={classes.accentText}>
            Join the community. Become a member!
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
