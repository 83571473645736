import React, { useState } from "react";
import { IVacancySearchResultDetails } from "../../../storeroom/homes/searchService/models";
import { humanizeDateRange } from "../../../formattingService";
import { useURLParams } from "../../../hooks";
import { useHistory } from "react-router-dom";
import RequestReservationFormDialog from "../RequestReservationFormDialog";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import { ICrumbProps } from "../../base/Crumbs";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar, { PageToolbarProps } from "../../base/PageToolbar";
import Modal from "../../misc/Modal";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useBillingStore, useDocumentTitle } from "../../hooks";
import { useObserver } from "mobx-react";

interface Props {
  vacancy: IVacancySearchResultDetails;
}

interface IUserBlockedModalProps {
  show: boolean;
  onClose: () => void;
}

const UserBlockedModal = (props: IUserBlockedModalProps) => {
  const history = useHistory();

  return (
    <Modal
      show={props.show}
      maxWidth={"sm"}
      secondary={true}
      headerChildren={"Missing Requirements"}
      contentChildren={
        <React.Fragment>
          <Typography variant={"body1"}>
            You must pass a background check before you can submit a request
          </Typography>
        </React.Fragment>
      }
      footerChildren={
        <React.Fragment>
          <Button onClick={props.onClose}>Close</Button>
          <Button
            onClick={() => history.push("/profile")}
            color={"secondary"}
            variant={"contained"}
            size={"small"}
          >
            Go to Billing
          </Button>
        </React.Fragment>
      }
    />
  );
};

export default function VacancySearchResultPageToolbar(props: Props) {
  const { vacancy } = props;
  const history = useHistory();
  const urlParams = useURLParams();
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [showBlockedForm, setShowBlockedForm] = useState(false);
  const billingStore = useBillingStore();

  useDocumentTitle("Find Home Details");

  const onRequestFormClose = (id: number | null | undefined) => {
    setShowRequestForm(false);
    if (Boolean(id)) {
      history.push(`/reservations/${id}`);
    }
  };

  const viewRequest = () => {
    history.push(
      privateRoutePaths.reservationDetails(vacancy.current_request.id),
      {
        tripID: vacancy.current_request.trip_id,
        tripLabel: humanizeDateRange(vacancy.start_date, vacancy.end_date),
      }
    );
  };

  const crumbs: ICrumbProps = {
    currentLabel: getPageTitle(urlParams, props.vacancy),
    links: [
      {
        label: "Find Homes",
        url: `/search_vacancies/?${getExploreURLParams(urlParams)}`,
      },
    ],
  };

  return useObserver(() => {
    const billingProfile = billingStore.billingProfile;

    const onRequest = () => {
      if (billingProfile.background_check_fee_paid) {
        setShowRequestForm(true);
      } else {
        setShowBlockedForm(true);
      }
    };

    const actions: Array<IPageToolbarAction> = vacancy.current_request
      ? [
          {
            label: "View Request",
            icon: "wysiwyg",
            forceIcon: true,
            onClick: viewRequest,
          },
        ]
      : [
          {
            label: "Send Request",
            icon: "add",
            forceIcon: true,
            onClick: onRequest,
          },
        ];

    const pageToolbarProps: PageToolbarProps = {
      crumbs: crumbs,
      actions: actions,
      secondary: true,
    };

    return (
      <PageToolbar {...pageToolbarProps}>
        <RequestReservationFormDialog
          vacancyID={props.vacancy.id}
          show={showRequestForm}
          onClose={onRequestFormClose}
        />

        <UserBlockedModal
          show={showBlockedForm}
          onClose={() => setShowBlockedForm(false)}
        />
      </PageToolbar>
    );
  });
}

export function VacancySearchResultPageLoadingToolbar() {
  const crumbs: ICrumbProps = { currentLabel: "Loading..." };
  return <PageToolbar crumbs={crumbs} actions={[]} secondary={true} />;
}

const getPageTitle = (
  urlParams: URLSearchParams,
  vacancy: IVacancySearchResultDetails
) => {
  return urlParams.has("start") && urlParams.has("end")
    ? humanizeDateRange(urlParams.get("start"), urlParams.get("end"))
    : humanizeDateRange(vacancy.start_date, vacancy.end_date);
};

const getExploreURLParams = (urlParams: URLSearchParams) => {
  const params: Array<string> = [];

  if (urlParams.has("destination")) {
    params.push(`destination=${urlParams.get("destination")}`);
  }

  if (urlParams.has("start")) {
    params.push(`startdate=${urlParams.get("start")}`);
  }

  if (urlParams.has("end")) {
    params.push(`enddate=${urlParams.get("end")}`);
  }

  return params.join("&");
};
