import { action, observable } from "mobx";
import IBasePetBuilderStepStore from "./IBasePetBuilderStepStore";
import { IGalleryItem } from "../../../albums/models";

export default class PetAlbumStepStore implements IBasePetBuilderStepStore {
  @observable pictures: Array<File> = [];
  @observable previews: Array<IGalleryItem> = [];
  @observable canSubmit: boolean = false;

  @action addFile = (file: File) => {
    this.pictures.push(file);

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      this.previews.push({
        src: img.src,
        width: img.width,
        height: img.height,
        filename: file.name,
      });
    };

    this.postUpdate();
  };

  remove = (index: number) => {
    const nameToRemove = this.previews[index].filename;
    this.previews = this.previews.filter(
      (item) => item.filename !== nameToRemove
    );
    this.pictures = this.pictures.filter(
      (picture) => picture.name !== nameToRemove
    );
    this.postUpdate();
  };

  submit = (): Promise<null> => Promise.resolve(null);

  private postUpdate = () => {
    this.canSubmit = this.pictures.length > 0;
  };
}
