import { observable } from "mobx";
import { IHomeReview } from "../models";
import { deepCopy } from "../../../utils";
import HomeReviewsHttpClient from "./HomeReviewsHttpClient";

export default class HomeReviewStore {
  private _homeReview: IHomeReview;

  @observable loading = false;
  @observable homeReview: IHomeReview;
  @observable dirty = false;
  @observable canSubmit = false;

  constructor(id?: number, reservationID?: number) {
    if (Boolean(id)) {
      this.load(id);
    } else {
      this.initializeNew(reservationID);
    }
  }

  private load = (id: number) => {
    this.loading = true;
    HomeReviewsHttpClient.retrieve(id)
      .then(this.postLoad)
      .catch(this.onLoadError);
  };

  private postLoad = (review: IHomeReview) => {
    this._homeReview = deepCopy(review);
    this.homeReview = deepCopy(review);
    this.loading = false;
    this.dirty = false;
  };

  private onLoadError = (error: any) => {
    this.homeReview = null;
    this.loading = false;
  };

  private initializeNew = (reservationID: number) => {
    this.postLoad({
      id: null,
      reservation: reservationID,
      reviewer: null,
      rating: null,
      summary: "",
      communication: null,
      home_accuracy: null,
      pet_accuracy: null,
    });
  };

  reset = () => {
    this.homeReview = deepCopy(this._homeReview);
    this.dirty = false;
  };

  setRating = (rating: 1 | 2 | 3 | 4 | 5) => {
    this.homeReview.rating = rating;
    this.postUpdate();
  };

  setSummary = (summary: string) => {
    this.homeReview.summary = summary;
    this.postUpdate();
  };

  setCommunication = (communication: boolean) => {
    this.homeReview.communication = communication;
    this.postUpdate();
  };

  setHomeAccuracy = (accuracy: boolean) => {
    this.homeReview.home_accuracy = accuracy;
    this.postUpdate();
  };

  setPetAccuracy = (accuracy: boolean) => {
    this.homeReview.pet_accuracy = accuracy;
    this.postUpdate();
  };

  postUpdate = () => {
    this.dirty = true;

    this.canSubmit =
      Boolean(this.homeReview.rating) &&
      this.homeReview.home_accuracy !== null &&
      this.homeReview.pet_accuracy !== null &&
      this.homeReview.communication !== null;
  };

  save = (): Promise<void> => {
    if (this.homeReview.id === null) {
      return HomeReviewsHttpClient.create(this.homeReview).then(this.postLoad);
    } else {
      return HomeReviewsHttpClient.update(this.homeReview).then(this.postLoad);
    }
  };
}
