import React, { useContext } from "react";
import Gallery from "react-photo-gallery";
import { AlbumStoreContext } from "../../../../storeroom/albums/AlbumStore";
import { useObserver } from "mobx-react";
import { Skeleton } from "@material-ui/lab";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Typography } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import AlbumModalEditableImage, {
  IEditableImageProps,
} from "./AlbumModalEditableImage";
import AlbumModalDropzone from "./AlbumModalDropzone";

const SortableImage = SortableElement((item: IEditableImageProps) => (
  <AlbumModalEditableImage {...item} />
));

const SortableGallery = SortableContainer((props: any) => (
  <Gallery
    photos={props.items}
    renderImage={(props: IEditableImageProps) => <SortableImage {...props} />}
  />
));

export default function AlbumModalEditTab() {
  const store = useContext(AlbumStoreContext);

  const maxPictureCount = store.getMaxPictureCount();

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      store.changeSequence(oldIndex, newIndex);
    }
  };

  return useObserver(() => {
    return (
      <div>
        <div style={{ height: "2rem" }} />

        <Typography variant={"body1"}>Edit Pictures</Typography>

        <DialogContentText>
          Drag 'n' Drop your pictures to change the sequence they are shown. The
          first picture will be used as the cover photo.
        </DialogContentText>

        <DialogContentText>Max {maxPictureCount} pictures</DialogContentText>

        <div style={{ height: "1rem" }} />

        {!store.loading && <AlbumModalDropzone />}

        <div style={{ height: "2rem" }} />

        {store.loading ? (
          <Skeleton variant={"rect"} height={250} animation={"wave"} />
        ) : (
          <SortableGallery
            items={[...store.galleryItems]}
            onSortEnd={onSortEnd}
            axis={"xy"}
          />
        )}
      </div>
    );
  });
}
