import { observable } from "mobx";
import { ITrip } from "./models";
import TripsHttpClient from "./TripsHttpClient";
import moment from "moment";
import { INonFieldError } from "../models";

export default class AddTripStore {
  @observable pending = false;
  @observable trip: ITrip;
  @observable error: string = "";

  constructor() {
    const today = moment().add(1, "days");
    const end = moment().add(3, "days");

    this.trip = {
      id: null,
      location: "",
      start_date: today.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      sitter: null,
    };
  }

  setDestination = (destination: string) => {
    this.trip.location = destination;
  };

  setStartDate = (startDate: string) => {
    this.trip.start_date = startDate;
  };

  setEndDate = (endDate: string) => {
    this.trip.end_date = endDate;
  };

  submit = (): Promise<number> => {
    this.error = "";
    this.pending = true;
    return TripsHttpClient.create(this.trip)
      .then(this._onSuccess)
      .catch(this._onFail);
  };

  private _onSuccess = (trip: ITrip) => {
    this.pending = false;
    return trip.id;
  };

  private _onFail = (error: INonFieldError) => {
    this.pending = false;
    this.error = error.response.data.non_field_errors.join(" ");
    throw error;
  };
}
