import React, { useEffect } from "react";
import { useDocumentTitle, useHomeStore, usePetStore } from "../hooks";
import { useObserver } from "mobx-react";
import { PetSummarySection } from "./sections/PetSummarySection";
import { IPet } from "../../storeroom/pets/models";
import LoadingSection from "../base/LoadingSection";
import PetListToolbar from "./toolbars/PetListToolbar";
import PetCareSection from "../homes/sections/PetCareSection";

export default function PetListPage() {
  const petStore = usePetStore();
  const homeStore = useHomeStore();

  useDocumentTitle("My Pets");

  useEffect(() => {
    petStore.loadPetList();
  }, [petStore]);

  return useObserver(() => {
    const home = homeStore.home;
    const routineSection = Boolean(home) ? (
      <PetCareSection />
    ) : (
      <LoadingSection header={"Pet Care Routine"} />
    );

    return (
      <div>
        <PetListToolbar />
        {routineSection}

        {petStore.petList === undefined ? (
          <LoadingSection header={"My Pets"} />
        ) : (
          petStore.petList.map((pet: IPet) => (
            <PetSummarySection pet={pet} key={pet.id} />
          ))
        )}
      </div>
    );
  });
}
