import { HttpClient } from "../HttpClient";
import { IBillingProfile } from "./models";

export default class StripeHttpClient {
  static createWithDefaultPayment = (
    paymentMethodID: string
  ): Promise<IBillingProfile> => {
    return HttpClient.post<IBillingProfile>(
      "billing/stripe/create_with_default_payment/",
      { payment_method: paymentMethodID }
    );
  };

  static addStripePayment = (
    paymentMethodID: string
  ): Promise<IBillingProfile> => {
    return HttpClient.post("billing/add_stripe_payment/", {
      payment_method: paymentMethodID,
    });
  };
}
