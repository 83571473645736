import React from "react";
import { observer } from "mobx-react";
import { StoreContext } from "../../../storeroom/StoreContext";
import SitterSearchToolbar from "../toolbar/SitterSearchToolbar";
import SearchResultsHeader from "./SearchResultsHeader";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import SitterSearchResultsSection from "../sections/SitterSearchResultsSection";

interface SitterSearchState {
  startDate: string;
  endDate: string;
}

interface Props extends RouteComponentProps<any, any, SitterSearchState> {}

@observer
class SitterSearchPage extends React.Component<Props, any> {
  static contextType = StoreContext;

  componentDidMount(): void {
    this.context.ownerStore.preferencesManager.loadPreferences();

    if (this.props.location.state !== undefined) {
      const state = this.props.location.state;
      const startDate = state.startDate;
      const endDate = state.endDate;

      if (startDate !== null && endDate != null) {
        const searchManager = this.context.sitterSearchManager;
        searchManager.setStartDate(startDate);
        searchManager.setEndDate(endDate);
        searchManager.search().then();
      }
    }
  }

  render() {
    return (
      <div>
        <SitterSearchToolbar />
        <SearchResultsHeader />
        <SitterSearchResultsSection />
      </div>
    );
  }
}

export default withRouter(SitterSearchPage);
