import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useObserver } from "mobx-react";
import TripPageToolbar, {
  TripPageLoadingToolbar,
} from "./toolbars/TripPageToolbar";
import CurrentRequestsSection from "./sections/CurrentRequestsSection";
import PastRequestsSection from "./sections/PastRequestsSection";
import LoadingSection from "../base/LoadingSection";
import AcceptedReservationSection from "./sections/AcceptedReservationSection";
import { useTripStore, useVacancySearchStore } from "../hooks";
import TripDetailsSection from "./sections/TripDetailsSection";

export default function TripPage() {
  const { id } = useParams();
  const tripStore = useTripStore();
  const searchStore = useVacancySearchStore();

  useEffect(() => {
    tripStore.loadTrip(id);
    searchStore.load();
  }, [id, tripStore, searchStore]);

  return useObserver(() => {
    return Boolean(tripStore.trip) && Boolean(searchStore.searchParams) ? (
      <div>
        <TripPageToolbar />
        <TripDetailsSection />
        <AcceptedReservationSection />
        <CurrentRequestsSection />
        <PastRequestsSection />
      </div>
    ) : (
      <div>
        <TripPageLoadingToolbar />
        <LoadingSection header={"Details"} />
        <LoadingSection header={"Accepted Reservation"} />
        <LoadingSection header={"Current Requests"} cards={true} />
        <LoadingSection header={"Past Requests"} cards={true} />
      </div>
    );
  });
}
