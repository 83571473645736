import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useHistory } from "react-router-dom";
import { humanizeDateRange } from "../../../formattingService";
import { ICrumbProps } from "../../base/Crumbs";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar from "../../base/PageToolbar";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import TripCancelModal from "../modals/TripCancelModal";
import { useDocumentTitle } from "../../hooks";

export default function TripPageToolbar() {
  const history = useHistory();
  const store = useContext(StoreContext);
  const trip = store.tripStore.trip;

  const [showCancelModal, setShowCancelModal] = useState(false);

  const dateRange = humanizeDateRange(trip.start_date, trip.end_date);
  useDocumentTitle(`My Trip: ${dateRange}`);

  const onDelete = () => {
    setShowCancelModal(true);
  };

  const onCancelModalClose = (tripCanceled: boolean) => {
    setShowCancelModal(false);
    if (tripCanceled) {
      history.replace("/trips");
    }
  };

  const findHomes = () => {
    const searchParams = store.vacancySearchStore.searchParams;

    searchParams.start_date = trip.start_date;
    searchParams.end_date = trip.end_date;
    searchParams.destination = trip.location;

    history.push(
      privateRoutePaths.searchVacancies(
        trip.location,
        trip.start_date,
        trip.end_date
      )
    );
  };

  const crumbProps: ICrumbProps = {
    currentLabel: dateRange,
    links: [
      {
        label: "My Trips",
        url: "/trips",
      },
    ],
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "Cancel",
      icon: "delete",
      forceIcon: true,
      onClick: onDelete,
    },
    {
      label: "Find Homes",
      icon: "search",
      onClick: findHomes,
    },
  ];

  return (
    <PageToolbar crumbs={crumbProps} actions={actions} secondary={true}>
      <TripCancelModal
        show={showCancelModal}
        onClose={onCancelModalClose}
        trip={trip}
      />
    </PageToolbar>
  );
}

export function TripPageLoadingToolbar() {
  const crumbs: ICrumbProps = {
    currentLabel: "Loading...",
    links: [
      {
        label: "My Trips",
        url: "/trips",
      },
    ],
  };

  return <PageToolbar crumbs={crumbs} actions={[]} secondary={true} />;
}
