import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../storeroom/StoreContext";
import { Section, sectionBodyStyle } from "../../../base/Section";
import { Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import ListingTitleForm from "../forms/ListingTitleForm";

export default function TitleSection() {
  const store = useContext(StoreContext);

  const [showEditForm, setShowEditForm] = useState(false);

  return useObserver(() => (
    <Section
      header={"Title"}
      actionIcon={"edit"}
      actionLabel={"Edit Title"}
      onActionClick={() => setShowEditForm(true)}
    >
      <ListingTitleForm
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <Typography variant={"body1"} style={sectionBodyStyle}>
        {store.listingStore.listing.title}
      </Typography>
    </Section>
  ));
}
