import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { IHome } from "../../../storeroom/homes/models";
import { Alert } from "@material-ui/lab";
import { useHomeStore, useMobile } from "../../hooks";
import Grid from "@material-ui/core/Grid";
import { states } from "../../../storeroom/models";
import Autocomplete from "@material-ui/lab/Autocomplete";

export interface IHomeFormModalProps {
  show: boolean;
  onClose: () => void;
}

export default function HomeFormModal(props: IHomeFormModalProps) {
  const homeStore = useHomeStore();
  const [home, setHome] = useState<IHome>(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const isMobile = useMobile();

  useEffect(() => {
    if (props.show) {
      setHome(JSON.parse(JSON.stringify(homeStore.home)));
    }
  }, [props.show, homeStore]);

  const onError = () => {
    setError("Update failed");
    setSubmitted(false);
  };

  const onSubmit = () => {
    if (isValid()) {
      setSubmitted(true);
      home.location = `${home.address1} ${home.address2}, ${home.city}, ${home.state}  ${home.zip}`;
      homeStore.save(home).then(finish).catch(onError);
    }
  };

  const isValid = (): boolean => {
    const errors = [];

    if (!Boolean(home.address1)) {
      errors.push("Address 1 is required");
    }

    if (!Boolean(home.city)) {
      errors.push("City is required");
    }

    if (!Boolean(home.state)) {
      errors.push("State is required");
    }

    if (!Boolean(home.zip)) {
      errors.push("Zip is required");
    }

    if (errors.length === 0) {
      return true;
    } else {
      setError(errors.join(", "));
      return false;
    }
  };

  const finish = () => {
    setError("");
    setSubmitted(false);
    props.onClose();
  };

  const onAddress1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    home.address1 = event.target.value;
  };

  const onAddress2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    home.address2 = event.target.value;
  };

  const onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    home.city = event.target.value;
  };

  const onStateChange = (event: any, newValue: string | null) => {
    home.state = newValue;
  };

  const onZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    home.zip = event.target.value;
  };

  const stateSelectProps = {
    defaultValue: home?.state ? home.state : null,
    options: states,
    onChange: onStateChange,
    fullWidth: true,
    renderInput: (params: any) => <TextField {...params} label={"State"} />,
  };

  return (
    <Dialog open={props.show} fullWidth maxWidth={"sm"}>
      <DialogTitle>Home Address</DialogTitle>

      <DialogContent>
        {Boolean(error) && (
          <div>
            <Alert severity={"error"}>{error}</Alert>
            <div style={{ height: "1rem" }} />
          </div>
        )}

        {Boolean(home) ? (
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 9 : 8}>
              <TextField
                label={"Address 1"}
                fullWidth
                defaultValue={home.address1}
                onChange={onAddress1Change}
              />
            </Grid>

            <Grid item xs={isMobile ? 3 : 4}>
              <TextField
                label={"Apt/Unit"}
                fullWidth
                defaultValue={home.address2}
                onChange={onAddress2Change}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={"City"}
                fullWidth
                defaultValue={home.city}
                onChange={onCityChange}
              />
            </Grid>

            <Grid item xs={isMobile ? 2 : 3}>
              <Autocomplete {...stateSelectProps} />
            </Grid>

            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label={"Zip"}
                fullWidth
                defaultValue={home.zip}
                onChange={onZipChange}
              />
            </Grid>
          </Grid>
        ) : (
          <div style={{ height: "2rem" }} />
        )}

        <div style={{ height: "1rem" }} />
      </DialogContent>

      <DialogActions style={{ padding: "1rem" }}>
        <Button disabled={submitted} onClick={finish}>
          Cancel
        </Button>

        <Button
          type={"submit"}
          variant={"contained"}
          color={"primary"}
          disabled={submitted}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
