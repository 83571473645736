import React, { useEffect, useState } from "react";
import { useTripStore } from "../../hooks";
import { ITrip } from "../../../storeroom/trips/models";
import { Section, SectionProps } from "../../base/Section";
import TripStore from "../../../storeroom/trips/TripStore";
import TripEditModal from "../modals/TripEditModal";
import { useObserver } from "mobx-react";
import { Typography } from "@material-ui/core";
import { humanizeDateRange } from "../../../formattingService";

const getSectionProps = (trip: ITrip, onClick: () => void) => {
  let sectionProps: SectionProps = {
    header: "Details",
    secondaryColor: true,
  };

  const hasActiveReservations =
    Boolean(trip.accepted_reservation) || trip.reservations?.length > 0;

  if (!hasActiveReservations) {
    sectionProps = {
      ...sectionProps,
      actionIcon: "edit",
      actionLabel: "Edit Details",
      onActionClick: onClick,
    };
  }

  return sectionProps;
};

export default function TripDetailsSection() {
  const tripStore = useTripStore();
  const trip = tripStore.trip;

  const [store, setStore] = useState<TripStore>(null);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    if (showEditForm) {
      setStore(new TripStore(trip));
    }
  }, [showEditForm, trip]);

  const onModalClose = (tripID: number) => {
    if (Boolean(tripID)) {
      tripStore.loadTrip(tripID);
    }
    setShowEditForm(false);
  };

  const sectionProps = getSectionProps(trip, () => setShowEditForm(true));

  return useObserver(() => {
    return (
      <Section {...sectionProps}>
        {store && (
          <TripEditModal
            store={store}
            show={showEditForm}
            onClose={onModalClose}
          />
        )}

        <Typography variant={"body1"}>{trip.location}</Typography>

        <Typography variant={"body1"}>
          {humanizeDateRange(trip.start_date, trip.end_date)}
        </Typography>
      </Section>
    );
  });
}
