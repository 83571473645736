import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import BasePage from "../showroom/base/BasePage";
import HomePage from "../showroom/homes/HomePage";
import { useObserver } from "mobx-react";
import { IRoute } from "./AppRouter";
import { CircularProgress } from "@material-ui/core";
import PetPage from "../showroom/pets/PetPage";
import MyListingPage from "../showroom/listings/myListing/MyListingPage";
import VacancySearchPage from "../showroom/vacancies/search/VacancySearchPage";
import SitterPage from "../showroom/sitters/SitterPage";
import TripPage from "../showroom/trips/TripPage";
import VacancyPage from "../showroom/vacancies/VacancyPage";
import ReservationPage from "../showroom/reservations/ReservationPage";
import ConversationListPage from "../showroom/conversations/ConversationListPage";
import ConversationPage from "../showroom/conversations/ConversationPage";
import MyProfilePage from "../showroom/users/myProfile/MyProfilePage";
import VacancySearchResultPage from "../showroom/vacancies/resultDetails/VacancySearchResultPage";
import SitterSearchPage from "../showroom/sitters/searchPage/SitterSearchPage";
import SitterSearchResultDetailsPage from "../showroom/sitters/searchPage/SitterSearchResultDetailsPage";
import OwnerPage from "../showroom/owners/OwnerPage";
import MyListingPreviewPage from "../showroom/listings/myListing/MyListingPreviewPage";
import PetListPage from "../showroom/pets/PetListPage";
import TripListPage from "../showroom/trips/TripListPage";
import VacancyListPage from "../showroom/vacancies/VacancyListPage";
import { useAuthStore } from "../showroom/hooks";

export const privateRoutePaths = {
  vacancySearch: () => "/search_vacancies",
  vacancySearchResult: (id: string | number = ":id") =>
    `/search_vacancies/${id}`,
  reservationDetails: (id: number | string) => `/reservations/${id}`,
  conversationList: () => "/conversations",
  conversation: (id: number | string = ":id") => `/conversations/${id}`,
  searchVacancies: (dest: string, start: string, end: string) =>
    `/search_vacancies/?destination=${dest}&startdate=${start}&enddate=${end}`,
  tripPage: (id: number | string = ":id") => `/trips/${id}`,
};

const defaultRoutes: Array<IRoute> = [
  {
    path: "/profile",
    component: <MyProfilePage />,
  },
  {
    path: privateRoutePaths.reservationDetails(":id"),
    component: <ReservationPage />,
  },
  {
    path: privateRoutePaths.conversationList(),
    component: <ConversationListPage />,
  },
  {
    path: privateRoutePaths.conversation(":id"),
    component: <ConversationPage />,
  },
  {
    path: "/sitters/search/:id",
    component: <SitterSearchResultDetailsPage />,
  },
];

const ownerRoutes: Array<IRoute> = [
  {
    path: "/dashboard",
    component: <Redirect to={"/home"} />,
  },
  {
    path: "/owner",
    component: <OwnerPage />,
  },
  {
    path: "/home",
    component: <HomePage />,
  },
  {
    path: "/pets",
    component: <PetListPage />,
  },
  {
    path: "/pets/:id",
    component: <PetPage />,
  },
  {
    path: "/listing",
    component: <MyListingPage />,
  },
  {
    path: "/listing/preview",
    component: <MyListingPreviewPage />,
  },
  {
    path: "/vacancies",
    component: <VacancyListPage />,
  },
  {
    path: "/vacancies/:id",
    component: <VacancyPage />,
  },
  {
    path: "/sitters/search",
    component: <SitterSearchPage />,
  },
];

const sitterRoutes: Array<IRoute> = [
  {
    path: "/dashboard",
    component: <Redirect to={"/sitter"} />,
  },
  {
    path: privateRoutePaths.vacancySearchResult(),
    component: <VacancySearchResultPage />,
  },
  {
    path: privateRoutePaths.vacancySearch(),
    component: <VacancySearchPage />,
  },
  {
    path: "/sitter",
    component: <SitterPage />,
  },
  {
    path: "/trips/:id",
    component: <TripPage />,
  },
  {
    path: "/trips",
    component: <TripListPage />,
  },
];

const routes: Array<IRoute> = [
  ...defaultRoutes,
  ...ownerRoutes,
  ...sitterRoutes,
];

export const privateRoutes = routes.map((route) => route.path);
export const defaultRouteComponents = defaultRoutes;
export const sitterRouteComponents = sitterRoutes;
export const ownerRouteComponents = ownerRoutes;

export default function PrivateRoutes() {
  const authStore = useAuthStore();

  useEffect(() => {
    authStore.verify();
  }, [authStore]);

  return useObserver(() => {
    const authStatus = authStore.authStatus;

    return authStatus.loading ? (
      <CircularProgress variant={"indeterminate"} color={"secondary"} />
    ) : authStatus.verified ? (
      <BasePage />
    ) : (
      <Redirect to={"/"} />
    );
  });
}
