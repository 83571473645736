import React, { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Divider,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useObserver } from "mobx-react";
import { useAuthStore, useProfileStore } from "../../hooks";

export default function ProfileMenu() {
  const authStore = useAuthStore();
  const profileStore = useProfileStore();
  const history = useHistory();

  const [anchor, setAnchor] = useState(null);

  const showMenu = (e: FormEvent) => setAnchor(e.currentTarget);
  const hideMenu = () => setAnchor(null);

  const goToProfile = () => history.push("/profile");

  const logout = () => {
    setAnchor(null);
    authStore.clear();
  };

  return useObserver(() => (
    <div>
      <Avatar
        src={profileStore.coverPhotoURL}
        onClick={showMenu}
        style={{ cursor: "pointer" }}
      />

      <Menu
        id={"menu-profile"}
        anchorEl={anchor}
        keepMounted
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchor)}
        onClose={hideMenu}
        MenuListProps={{ onMouseLeave: hideMenu }}
      >
        <MenuItem onClick={goToProfile}>
          <ListItemIcon>
            <Icon color={"primary"}>person</Icon>
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>

        <Divider />

        <a
          href={"https://sites.google.com/jetsyt.com/help-center/help-center"}
          target={"_blank"}
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <ListItemIcon>
              <Icon>help</Icon>
            </ListItemIcon>
            <ListItemText>Help Center</ListItemText>
          </MenuItem>
        </a>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Icon>exit_to_app</Icon>
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  ));
}
