import React, { useEffect, useState } from "react";
import { useHomeStore } from "../../hooks";
import Modal from "../../misc/Modal";
import { TextField } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { TextFieldChangeEvent } from "../../models";
import { deepCopy } from "../../../utils";
import { IHome } from "../../../storeroom/homes/models";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export default function HomeRulesModal(props: IProps) {
  const homeStore = useHomeStore();
  const [rules, setRules] = useState(homeStore.home?.home_rules);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.show) {
      setSubmitted(false);
      setError("");
      setRules(homeStore.home?.home_rules);
    }
  }, [props.show, homeStore.home]);

  const onChange = (event: TextFieldChangeEvent) => {
    setRules(event.target.value);
  };

  const onSave = () => {
    const updatedHome: IHome = deepCopy(homeStore.home);
    updatedHome.home_rules = rules;
    setSubmitted(true);
    homeStore
      .submitUpdates(updatedHome)
      .then(props.onClose)
      .catch(() => setError("Error saving home rules"));
  };

  return useObserver(() => {
    const home = homeStore.home;

    return home ? (
      <Modal
        show={props.show}
        maxWidth={"sm"}
        headerChildren={<strong>Home Rules</strong>}
        contentChildren={
          <React.Fragment>
            {error && (
              <Alert severity={"error"} style={{ marginBottom: "1rem" }}>
                {error}
              </Alert>
            )}

            <TextField
              label={"Home Rules"}
              fullWidth
              value={rules}
              onChange={onChange}
              multiline={true}
              rows={18}
              autoFocus={true}
            />
          </React.Fragment>
        }
        footerChildren={
          <React.Fragment>
            <Button onClick={props.onClose} disabled={submitted}>
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              disabled={submitted}
              onClick={onSave}
            >
              Submit
            </Button>
          </React.Fragment>
        }
      />
    ) : null;
  });
}
