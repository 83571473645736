import moment from "moment";

export const humanizeDate = (date: string) => {
  return moment(date).format("MMM D");
};

export const humanizeDateRange = (startDate: string, endDate: string) => {
  const start = moment(startDate).format("MMM D");
  const end = moment(endDate).format("MMM D");
  return `${start} - ${end}`;
};

export const humanizeTimestamp = (timestamp: string) => {
  return moment(timestamp).format("MMM D, h:mm a");
};
