import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  createStyles,
  Grid,
  Theme,
} from "@material-ui/core";
import { RatingProps } from "@material-ui/lab";
import Rating from "@material-ui/lab/Rating";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { SitterSearchResult } from "../../../storeroom/sitters/models";
import useTheme from "@material-ui/core/styles/useTheme";
import { humanizeDateRange } from "../../../formattingService";

const StyledRating = withStyles((theme: Theme) =>
  createStyles({
    iconFilled: {
      color: theme.palette.primary.main,
    },
  })
)(Rating);

function PipeSeparator() {
  const theme = useTheme();

  const style: React.CSSProperties = {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  };

  return <span style={style}>&nbsp;|&nbsp;</span>;
}

export default function SitterSearchResultsSection() {
  const history = useHistory();
  const store = useContext(StoreContext);
  const searchManager = store.sitterSearchManager;

  const onClick = (result: SitterSearchResult) => {
    history.push(`/sitters/search/${result.trip}`);
  };

  const ratingProps: RatingProps = {
    precision: 0.1,
    readOnly: true,
    size: "small",
  };

  return useObserver(() => (
    <Container maxWidth={"lg"} style={{ marginTop: "3em" }}>
      <Grid container spacing={2}>
        {searchManager.searchResults?.page_results.map((result) => (
          <Grid item xs={12} sm={6} lg={4} key={result.trip}>
            <Card>
              <CardActionArea onClick={() => onClick(result)}>
                <CardMedia
                  component={"img"}
                  image={result.picture}
                  title={"Home"}
                  height={"240"}
                />
                <CardContent>
                  <Typography
                    variant={"body1"}
                    style={{ fontWeight: 700, whiteSpace: "nowrap" }}
                  >
                    {result.name}
                  </Typography>

                  <StyledRating {...ratingProps} value={result.rating} />
                  <div style={{ height: "0.5rem" }} />

                  <Typography variant={"body1"}>
                    {humanizeDateRange(result.start_date, result.end_date)}
                    <PipeSeparator />
                    Preferred Pets: {result.pet_preferences}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  ));
}
