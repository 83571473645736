import React, { useContext } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import { AppBar, Container, Toolbar, Typography } from "@material-ui/core";
import { humanizeDateRange } from "../../../formattingService";
import { useParams, useHistory } from "react-router-dom";
import { SitterSearchResultDetails } from "../../../storeroom/sitters/models";
import { ICrumbProps } from "../../base/Crumbs";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar from "../../base/PageToolbar";
import { useDocumentTitle } from "../../hooks";

export default function SitterSearchResultDetailsToolbar() {
  const history = useHistory();
  const store = useContext(StoreContext);
  const searchResultDetails: SitterSearchResultDetails =
    store.sitterSearchManager.searchResultDetails;
  const { id } = useParams();

  const dateRange = humanizeDateRange(
    searchResultDetails.start_date,
    searchResultDetails.end_date
  );
  useDocumentTitle(`Search Result: ${dateRange}`);

  const crumbs: ICrumbProps = {
    currentLabel: dateRange,
  };

  const actions: Array<IPageToolbarAction> =
    id === "preview"
      ? []
      : Boolean(searchResultDetails.current_request)
      ? [
          {
            label: "View Request",
            icon: "mail",
            onClick: () =>
              history.push(
                `/reservations/${searchResultDetails.current_request}`
              ),
          },
        ]
      : [
          {
            label: "Send Request",
            icon: "mail",
            onClick: () => {},
            disabled: true,
          },
        ];

  return <PageToolbar crumbs={crumbs} actions={actions} />;
}

export function SitterSearchResultDetailsLoadingToolbar() {
  return useObserver(() => (
    <AppBar position={"sticky"} style={{ marginBottom: "2em" }} elevation={4}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Toolbar>
          <Typography variant={"h6"} style={{ marginRight: "2em" }}>
            Sitter
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  ));
}
