import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import MyListingToolbar, {
  ReadOnlyMyListingToolbar,
} from "./toolbar/MyListingToolbar";
import TitleSection from "./sections/TitleSection";
import DescriptionSection from "./sections/DescriptionSection";
import NotesSection from "./sections/NotesSection";
import LoadingSection from "../../base/LoadingSection";
import ListingStatusSection from "./sections/ListingStatusSection";
import { useDocumentTitle, useListingStore } from "../../hooks";
import AlbumSection from "../../albums/AlbumSection";

export default function MyListingPage() {
  const listingStore = useListingStore();

  useDocumentTitle("Listing");

  useEffect(() => {
    listingStore.load();
  }, [listingStore]);

  return useObserver(() => {
    const listing = listingStore.listing;

    return Boolean(listing) ? (
      <div>
        <MyListingToolbar />
        <ListingStatusSection />
        <TitleSection />
        <DescriptionSection />
        <AlbumSection
          album={listing.album}
          headerOverride={"Pictures of Home"}
        />
        <NotesSection />
      </div>
    ) : (
      <div>
        <ReadOnlyMyListingToolbar />
        <LoadingSection header={"Title"} />
        <LoadingSection header={"Description"} />
        <LoadingSection header={"Pictures of Home"} />
        <LoadingSection header={"Other Notes"} />
      </div>
    );
  });
}
