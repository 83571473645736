import { IAlbum } from "../albums/models";
import { IHomeAmenity } from "../amenities/models";

export enum ReservationStatus {
  requested = 1,
  in_review = 2,
  accepted = 3,
  rejected = 4,
  canceled = 5,
  expired = 6,
  started = 7,
  completed = 8,
}

export interface IOtherUser {
  id: number;
  name: string;
  is_owner: boolean;
  picture_url: string;
}

export interface IReservationBase {
  id: number;
  is_guest: boolean;
  start_date: string;
  end_date: string;
  status: ReservationStatus;
  status_display: string;
  conversation: number;
  vacancy: number;
  trip: number;
}

export interface IReservation {
  id: number;
  other_user: IOtherUser;
  conversation: number;
  status: ReservationStatus;
  status_display: string;
  start_date: string;
  end_date: string;
  vacancy: number;
  trip: number;
  requested_by: number;
  album: IAlbum;
}

interface IReservationGuest {
  id: number;
  sitter_id: number;
  album: IAlbum;
  name: string;
}

export interface IReservationForHost extends IReservationBase {
  guest: IReservationGuest;
  sitter_review: number;
}

export interface IReservationHome {
  beds: number;
  bedrooms: number;
  bathrooms: number;
  check_in_instructions: string;
  album: number;
  amenities: Array<IHomeAmenity>;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  home_rules: string;
}

export interface IReservationListing {
  description: string;
  neighborhood: string;
  other_notes: string;
  home: IReservationHome;
}

export interface IReservationHost {
  name: string;
  description: string;
  email: string;
  phone_number: string;
  cover_photo_url: string;
}

export interface IReservationPet {
  name: string;
  type: string;
  breed: string;
  description: string;
  album: number;
}

export interface IReservationPetInfo {
  routine: string;
  pets: Array<IReservationPet>;
}

export interface IReservationForGuest extends IReservationBase {
  listing: IReservationListing;
  host: IReservationHost;
  pets: IReservationPetInfo;
  home_review: number;
}

export const isAccepted = (reservation: IReservationBase) => {
  return [ReservationStatus.accepted, ReservationStatus.started].includes(
    reservation.status
  );
};

export const isInactiveReservation = (reservation: IReservation) => {
  return [
    ReservationStatus.canceled,
    ReservationStatus.expired,
    ReservationStatus.rejected,
    ReservationStatus.completed,
  ].includes(reservation.status);
};

export const statusDisplayName = (status: number) => {
  return [
    "",
    "Requested",
    "In Review",
    "Accepted",
    "Rejected",
    "Canceled",
    "Expired",
    "Started",
    "Completed",
  ][status];
};
