import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { IVacancy } from "../../../storeroom/homes/models";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useListingStore } from "../../hooks";
import { Alert } from "@material-ui/lab";

export interface IVacancyDeleteDialogProps {
  show: boolean;
  onClose: (canceled: boolean) => void;
  vacancy: IVacancy;
}

export default function VacancyDeleteDialog(props: IVacancyDeleteDialogProps) {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const vacancyListStore = useListingStore().vacancyListStore;

  useEffect(() => {
    if (props.show) {
      setSubmitted(false);
      setError("");
    }
  }, [props.show]);

  const onCancel = () => {
    props.onClose(false);
  };

  const onError = (error: any) => {
    setError(error.response.data[0]);
    setSubmitted(false);
  };

  const onContinue = () => {
    setSubmitted(true);
    vacancyListStore
      .remove(props.vacancy.id)
      .then(() => props.onClose(true))
      .catch(onError);
  };

  return (
    <Dialog open={props.show} fullWidth maxWidth={"xs"}>
      <DialogTitle>Cancel Trip</DialogTitle>

      <DialogContent>
        <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
          Are you sure?
        </Typography>

        <Typography variant={"body1"}>
          This trip will be deleted along with all related reservations and
          requests. This cannot be reversed.
        </Typography>
        <div style={{ height: "1rem" }} />

        {error && <Alert severity={"error"}>{error}</Alert>}
      </DialogContent>

      <DialogActions>
        <Button disabled={submitted} onClick={onCancel} size={"small"}>
          Cancel
        </Button>

        <Button
          disabled={submitted}
          variant={"contained"}
          color={"primary"}
          onClick={onContinue}
          size={"small"}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
