import React from "react";
import { Typography } from "@material-ui/core";
import { publicRoutePaths } from "../../routes/PublicRoutes";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function Page404() {
  const history = useHistory();

  const onLogoClick = () => {
    history.push(publicRoutePaths.front());
  };

  return (
    <div style={{ textAlign: "center", padding: "3rem" }}>
      <Typography variant={"h2"}>Oh No!</Typography>

      <Typography variant={"h4"}>
        We couldn't find the page you're looking for.
      </Typography>

      <div style={{ height: "3rem" }} />

      <Button onClick={onLogoClick} variant={"contained"} color={"secondary"}>
        Go Home
      </Button>
    </div>
  );
}
