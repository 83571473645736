import React, { useState } from "react";
import { IMenuListProps } from "./models";
import { Menu } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";

export default function MenuDropdown(props: IMenuListProps) {
  const [anchor, setAnchor] = useState(null);

  const showMenu = (event: any) => {
    setAnchor(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchor(null);
  };

  return (
    <React.Fragment>
      <Button onClick={showMenu} endIcon={<Icon>expand_more</Icon>}>
        {props.label}
      </Button>

      <Menu
        keepMounted
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={hideMenu}
        MenuListProps={{ onMouseLeave: hideMenu }}
        style={{ marginTop: "2em" }}
      >
        {props.menuItems.map((menuItem) => (
          <MenuItem onClick={menuItem.onClick} key={menuItem.label}>
            <ListItemIcon>
              <Icon color={menuItem.color}>{menuItem.icon}</Icon>
            </ListItemIcon>
            <ListItemText>{menuItem.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
