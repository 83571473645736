import React, { useState } from "react";
import ListingPreviewHomeTab from "./tabs/ListingPreviewHomeTab";
import ListingPreviewPetsTab from "./tabs/ListingPreviewPetsTab";
import ListingPreviewOwnerTab from "./tabs/ListingPreviewOwnerTab";
import { Container } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
import { useVacancySearchResult } from "../contexts";
import Avatar from "@material-ui/core/Avatar";

interface ITabPanelProps {
  activeTab: number;
}

function TabPanel(props: ITabPanelProps) {
  switch (props.activeTab) {
    case 0:
      return <ListingPreviewHomeTab />;

    case 1:
      return <ListingPreviewPetsTab />;

    case 2:
      return <ListingPreviewOwnerTab />;

    default:
      return null;
  }
}

export default function ListingPreviewTabs() {
  const listing = useVacancySearchResult().listing;
  const [activeTab, setActiveTab] = useState(0);

  const onTabChange = (event: any, newTab: number) => {
    setActiveTab(newTab);
  };

  return (
    <Container maxWidth={"md"} disableGutters={true}>
      <Container maxWidth={"md"}>
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          indicatorColor={"secondary"}
          textColor={"secondary"}
          variant={"fullWidth"}
        >
          <Tab label={"Home"} icon={<Icon fontSize={"large"}>home</Icon>} />
          <Tab label={"Pets"} icon={<Icon fontSize={"large"}>pets</Icon>} />
          <Tab
            label={"Pet Parent"}
            icon={<Avatar src={listing.owner.cover_photo} />}
          />
        </Tabs>
      </Container>

      <div style={{ height: "1rem" }} />
      <TabPanel activeTab={activeTab} />
    </Container>
  );
}
