import { action, observable } from "mobx";
import { IOwner } from "./models";
import { HttpClient } from "../HttpClient";
import OwnerPreferencesManager from "../owners/OwnerPreferencesManager";

export class OwnerStore {
  preferencesManager = new OwnerPreferencesManager();

  @observable owner: IOwner = undefined;

  @action
  private setOwner = (newOwner: IOwner) => {
    this.owner = newOwner;
  };

  load = (error?: (error: Response) => void) => {
    if (this.owner === undefined) {
      HttpClient.get<IOwner>("profile/owner/").then(this.postLoad).catch(error);
    }
  };

  postLoad = (owner: IOwner) => {
    this.preferencesManager.loadPreferences().then(() => this.setOwner(owner));
  };

  register = (error?: (error: Response) => void): void => {
    HttpClient.get<IOwner>("profile/register_as_owner/")
      .then((response: IOwner) => this.setOwner(response))
      .catch(error);
  };

  update = (
    updatedOwner: IOwner,
    error?: (error: Response) => void
  ): Promise<void> => {
    return HttpClient.put<IOwner>(
      "profile/owner/",
      updatedOwner
    ).then((response: IOwner) => this.setOwner(response));
  };
}
