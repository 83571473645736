import React, { useState } from "react";
import { Container } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HomeTab from "./tabs/HomeTab";
import PetsTab from "./tabs/PetsTab";
import PetParentTab from "./tabs/PetParentTab";
import { ReservationStatus } from "../../../storeroom/reservations/models";
import Icon from "@material-ui/core/Icon";
import { useGuestReservation } from "../hooks";
import Avatar from "@material-ui/core/Avatar";

export default function ReservationContent() {
  const reservation = useGuestReservation();
  const [activeTab, setActiveTab] = useState(0);

  const onTabChange = (event: any, newTab: number) => {
    setActiveTab(newTab);
  };

  return (
    <Container maxWidth={"md"} disableGutters={true}>
      <Container maxWidth={"md"}>
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          indicatorColor={"secondary"}
          textColor={"secondary"}
          variant={"fullWidth"}
        >
          <Tab label={"Home"} icon={<Icon fontSize={"large"}>home</Icon>} />
          <Tab label={"Pets"} icon={<Icon fontSize={"large"}>pets</Icon>} />
          <Tab
            label={"Pet Parent"}
            icon={<Avatar src={reservation.host.cover_photo_url} />}
          />
        </Tabs>
      </Container>

      <div style={{ height: "1rem" }} />
      <TabPanel activeTab={activeTab} status={reservation.status} />
    </Container>
  );
}

function TabPanel(props: { activeTab: number; status: ReservationStatus }) {
  const isStarted = props.status === ReservationStatus.started;

  switch (props.activeTab) {
    case 0:
      return isStarted ? <PetsTab /> : <HomeTab />;

    case 1:
      return isStarted ? <HomeTab /> : <PetsTab />;

    case 2:
      return <PetParentTab />;

    default:
      return null;
  }
}
