import React from "react";
import { IMenuItem, IMenuListProps, IMenuProps } from "./models";
import { useHistory } from "react-router-dom";
import { useTabletOrLarger } from "../../../hooks";
import MenuDropdown from "./MenuDropdown";
import MenuButtonList from "./MenuButtonList";

export default function OwnerMenu(props: IMenuProps) {
  const history = useHistory();
  const isNarrow = !useTabletOrLarger();

  const menuItems: Array<IMenuItem> = [
    {
      label: "My Profile",
      onClick: () => history.push("/owner"),
      icon: "person",
      color: "primary",
    },
    {
      label: "Home",
      onClick: () => history.push("/home"),
      icon: "home",
      color: "primary",
    },
    {
      label: "Pets",
      onClick: () => history.push("/pets"),
      icon: "pets",
      color: "primary",
    },
    {
      label: "Listing",
      onClick: () => history.push("/listing"),
      icon: "wysiwyg",
      color: "primary",
    },
    {
      label: "Vacancies",
      onClick: () => history.push("/vacancies"),
      icon: "card_travel",
      color: "primary",
    },
  ];

  const menuListProps: IMenuListProps = {
    label: "Parent",
    menuItems: menuItems,
  };

  return isNarrow || props.forceList ? (
    <MenuDropdown {...menuListProps} />
  ) : (
    <MenuButtonList {...menuListProps} />
  );
}
