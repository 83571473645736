import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F44E0C",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#1bbebb",
      contrastText: "#FFFFFF",
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#FFF",
      },
    },
  },
});

export function hexToRGBA(hex: string, a: number) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export default function Theme(props: { children: any }) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
