import React, { FormEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { IProfile } from "../../../../storeroom/users/models";
import { StoreContext } from "../../../../storeroom/StoreContext";
import { observer } from "mobx-react";
import { reaction } from "mobx";
import { Alert } from "@material-ui/lab";

interface ProfileFormDialogProps {
  show: boolean;
  onClose: () => void;
}

interface ProfileFormDialogState {
  wipProfile: IProfile;
  submitted: boolean;
  error: string;
  disposer: any;
}

@observer
class ProfileFormDialog extends React.Component<
  ProfileFormDialogProps,
  ProfileFormDialogState
> {
  static contextType = StoreContext;

  constructor(props: ProfileFormDialogProps) {
    super(props);

    this.state = {
      wipProfile: undefined,
      submitted: false,
      error: "",
      disposer: undefined,
    };
  }

  componentDidMount(): void {
    this.initializeProfile();
    this.setState({ disposer: this.profileReaction() });
  }

  componentDidUpdate(
    prevProps: Readonly<ProfileFormDialogProps>,
    prevState: Readonly<ProfileFormDialogState>,
    snapshot?: any
  ): void {
    if (!prevProps.show && this.props.show) {
      this.initializeProfile();
    }
  }

  componentWillUnmount(): void {
    if (this.state.disposer !== undefined) {
      this.state.disposer();
    }
  }

  profileReaction = () => {
    return reaction(
      () => this.context.profileStore.profile,
      () => {
        if (this.state.submitted) {
          this.onClose();
        }
      }
    );
  };

  initializeProfile = () => {
    const wipProfile = JSON.parse(
      JSON.stringify(this.context.profileStore.profile)
    );
    this.setState({ wipProfile: wipProfile });
  };

  onClose = () => {
    this.setState({ submitted: false }, () => {
      this.props.onClose();
    });
  };

  onError = (error: any) => {
    this.setState({ error: "Invalid changes.", submitted: false });
  };

  onSubmit = (event: FormEvent) => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      this.context.profileStore.update(this.state.wipProfile, this.onError);
    });
  };

  onFirstNameChange = (event: any) => {
    const profile = this.state.wipProfile;
    profile.user.first_name = event.target.value;
    this.setState({ wipProfile: profile });
  };

  onLastNameChange = (event: any) => {
    const profile = this.state.wipProfile;
    profile.user.last_name = event.target.value;
    this.setState({ wipProfile: profile });
  };

  onPhoneChange = (event: any) => {
    const profile = this.state.wipProfile;
    profile.phone = event.target.value;
    this.setState({ wipProfile: profile });
  };

  render() {
    const profile = this.state.wipProfile;
    const submitted = this.state.submitted;

    const errorAlert = this.state.error ? (
      <Alert severity={"error"}>{this.state.error}</Alert>
    ) : (
      ""
    );

    return profile !== undefined ? (
      <Dialog open={this.props.show}>
        <form onSubmit={this.onSubmit} autoComplete={"off"}>
          <DialogTitle>Edit Profile</DialogTitle>

          <DialogContent>
            <div>
              <TextField
                label={"First Name"}
                style={{ marginRight: "2em" }}
                defaultValue={profile.user.first_name}
                onChange={this.onFirstNameChange}
              />

              <TextField
                label={"Last Name"}
                defaultValue={profile.user.last_name}
                onChange={this.onLastNameChange}
              />
            </div>

            <div>
              <TextField
                label={"Phone"}
                fullWidth
                style={{ marginTop: "2em" }}
                defaultValue={profile.phone}
                onChange={this.onPhoneChange}
              />
            </div>

            {errorAlert}
          </DialogContent>

          <DialogActions style={{ marginTop: "1em" }}>
            <Button onClick={this.onClose} disabled={submitted}>
              Cancel
            </Button>
            <Button
              type={"submit"}
              variant={"contained"}
              color={"secondary"}
              disabled={submitted}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ) : (
      <div />
    );
  }
}

export default ProfileFormDialog;
