import { HttpClient } from "../HttpClient";
import { IAlbum, IPicture } from "./models";

export default class AlbumsHttpClient {
  static addPicture(
    picture: File,
    albumID: string,
    uploaded_by: string
  ): Promise<IPicture> {
    const formData = new FormData();
    formData.append("picture", picture);
    formData.append("subject", albumID);
    formData.append("uploaded_by", uploaded_by);

    return HttpClient.postFile<IPicture>("pictures/", formData);
  }

  static preview(albumID: number): Promise<IAlbum> {
    return HttpClient.get<IAlbum>(`albums/${albumID}/preview/`);
  }

  static retrieve(albumID: number): Promise<IAlbum> {
    return HttpClient.get<IAlbum>(`albums/${albumID}/`);
  }

  static update(album: IAlbum): Promise<IAlbum> {
    return HttpClient.put<IAlbum>(`albums/${album.id}/`, album);
  }

  static deletePicture(pictureID: number): Promise<any> {
    return HttpClient.delete(`pictures/${pictureID}/`);
  }
}
