import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { Typography } from "@material-ui/core";
import PetDetailsModal from "../modals/PetDetailsModal";

export default function BasicPetInfoSection() {
  const [showEditForm, setShowEditForm] = useState(false);

  const store = useContext(StoreContext);
  const pet = store.petStore.pet;

  const sectionProps: SectionProps = {
    header: pet ? pet.name : "Loading...",
    actionIcon: "edit",
    actionLabel: "Edit Basic Info",
    onActionClick: () => setShowEditForm(true),
  };

  return (
    <Section {...sectionProps}>
      <PetDetailsModal
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <Typography style={sectionBodyStyle}>
        {pet.breed} ({pet.type})
      </Typography>
    </Section>
  );
}
