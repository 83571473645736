import { action, observable } from "mobx";
import { ITrip } from "./models";
import { HttpClient } from "../HttpClient";

export class TripStoreOld {
  @observable tripList: Array<ITrip> = undefined;
  @observable trip: ITrip = null;

  @action
  private setTripList = (newList: Array<ITrip>) => {
    this.tripList = newList;
  };

  @action
  private setTrip = (newTrip: ITrip) => {
    this.trip = newTrip;
  };

  @action
  private postAdd = (newTrip: ITrip) => {
    this.trip = newTrip;
  };

  @action
  private postUpdate = (updatedTrip: ITrip) => {
    this.trip = updatedTrip;
    this.tripList = this.tripList.map((trip) =>
      trip.id === updatedTrip.id ? updatedTrip : trip
    );
  };

  @action
  private postRemove = (id: number) => {
    if (this.tripList) {
      this.tripList = this.tripList.filter((trip) => trip.id !== id);
      this.trip = null;
    }
  };

  loadTripList = (error?: (error: Response) => void) => {
    if (this.tripList === undefined) {
      HttpClient.get<Array<ITrip>>("trips/?completed=false")
        .then((response: Array<ITrip>) => this.setTripList(response))
        .catch(error);
    }
  };

  loadTrip = (id: number, error?: (error: Response) => void) => {
    this.trip = null;
    HttpClient.get<ITrip>(`trips/${id}/`)
      .then((response) => this.setTrip(response))
      .catch(error);
  };

  add = (newTrip: ITrip): Promise<ITrip> => {
    return HttpClient.post<ITrip>("trips/", newTrip).then((response) => {
      this.postAdd(response);
      return response;
    });
  };

  update = (updatedTrip: ITrip): Promise<void> => {
    return HttpClient.put<ITrip>(
      `trips/${updatedTrip.id}/`,
      updatedTrip
    ).then((response) => this.postUpdate(response));
  };

  remove = (id: number): Promise<void> => {
    return HttpClient.delete(`trips/${id}/`).then(() => this.postRemove(id));
  };

  getGooglePicture = (tripID: number): Promise<any> => {
    return HttpClient.getImage(`trips/${tripID}/get_google_picture/`);
  };
}
