import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useHistory } from "react-router-dom";
import { ICrumbProps } from "../../base/Crumbs";
import { humanizeDateRange } from "../../../formattingService";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar from "../../base/PageToolbar";
import VacancyDeleteDialog from "../modals/VacancyDeleteDialog";
import { useDocumentTitle } from "../../hooks";

export default function VacancyToolbar() {
  const history = useHistory();
  const store = useContext(StoreContext);
  const vacancy = store.vacancyStore.vacancy;

  const [showCancelModal, setShowCancelModal] = useState(false);

  const dateRange = humanizeDateRange(vacancy.start_date, vacancy.end_date);
  useDocumentTitle(`Vacancy: ${dateRange}`);

  const onCancelClose = (canceled: boolean) => {
    setShowCancelModal(false);
    if (canceled) {
      history.replace("/vacancies");
    }
  };

  const onDelete = () => {
    setShowCancelModal(true);
  };

  const onPreview = () => {
    history.push(`/listing/preview`);
  };

  const findSitters = () => {
    history.push(`/sitters/search`, {
      startDate: vacancy.start_date,
      endDate: vacancy.end_date,
    });
  };

  const crumbs: ICrumbProps = {
    currentLabel: dateRange,
    links: [
      {
        label: "Home",
        url: "/home",
      },
      {
        label: "Listing",
        url: "/listing",
      },
      {
        label: "Vacancies",
        url: "/vacancies",
      },
    ],
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "Find Sitters",
      icon: "search",
      onClick: findSitters,
    },
    {
      label: "Preview",
      icon: "compare",
      onClick: onPreview,
    },
    {
      label: "Cancel",
      icon: "delete",
      forceIcon: true,
      onClick: onDelete,
    },
  ];

  return (
    <PageToolbar crumbs={crumbs} actions={actions}>
      <VacancyDeleteDialog
        show={showCancelModal}
        onClose={onCancelClose}
        vacancy={vacancy}
      />
    </PageToolbar>
  );
}

export function VacancyLoadingToolbar() {
  const crumbs: ICrumbProps = {
    currentLabel: "Loading...",
    links: [
      {
        label: "Home",
        url: "/home",
      },
      {
        label: "Listing",
        url: "/listing",
      },
      {
        label: "Vacancies",
        url: "/vacancies",
      },
    ],
  };

  return <PageToolbar crumbs={crumbs} actions={[]} />;
}
