import React, { useContext, useState } from "react";
import { Section, sectionBodyStyle } from "../../base/Section";
import { StoreContext } from "../../../storeroom/StoreContext";
import { Typography } from "@material-ui/core";
import HomeFormModal from "../forms/HomeFormModal";

export default function AddressSection() {
  const [showEditForm, setShowEditForm] = useState(false);
  const stores = useContext(StoreContext);

  return (
    <Section
      header={"Address"}
      actionIcon={"edit"}
      actionLabel={"Edit Address"}
      onActionClick={() => setShowEditForm(true)}
    >
      <HomeFormModal
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <Typography style={sectionBodyStyle}>
        {stores.homeStore.home.location}
      </Typography>
    </Section>
  );
}
