import React from "react";
import {
  createStyles,
  DialogTitle,
  DialogTitleProps,
  IconButtonProps,
  Theme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useOwnerStyles, useSitterStyles } from "../../../base/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 10010,
      color: "inherit",
    },
    label: {
      pointerEvents: "none",
    },
  })
);

interface IProps {
  header: string;
  onClose?: () => void;
  showCloseIcon?: boolean;
  secondary?: boolean;
}

export default function AlbumModalHeader(props: IProps) {
  const ownerClasses = useOwnerStyles();
  const sitterClasses = useSitterStyles();
  const classes = useStyles();
  const { header, showCloseIcon } = props;

  const dialogTitleProps: DialogTitleProps = {
    className: props.secondary
      ? sitterClasses.modalHeader
      : ownerClasses.modalHeader,
    disableTypography: true,
  };

  const iconButtonProps: IconButtonProps = {
    className: classes.closeIcon,
    onClick: props.onClose,
  };

  return (
    <DialogTitle {...dialogTitleProps}>
      <Typography variant={"h6"}>{header}</Typography>

      {showCloseIcon && (
        <IconButton {...iconButtonProps}>
          <Icon>close</Icon>
        </IconButton>
      )}
    </DialogTitle>
  );
}
