import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useObserver } from "mobx-react";
import HomeReviewStore from "../../../storeroom/reviews/homeReviews/HomeReviewStore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Rating, RatingProps } from "@material-ui/lab";
import { TextFieldChangeEvent } from "../../models";
import { IHomeReview } from "../../../storeroom/reviews/models";
import Modal from "../../misc/Modal";
import FieldRow from "./FieldRow";
import ThumbsField from "./ThumbsField";

const ReviewStoreContext = React.createContext<HomeReviewStore>(null);

export interface IHomeReviewModalProps {
  show: boolean;
  onClose: (homeReview: IHomeReview) => void;
  reservationID: number;
  reviewID?: number;
}

export default function HomeReviewModal(props: IHomeReviewModalProps) {
  const [store, setStore] = useState<HomeReviewStore>(null);
  const { onClose } = props;

  useEffect(() => {
    if (props.show) {
      setStore(new HomeReviewStore(props.reviewID, props.reservationID));
    }
  }, [props.show, props.reviewID, props.reservationID]);

  return useObserver(() => {
    return store ? (
      <ReviewStoreContext.Provider value={store}>
        <Modal
          show={props.show}
          headerChildren={"Home Review"}
          contentChildren={<ModalContent />}
          footerChildren={<ModalActions store={store} onClose={onClose} />}
          secondary={true}
        />
      </ReviewStoreContext.Provider>
    ) : null;
  });
}

function RatingField() {
  const store = useContext(ReviewStoreContext);

  const onRatingChange = (event: any, rating: any) => {
    if ([1, 2, 3, 4, 5].includes(rating)) {
      store.setRating(rating);
    } else {
      store.setRating(null);
    }
  };

  return useObserver(() => {
    const ratingProps: RatingProps = {
      value: store.homeReview.rating,
      onChange: onRatingChange,
      size: "large",
      name: "overallRating",
    };

    return (
      <FieldRow label={"Overall Experience"}>
        <Rating {...ratingProps} />
      </FieldRow>
    );
  });
}

function HomeAccuracyField() {
  const store = useContext(ReviewStoreContext);

  return useObserver(() => {
    return (
      <ThumbsField
        label={"Home Accuracy"}
        thumbState={store.homeReview.home_accuracy}
        onChange={store.setHomeAccuracy}
        helpText={
          "The listing on the reservation accurately reflected the actual home and amenities."
        }
        secondary={true}
      />
    );
  });
}

function PetAccuracyField() {
  const store = useContext(ReviewStoreContext);

  return useObserver(() => {
    return (
      <ThumbsField
        label={"Pet Accuracy"}
        thumbState={store.homeReview.pet_accuracy}
        onChange={store.setPetAccuracy}
        helpText={
          "The pet information on the listing accurately reflected the actual pets."
        }
        secondary={true}
      />
    );
  });
}

function CommunicationField() {
  const store = useContext(ReviewStoreContext);

  return useObserver(() => {
    return (
      <ThumbsField
        label={"Communication"}
        thumbState={store.homeReview.communication}
        onChange={store.setCommunication}
        helpText={
          "The pet parent was timely and helpful when responding to my questions."
        }
        secondary={true}
      />
    );
  });
}

function CommentsField() {
  const store = useContext(ReviewStoreContext);

  const onChange = (event: TextFieldChangeEvent) => {
    store.setSummary(event.target.value);
  };

  return useObserver(() => {
    return (
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={store.homeReview.summary}
          onChange={onChange}
          label={"Comments"}
          multiline
          rows={6}
          color={"secondary"}
        />
      </Grid>
    );
  });
}

function SpacingField() {
  return (
    <Grid item xs={12}>
      <div style={{ height: 0 }} />
    </Grid>
  );
}

function ModalContent() {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <RatingField />
        <SpacingField />
        <HomeAccuracyField />
        <PetAccuracyField />
        <CommunicationField />
        <CommentsField />
      </Grid>
    </React.Fragment>
  );
}

interface IModalActionsProps {
  store: HomeReviewStore;
  onClose: (homeReview: IHomeReview) => void;
}

function ModalActions(props: IModalActionsProps) {
  const { store, onClose } = props;

  const onSubmit = () => {
    store.save().then(() => onClose(store.homeReview));
  };

  const onCancel = () => {
    props.onClose(null);
  };

  return useObserver(() => {
    return (
      <React.Fragment>
        <Button disabled={store.loading} size={"small"} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          disabled={!store.canSubmit}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          onClick={onSubmit}
        >
          Save
        </Button>
      </React.Fragment>
    );
  });
}
