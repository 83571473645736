import React, { useState } from "react";
import { useObserver } from "mobx-react";
import { Button, TextField } from "@material-ui/core";
import { useURLParams } from "../../hooks";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useReservationStore } from "../hooks";
import Modal from "../misc/Modal";
import { Alert } from "@material-ui/lab";

interface Props {
  vacancyID: number;
  show: boolean;
  onClose: (reservationID: number | null | undefined) => void;
}

interface FormFields {
  startDate: string;
  endDate: string;
  message: string;
}

const schema = object().shape({
  startDate: string().required(),
  endDate: string().required(),
  message: string().required(),
});

export default function RequestReservationFormDialog(props: Props) {
  const urlParams = useURLParams();
  const reservationStore = useReservationStore();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onError = (response: any) => {
    const message = response.response.data;
    setError(message.join(". "));
    setPending(false);
  };

  const onSubmit = (data: FormFields) => {
    setPending(true);
    setError("");
    reservationStore
      .request(
        props.vacancyID,
        urlParams.get("destination"),
        data.startDate,
        data.endDate,
        data.message
      )
      .then(props.onClose)
      .catch(onError);
  };

  return useObserver(() => (
    <Modal
      show={props.show}
      secondary={true}
      maxWidth={"xs"}
      onFormSubmit={handleSubmit(onSubmit)}
      headerChildren={<strong>Request to Reserve</strong>}
      contentChildren={
        <React.Fragment>
          {error && (
            <Alert severity={"error"} style={{ marginBottom: "1rem" }}>
              {error}
            </Alert>
          )}

          <TextField
            name={"startDate"}
            label={"Check-in"}
            type={"date"}
            fullWidth
            defaultValue={urlParams.get("start")}
            inputRef={register}
            disabled={pending}
            style={{ marginBottom: "1em" }}
            error={Boolean(errors.startDate)}
            helperText={
              Boolean(errors.startDate) ? errors.startDate.message : ""
            }
            InputLabelProps={{ shrink: true }}
            color={"secondary"}
          />

          <TextField
            name={"endDate"}
            label={"Check-out"}
            type={"date"}
            fullWidth
            defaultValue={urlParams.get("end")}
            inputRef={register}
            disabled={pending}
            style={{ marginBottom: "1em" }}
            error={Boolean(errors.endDate)}
            helperText={Boolean(errors.endDate) ? errors.endDate.message : ""}
            InputLabelProps={{ shrink: true }}
            color={"secondary"}
          />

          <TextField
            name={"message"}
            label={"Message"}
            fullWidth
            inputRef={register}
            multiline
            rows={6}
            disabled={pending}
            error={Boolean(errors.message)}
            helperText={
              Boolean(errors.message)
                ? errors.message.message
                : "Send a greeting or short message with your request"
            }
            color={"secondary"}
          />
        </React.Fragment>
      }
      footerChildren={
        <React.Fragment>
          <Button onClick={() => props.onClose(null)} disabled={pending}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            variant={"contained"}
            color={"secondary"}
            disabled={pending}
          >
            Submit
          </Button>
        </React.Fragment>
      }
    />
  ));
}
