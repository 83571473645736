import { action, observable } from "mobx";
import { IConversation, IMessage } from "./models";
import { HttpClient } from "../HttpClient";
import ConversationHttpClient from "./ConversationHttpClient";

export class ConversationStore {
  @observable conversation: IConversation = null;
  @observable loading: boolean = false;
  @observable sendingMessage: boolean = false;

  constructor(conversationID: number) {
    this.loadConversation(conversationID);
  }

  @action
  private setConversation = (newConversation: IConversation) => {
    this.conversation = newConversation;
    this.loading = false;
  };

  @action
  private postAdd = (newMessage: IMessage) => {
    const messages = this.conversation.messages;
    messages.push(newMessage);

    this.conversation = { ...this.conversation, messages: messages };
    this.sendingMessage = false;
  };

  loadConversation = (id: number) => {
    this.loading = true;
    ConversationHttpClient.retrieve(id).then(this.setConversation);
  };

  addTextMessage = (message: string) => {
    this.sendingMessage = true;
    return HttpClient.post<IMessage>(
      `conversations/${this.conversation.id}/add_text_message/`,
      { message }
    ).then((response) => this.postAdd(response));
  };

  addImageMessage = (imageFile: Blob) => {
    const formData = new FormData();
    formData.append("picture", imageFile);

    return HttpClient.postFile<IMessage>(
      `conversations/${this.conversation.id}/add_image_message/`,
      formData
    ).then((response) => this.postAdd(response));
  };

  static getPreview = (id: number) => ConversationHttpClient.preview(id);
}
