import React, { FormEvent } from "react";
import { StoreContext } from "../../storeroom/StoreContext";
import { reaction } from "mobx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { IAmenity, IHomeAmenity } from "../../storeroom/amenities/models";
import { Alert } from "@material-ui/lab";

interface Props {
  show: boolean;
  homeAmenityID: number;
  onClose: () => void;
}

interface State {
  wipAmenity: IHomeAmenity;
  submitted: boolean;
  error: string;
  disposer: any;
  isNew: boolean;
}

@observer
class EditAmenityFormDialog extends React.Component<Props, State> {
  static contextType = StoreContext;

  constructor(props: any) {
    super(props);

    this.state = {
      wipAmenity: undefined,
      submitted: false,
      error: "",
      disposer: undefined,
      isNew: false,
    };
  }

  initialize = () => {
    if (this.props.homeAmenityID !== null) {
      const id = this.props.homeAmenityID;
      const list = this.context.amenityStore.homeAmenityList;
      const homeAmenityOptions = list.filter(
        (amenity: IHomeAmenity) => amenity.id === id
      );
      this.setState({
        wipAmenity: homeAmenityOptions[0],
        error: "",
        isNew: false,
      });
    } else {
      this.setState({
        isNew: true,
        error: "",
        wipAmenity: {
          id: null,
          quantity: 1,
          private_details: "",
          home: this.context.homeStore.home.id,
          amenity: "",
          icon: "",
          display_name: "",
        },
      });
    }
  };

  amenityUpdateReaction = () => {
    return reaction(
      () => this.context.amenityStore.homeAmenityList,
      () => {
        if (this.state.submitted) {
          this.onClose();
        }
      }
    );
  };

  componentDidMount(): void {
    this.initialize();
    this.setState({ disposer: this.amenityUpdateReaction() });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!prevProps.show && this.props.show) {
      this.initialize();
    }
  }

  componentWillUnmount(): void {
    if (this.state.disposer !== undefined) {
      this.state.disposer();
    }
  }

  onClose = () => {
    this.setState({ submitted: false }, () => {
      this.props.onClose();
    });
  };

  onError = (error: any) => {
    const data = error.response.data;
    let errorState = { error: "Update Failed", submitted: false };

    if ("non_field_errors" in data) {
      const message = data.non_field_errors[0];
      debugger;
      if (message.includes("must make a unique set")) {
        errorState = { error: "Amenity already exists.", submitted: false };
      }
    }

    this.setState(errorState);
  };

  onSubmit = (event: FormEvent) => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      const amenityStore = this.context.amenityStore;
      if (this.state.isNew) {
        amenityStore.add(this.state.wipAmenity, this.onError);
      } else {
        amenityStore.update(this.state.wipAmenity, this.onError);
      }
    });
  };

  delete = () => {
    this.setState({ submitted: true }, () => {
      this.context.amenityStore.remove(this.state.wipAmenity.id);
    });
  };

  onAmenityChange = (event: any) => {
    const wip = this.state.wipAmenity;
    wip.amenity = event.target.value;
    this.setState({ wipAmenity: wip });
  };

  onQuantityChange = (event: any) => {
    const wip = this.state.wipAmenity;
    wip.quantity = event.target.value;
    this.setState({ wipAmenity: wip });
  };

  onPrivateDetailsChange = (event: any) => {
    const wip = this.state.wipAmenity;
    wip.private_details = event.target.value;
    this.setState({ wipAmenity: wip });
  };

  render() {
    const amenity = this.state.wipAmenity;
    const { submitted, isNew } = this.state;

    const errorAlert = this.state.error ? (
      <Alert severity={"error"}>{this.state.error}</Alert>
    ) : (
      ""
    );

    return amenity === undefined ? (
      <div />
    ) : isNew ? (
      <Dialog open={this.props.show}>
        <form onSubmit={this.onSubmit} autoComplete={"off"}>
          <DialogTitle>Add Amenity</DialogTitle>
          <DialogContent>
            <FormGroup>
              <FormControl>
                <InputLabel htmlFor={"amenitySelect"}>Amenity</InputLabel>
                <Select
                  onChange={this.onAmenityChange}
                  value={amenity.amenity}
                  fullWidth
                  inputProps={{ id: "amenitySelect" }}
                >
                  {this.context.amenityStore.amenityList?.map(
                    (amenity: IAmenity) => (
                      <MenuItem value={amenity.name} key={amenity.name}>
                        {amenity.display_name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <TextField
                label={"Private Details"}
                fullWidth
                style={{ width: "100vw", maxWidth: 400, margin: "1em 0" }}
                defaultValue={amenity.private_details}
                onChange={this.onPrivateDetailsChange}
                multiline
                rows={2}
              />
            </FormGroup>

            {errorAlert}
          </DialogContent>

          <DialogActions style={{ marginTop: "1em" }}>
            <Button onClick={this.onClose} disabled={submitted}>
              Cancel
            </Button>
            <Button
              type={"submit"}
              variant={"contained"}
              color={"primary"}
              disabled={submitted}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ) : (
      <Dialog open={this.props.show}>
        <form onSubmit={this.onSubmit} autoComplete={"off"}>
          <DialogTitle>Edit {amenity.display_name}</DialogTitle>
          <DialogContent>
            <FormGroup>
              <FormControl style={{ margin: "1em 0" }}>
                <InputLabel>Quantity</InputLabel>
                <Input
                  value={amenity.quantity}
                  onChange={this.onQuantityChange}
                />
              </FormControl>

              <TextField
                label={"Private Details"}
                fullWidth
                style={{ width: "100vw", maxWidth: 400, margin: "1em 0" }}
                defaultValue={amenity.private_details}
                onChange={this.onPrivateDetailsChange}
                multiline
                rows={2}
              />
            </FormGroup>

            {errorAlert}
          </DialogContent>

          <DialogActions style={{ marginTop: "1em" }}>
            <IconButton onClick={this.delete}>
              <Icon fontSize={"small"}>delete</Icon>
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <Button onClick={this.onClose} disabled={submitted}>
              Cancel
            </Button>
            <Button
              type={"submit"}
              variant={"contained"}
              color={"primary"}
              disabled={submitted}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default EditAmenityFormDialog;
