import { HttpClient } from "../HttpClient";
import { IListing } from "../homes/models";

export default class ListingHttpClient {
  static canPublish = (): Promise<boolean> => {
    return HttpClient.get("listing/can_publish/");
  };

  static publish = (): Promise<IListing> => {
    return HttpClient.get("listing/publish/");
  };
}
