import React from "react";
import { useTripStore } from "../../hooks";
import { useHistory } from "react-router-dom";
import { Section, SectionProps } from "../../base/Section";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import { Typography } from "@material-ui/core";
import Gallery from "react-photo-gallery";
import { humanizeDateRange } from "../../../formattingService";
import { IPicture } from "../../../storeroom/albums/models";

function galleryPhoto(picture: IPicture) {
  return {
    src: picture.url,
    width: picture.width,
    height: picture.height,
  };
}

export default function AcceptedReservationSection() {
  const history = useHistory();
  const trip = useTripStore().trip;
  const reservation = trip.accepted_reservation;

  const sectionProps: SectionProps =
    reservation === null
      ? {
          header: "Accepted Reservation",
        }
      : {
          header: "Accepted Reservation",
          actionIcon: "launch",
          actionLabel: "Details",
          onActionClick: () =>
            history.push(privateRoutePaths.reservationDetails(reservation.id), {
              tripID: trip.id,
              tripLabel: humanizeDateRange(trip.start_date, trip.end_date),
            }),
          secondaryColor: true,
        };

  return (
    <Section {...sectionProps}>
      {reservation === null ? (
        <Typography variant={"h6"}>No Accepted Requests</Typography>
      ) : (
        <div>
          <Typography variant={"h6"}>{reservation.address}</Typography>
          <Gallery photos={reservation.album.map(galleryPhoto)} />
        </div>
      )}
    </Section>
  );
}
