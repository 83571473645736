import { observable } from "mobx";
import PetsHttpClient from "../../PetsHttpClient";
import { IPet } from "../../models";
import AlbumsHttpClient from "../../../albums/AlbumsHttpClient";
import IBasePetBuilderStepStore from "./IBasePetBuilderStepStore";

export default class PetBuilderFinishStore implements IBasePetBuilderStepStore {
  @observable petCreationProgress: number = null;
  @observable imageUploadProgress: number = null;
  @observable canSubmit: boolean = false;

  readonly home: number;
  readonly user: number;
  readonly name: string;
  readonly type: string;
  readonly breed: string;
  readonly description: string;
  readonly pictures: Array<File>;

  private _pet: IPet;

  constructor(
    home: number,
    user: number,
    name: string,
    type: string,
    breed: string,
    description: string,
    pictures: Array<File>
  ) {
    this.home = home;
    this.user = user;
    this.name = name;
    this.type = type;
    this.breed = breed;
    this.description = description;
    this.pictures = [...pictures];
  }

  getPet = () => this._pet;

  submit = () => {
    this.createPet().then((pet: IPet) => {
      this.petCreationProgress = 100;
      this.uploadPictures(pet, 0);
      this._pet = pet;
    });
  };

  private createPet = (): Promise<IPet> => {
    this.petCreationProgress = 0;

    return PetsHttpClient.create({
      id: null,
      album: null,
      name: this.name,
      type: this.type,
      description: this.description,
      home: this.home,
      breed: this.breed,
      care: null,
    });
  };

  private uploadPictures = (pet: IPet, index: number) => {
    this.imageUploadProgress = 100 * (index / this.pictures.length);

    if (this.imageUploadProgress <= 99) {
      AlbumsHttpClient.addPicture(
        this.pictures[index],
        `${pet.album}`,
        `${this.user}`
      )
        .then(() => this.uploadPictures(pet, index + 1))
        .catch(() => this.uploadPictures(pet, index + 1));
    } else {
      this.canSubmit = true;
    }
  };
}
