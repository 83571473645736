import React from "react";
import { observer } from "mobx-react";
import { StoreContext } from "../../storeroom/StoreContext";
import LoadingSection from "../base/LoadingSection";
import OwnerPageToolbar from "./toolbars/OwnerPageToolbar";
import OwnerDescriptionSection from "./sections/OwnerDescriptionSection";
import AlbumSection from "../albums/AlbumSection";

@observer
class OwnerPage extends React.Component<any, any> {
  static contextType = StoreContext;

  componentDidMount(): void {
    this.context.ownerStore.load();
    this.context.profileStore.load();
  }

  onAlbumChange = () => {
    this.context.profileStore.loadCoverPhoto();
  };

  render() {
    const owner = this.context.ownerStore.owner;
    const profile = this.context.profileStore.profile;

    return Boolean(owner) ? (
      <div>
        <OwnerPageToolbar />
        <OwnerDescriptionSection />

        {profile ? (
          <AlbumSection
            album={profile.album}
            headerOverride={"My Pictures"}
            prevURL={"/owner"}
            onAlbumChange={this.onAlbumChange}
          />
        ) : (
          <LoadingSection header={"My Pictures"} cards={true} />
        )}
      </div>
    ) : (
      <div>
        <OwnerPageToolbar />
        <LoadingSection header={"About Me"} />
      </div>
    );
  }
}

export default OwnerPage;
