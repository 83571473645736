import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function SitterDescriptionForm(props: Props) {
  const store = useContext(StoreContext);
  const sitter = store.sitterStore.sitter;

  const [pending, setPending] = useState(false);
  const [description, setDescription] = useState<string>(
    sitter.sitter_description
  );

  const onChange = (event: any) => {
    setDescription(event.target.value);
  };

  const onSubmit = () => {
    setPending(true);

    const updatedSitter = JSON.parse(JSON.stringify(sitter));
    updatedSitter.sitter_description = description;

    store.sitterStore
      .update(updatedSitter)
      .then(() => props.onClose())
      .catch(() => setPending(false));
  };

  return useObserver(() => (
    <Dialog open={props.show} maxWidth={"md"} fullWidth={true}>
      <form onSubmit={onSubmit} autoComplete={"off"}>
        <DialogTitle>About Me</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Describe yourself. Include any information to help the owner feel
            confident in your abilities like pets you own or expertise in caring
            for certain pets.
          </DialogContentText>

          <div style={{ height: "2em" }} />

          <TextField
            label={"Description"}
            fullWidth
            defaultValue={description}
            onChange={onChange}
            multiline
            rows={18}
          />
        </DialogContent>

        <DialogActions style={{ marginTop: "1em" }}>
          <Button onClick={props.onClose} disabled={pending}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            variant={"contained"}
            color={"secondary"}
            disabled={pending}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ));
}
