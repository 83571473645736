import { observable } from "mobx";
import RegisterHttpClient from "./RegisterHttpClient";

export default class RegistrationStore {
  @observable email: string;
  @observable phone: string;
  @observable password: string;
  @observable firstName: string;
  @observable lastName: string;

  setEmail = (email: string) => {
    this.email = email;
  };

  setPhone = (phone: string) => {
    this.phone = phone;
  };

  setPassword = (password: string) => {
    this.password = password;
  };

  setFirstName = (name: string) => {
    this.firstName = name;
  };

  setLastName = (name: string) => {
    this.lastName = name;
  };

  submitRegistration = () => {
    return RegisterHttpClient.register({
      email: this.email,
      phone: this.phone,
      password: this.password,
      first_name: this.firstName,
      last_name: this.lastName,
    });
  };
}
