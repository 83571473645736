import React, { FormEvent } from "react";
import { StoreContext } from "../../storeroom/StoreContext";
import DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import { observer } from "mobx-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import "react-day-picker/lib/style.css";
import "../listings/listing.css";
import moment from "moment";
import { IVacancy } from "../../storeroom/homes/models";

interface Props {
  listingID: number;
  show: boolean;
  onClose: (newVacancy: IVacancy) => void;
}

interface State {
  error: string;
  submitted: boolean;
  disposer: any;
  range: RangeModifier;
}

@observer
class VacancyAddForm extends React.Component<Props, State> {
  static contextType = StoreContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      error: "",
      submitted: false,
      disposer: undefined,
      range: { from: undefined, to: undefined },
    };
  }

  initialize = () => {
    this.setState({ range: { from: undefined, to: undefined } });
  };

  componentDidMount(): void {
    this.initialize();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!prevProps.show && this.props.show) {
      this.initialize();
    }
  }

  componentWillUnmount(): void {
    if (this.state.disposer !== undefined) {
      this.state.disposer();
    }
  }

  onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const startDate = moment(this.state.range.from).format("YYYY-MM-DD");
    const endDate = moment(this.state.range.to).format("YYYY-MM-DD");

    this.setState({ submitted: true }, () => {
      this.context.vacancyStore
        .add({
          id: null,
          start_date: startDate,
          end_date: endDate,
          full_rental_only: true,
          listing: this.props.listingID,
          is_reserved: false,
          reservations: [],
          accepted_reservation: null,
          current_requests: [],
          past_requests: [],
        })
        .then(this.onClose)
        .catch((error: any) => {
          alert(error.response.data.non_field_errors[0]);
          this.onClose(null);
        });
    });
  };

  onClose = (vacancy: null | IVacancy) => {
    this.setState({ submitted: false }, () => {
      this.props.onClose(vacancy);
    });
  };

  onDayClick = (day: any) => {
    const range = DateUtils.addDayToRange(day, this.state.range);
    this.setState({ range: range });
  };

  render() {
    const { submitted } = this.state;
    const { from, to } = this.state.range;
    const modifiers = { start: from, end: to };

    return (
      <Dialog open={this.props.show} maxWidth={"md"} fullWidth={true}>
        <form onSubmit={this.onSubmit} autoComplete={"off"}>
          <DialogTitle>Add Vacancy</DialogTitle>
          <DialogContent style={{ display: "flex", justifyContent: "center" }}>
            <DayPicker
              className={"Selectable"}
              numberOfMonths={2}
              selectedDays={[from, { from, to }]}
              onDayClick={this.onDayClick}
              modifiers={modifiers}
            />
          </DialogContent>

          <DialogActions style={{ marginTop: "1em" }}>
            <Button onClick={() => this.onClose(null)} disabled={submitted}>
              Cancel
            </Button>
            <Button
              type={"submit"}
              variant={"contained"}
              color={"secondary"}
              disabled={submitted}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default VacancyAddForm;
