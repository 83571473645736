import React from "react";
import { useConversationListStore, useDocumentTitle } from "../hooks";
import { useObserver } from "mobx-react";
import ListLoadingSection from "./sections/ListLoadingSection";
import ConversationListSection from "./sections/ConversationListSection";
import PageToolbar from "../base/PageToolbar";
import { ICrumbProps } from "../base/Crumbs";

export default function ConversationListPage() {
  const conversationListStore = useConversationListStore();

  useDocumentTitle("Conversations");
  const crumbs: ICrumbProps = { currentLabel: "Conversations" };

  return useObserver(() => {
    const { loading } = conversationListStore;
    const mainSection = loading ? (
      <ListLoadingSection />
    ) : (
      <ConversationListSection />
    );

    return (
      <div>
        <PageToolbar crumbs={crumbs} actions={[]} />
        {mainSection}
      </div>
    );
  });
}
