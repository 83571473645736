import { ITrip } from "./models";
import { observable } from "mobx";
import { deepCopy } from "../../utils";
import moment from "moment";
import TripsHttpClient from "./TripsHttpClient";

export default class TripStore {
  private _trip: ITrip;

  @observable loading = false;
  @observable dirty = false;
  @observable trip: ITrip;

  constructor(trip?: ITrip) {
    if (Boolean(trip)) {
      this.postLoad(trip);
    } else {
      this.initializeNew();
    }
  }

  private postLoad = (trip: ITrip) => {
    this._trip = deepCopy(trip);
    this.trip = deepCopy(trip);
    this.loading = false;
    this.dirty = false;
  };

  private initializeNew = () => {
    const today = moment().add(1, "days");
    const end = moment().add(3, "days");

    this.postLoad({
      id: null,
      location: "",
      start_date: today.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      sitter: null,
    });
  };

  private postUpdate = () => {
    this.dirty = true;
  };

  setDestination = (destination: string) => {
    this.trip.location = destination;
    this.postUpdate();
  };

  setStartDate = (startDate: string) => {
    this.trip.start_date = startDate;
    this.postUpdate();
  };

  setEndDate = (endDate: string) => {
    this.trip.end_date = endDate;
    this.postUpdate();
  };

  save = (): Promise<void> => {
    if (this.trip.id === null) {
      return TripsHttpClient.create(this.trip).then(this.postLoad);
    } else {
      return TripsHttpClient.update(this.trip).then(this.postLoad);
    }
  };
}
