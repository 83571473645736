import { action, observable } from "mobx";
import { IConversationListItem } from "./models";
import ConversationHttpClient from "./ConversationHttpClient";

export default class ConversationListStore {
  @observable conversations: Array<IConversationListItem> = null;
  @observable loading: boolean = false;

  constructor() {
    this.load();
  }

  @action setList = (newList: Array<IConversationListItem>) => {
    this.loading = false;
    this.conversations = newList;
  };

  load = () => {
    this.loading = true;
    ConversationHttpClient.list().then(this.setList);
  };

  updateUnreadFlag = (conversationID: number) => {
    this.conversations = this.conversations.map(
      (conversation: IConversationListItem) => {
        if (conversation.id === conversationID) {
          conversation.unread = false;
        }

        return conversation;
      }
    );
  };
}
