import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles, Theme } from "@material-ui/core";
import logo from "../../../jetsyt-logo-orange.png";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "fit-content",
      maxWidth: 400,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
    },
    logo: {
      width: "50%",
      marginBottom: "5em",
    },
  })
);

export default function MaintenanceModePage() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={logo} alt={"logo"} className={classes.logo} />

      <Typography variant={"h6"} style={{ textAlign: "center" }}>
        We're down for scheduled maintenance. Sorry for any inconvenience.
      </Typography>
    </div>
  );
}
