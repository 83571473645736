import React, { useState } from "react";
import { useHomeStore } from "../../hooks";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { useObserver } from "mobx-react";
import HomeRulesModal from "../forms/HomeRulesModal";
import { Typography } from "@material-ui/core";

export default function HomeRulesSection() {
  const home = useHomeStore().home;
  const [showForm, setShowForm] = useState(false);

  const sectionProps: SectionProps = {
    header: "Home Rules",
    actionIcon: "edit",
    actionLabel: "Edit Rules",
    onActionClick: () => setShowForm(true),
  };

  return useObserver(() => {
    return (
      <Section {...sectionProps}>
        <HomeRulesModal show={showForm} onClose={() => setShowForm(false)} />
        <Typography style={sectionBodyStyle}>{home.home_rules}</Typography>
      </Section>
    );
  });
}
