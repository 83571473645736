import React, { FormEvent, useEffect, useState } from "react";
import { usePetStore } from "../../hooks";
import { TextFieldChangeEvent } from "../../models";
import { IPet } from "../../../storeroom/pets/models";
import { deepCopy } from "../../../utils";
import Modal from "../../misc/Modal";
import { Alert } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export interface IPetDescriptionModalProps {
  show: boolean;
  onClose: () => void;
}

export default function PetDescriptionModal(props: IPetDescriptionModalProps) {
  const petStore = usePetStore();
  const pet = petStore.pet;

  const [description, setDescription] = useState(pet?.description);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.show) {
      setSubmitted(false);
      setError("");
      setDescription(pet?.description);
    }
  }, [props.show, pet]);

  const onDescriptionChange = (event: TextFieldChangeEvent) => {
    setDescription(event.target.value);
  };

  const onError = () => {
    setError("Description could not be saved.");
    setSubmitted(false);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const updatedPet: IPet = deepCopy(pet);
    updatedPet.description = description;

    setSubmitted(true);
    petStore.submitUpdates(updatedPet).then(props.onClose).catch(onError);
  };

  return pet ? (
    <Modal
      show={props.show}
      maxWidth={"sm"}
      onFormSubmit={onSubmit}
      headerChildren={<strong>Description</strong>}
      contentChildren={
        <React.Fragment>
          {error && (
            <Alert severity={"error"} style={{ marginBottom: "1rem" }}>
              {error}
            </Alert>
          )}

          <TextField
            label={"Description"}
            fullWidth
            value={description}
            onChange={onDescriptionChange}
            multiline
            rows={18}
            autoFocus={true}
          />
        </React.Fragment>
      }
      footerChildren={
        <React.Fragment>
          <Button onClick={props.onClose} disabled={submitted}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            variant={"contained"}
            color={"primary"}
            disabled={submitted}
          >
            Submit
          </Button>
        </React.Fragment>
      }
    />
  ) : null;
}
