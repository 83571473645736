import React, { useContext } from "react";
import { IVacancySearchResultDetails } from "../../storeroom/homes/searchService/models";

const VacancySearchResultContext = React.createContext<IVacancySearchResultDetails>(
  null
);
const VacancySearchResultProvider = VacancySearchResultContext.Provider;

function useVacancySearchResult(): IVacancySearchResultDetails {
  return useContext(VacancySearchResultContext);
}

export { VacancySearchResultProvider, useVacancySearchResult };
