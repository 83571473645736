import { observable } from "mobx";
import { IAlbum, IGalleryItem, IPicture } from "./models";
import AlbumsHttpClient from "./AlbumsHttpClient";

export default class AlbumGalleryStore {
  @observable loading: boolean = true;
  pictures: Array<IGalleryItem> = [];

  constructor(albumID: number) {
    AlbumsHttpClient.preview(albumID).then(this.postLoad);
  }

  postLoad = (album: IAlbum) => {
    this.pictures = album.pictures.map(
      (picture: IPicture): IGalleryItem => {
        return {
          src: picture.url,
          width: picture.width,
          height: picture.height,
        };
      }
    );
    this.loading = false;
  };
}
