import React, { useState } from "react";
import VacancyStore from "../../../storeroom/vacancies/VacancyStore";
import Modal from "../../misc/Modal";
import { useObserver } from "mobx-react";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { CheckboxChangeEvent, TextFieldChangeEvent } from "../../models";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Alert } from "@material-ui/lab";

export interface IVacancyEditModalProps {
  store: VacancyStore;
  show: boolean;
  onClose: (vacancyID: number) => void;
}

interface IActionButtonProps extends IVacancyEditModalProps {
  onError: (error: string) => void;
}

export default function VacancyEditModal(props: IVacancyEditModalProps) {
  const { store, show } = props;
  const [error, setError] = useState("");

  const isNew = !Boolean(store.vacancy.id);
  const modalTitle = isNew ? (
    <strong>Add Vacancy</strong>
  ) : (
    <strong>Edit Vacancy</strong>
  );

  const onError = (error: string) => {
    setError(error);
  };

  return useObserver(() => {
    return (
      <Modal
        show={show}
        maxWidth={"xs"}
        headerChildren={modalTitle}
        contentChildren={
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Alert severity={"error"}>{error}</Alert>
              </Grid>
            )}

            <StartDateField {...props} />
            <EndDateField {...props} />
            <FullRentalField {...props} />
          </Grid>
        }
        footerChildren={
          <React.Fragment>
            <CancelButton {...props} />
            <SubmitButton {...props} onError={onError} />
          </React.Fragment>
        }
      />
    );
  });
}

function CancelButton(props: IVacancyEditModalProps) {
  return useObserver(() => {
    const loading = props.store.loading;

    return (
      <Button
        onClick={() => props.onClose(null)}
        disabled={loading}
        size={"small"}
      >
        Cancel
      </Button>
    );
  });
}

function SubmitButton(props: IActionButtonProps) {
  const onClick = () => {
    props.store
      .save()
      .then(() => props.onClose(props.store.vacancy.id))
      .catch((error) => props.onError(error.response.data.non_field_errors[0]));
  };

  return useObserver(() => {
    const loading = props.store.loading;

    return (
      <Button
        variant={"contained"}
        color={"primary"}
        disabled={loading}
        size={"small"}
        onClick={onClick}
      >
        Submit
      </Button>
    );
  });
}

function StartDateField(props: IVacancyEditModalProps) {
  const onChange = (event: TextFieldChangeEvent) => {
    props.store.setStartDate(event.target.value);
  };

  return useObserver(() => {
    const store = props.store;
    const startDate = store.vacancy.start_date;

    return (
      <Grid item xs={6}>
        <TextField
          label={"Start"}
          type={"date"}
          value={startDate}
          onChange={onChange}
          autoFocus={true}
          fullWidth={true}
          color={"primary"}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  });
}

function EndDateField(props: IVacancyEditModalProps) {
  const onChange = (event: TextFieldChangeEvent) => {
    props.store.setEndDate(event.target.value);
  };

  return useObserver(() => {
    const store = props.store;
    const endDate = store.vacancy.end_date;

    return (
      <Grid item xs={6}>
        <TextField
          label={"End"}
          type={"date"}
          value={endDate}
          onChange={onChange}
          fullWidth={true}
          color={"primary"}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  });
}

function FullRentalField(props: IVacancyEditModalProps) {
  const onChange = (event: CheckboxChangeEvent) => {
    props.store.setFullRental(event.target.checked);
  };

  return useObserver(() => {
    const store = props.store;
    const fullRental = store.vacancy.full_rental_only;

    return (
      <Grid item xs={12}>
        <FormControlLabel
          label={"Only allow sitters that can commit to the full stay"}
          control={
            <Checkbox
              size={"small"}
              checked={fullRental}
              onChange={onChange}
              color={"primary"}
            />
          }
        />
      </Grid>
    );
  });
}
