import React from "react";
import { ElevationAppBar } from "../../base/navbar/ElevationAppBar";
import { createStyles, Theme, Toolbar } from "@material-ui/core";
import logo from "../../../jetsyt-logo-orange.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { publicRoutePaths } from "../../../routes/PublicRoutes";
import { useMobile } from "../../hooks";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeLink: {
      marginRight: "2em",
      cursor: "pointer",
      outline: "none",
      border: "none",
    },
  })
);

interface IMenuButtonProps {
  label: string;
  url: string;
  highlight?: boolean;
}

function MenuButton(props: IMenuButtonProps) {
  const history = useHistory();
  const isNarrow = useMobile();

  const buttonStyle: React.CSSProperties = {
    marginLeft: "0.5rem",
  };

  if (!isNarrow) {
    buttonStyle.marginLeft = "1rem";
    buttonStyle.fontSize = "1rem";
  }

  return (
    <Button
      onClick={() => history.push(props.url)}
      style={buttonStyle}
      color={props.highlight ? "secondary" : "default"}
      variant={props.highlight ? "contained" : "text"}
    >
      {props.label}
    </Button>
  );
}

function PublicMenu() {
  const history = useHistory();
  const classes = useStyles();
  const isNarrow = useMobile();

  const logoStyle: React.CSSProperties = {
    height: isNarrow ? "2rem" : "3rem",
  };

  const onLogoClick = () => {
    history.push(publicRoutePaths.front());
  };

  return (
    <React.Fragment>
      <img
        src={logo}
        alt={"logo"}
        className={classes.homeLink}
        style={logoStyle}
        onClick={onLogoClick}
      />

      <div style={{ flexGrow: 1 }} />

      <MenuButton label={"About"} url={publicRoutePaths.about()} />
      <MenuButton label={"Price"} url={"/prices"} />
      <MenuButton label={"Log In"} url={"/login"} />
      <MenuButton label={"Sign Up"} url={"/register"} highlight={true} />
    </React.Fragment>
  );
}

export default function PublicMenuBar() {
  const isNarrow = useMobile();

  const toolbarStyle: React.CSSProperties = isNarrow
    ? null
    : {
        minHeight: "4.5rem",
      };

  return (
    <ElevationAppBar>
      <Toolbar style={toolbarStyle}>
        <PublicMenu />
      </Toolbar>
    </ElevationAppBar>
  );
}
