import React from "react";
import {
  IReservationForGuest,
  IReservationForHost,
} from "../../storeroom/reservations/models";

export const GuestReservationContext = React.createContext<IReservationForGuest>(
  null
);
export const HostReservationContext = React.createContext<IReservationForHost>(
  null
);
