import React, { useContext } from "react";
import GuestReservationToolbar from "./toolbars/GuestReservationToolbar";
import { GuestReservationContext } from "./contexts";
import {
  IReservationForGuest,
  ReservationStatus,
} from "../../storeroom/reservations/models";
import { useReservationStore } from "../hooks";
import { Container } from "@material-ui/core";
import {
  ApprovedBannerForGuest,
  CanceledBanner,
  NeedsHomeReviewBanner,
  RejectedBanner,
} from "./banners/banners";
import ReservationContent from "./guests/ReservationContent";
import ReservationTimeline from "./ReservationTimeline";

export default function ReservationForGuestPage() {
  const reservation: IReservationForGuest = useReservationStore()
    .reservation as IReservationForGuest;

  const hideContent = [ReservationStatus.rejected].includes(reservation.status);

  return (
    <GuestReservationContext.Provider value={reservation}>
      <GuestReservationToolbar />
      <GuestReservationBanner />

      {!hideContent && (
        <div>
          <ReservationTimeline reservation={reservation} secondary={true} />
          <div style={{ height: "2rem" }} />
          <ReservationContent />
        </div>
      )}
    </GuestReservationContext.Provider>
  );
}

function GuestReservationBanner() {
  const reservation: IReservationForGuest = useContext(GuestReservationContext);

  let banner = null;

  switch (reservation.status) {
    case ReservationStatus.rejected:
      banner = <RejectedBanner />;
      break;

    case ReservationStatus.accepted:
      banner = <ApprovedBannerForGuest />;
      break;

    case ReservationStatus.canceled:
      banner = <CanceledBanner />;
      break;

    case ReservationStatus.completed:
      if (reservation.home_review === null) {
        banner = <NeedsHomeReviewBanner />;
        break;
      } else {
        return null;
      }

    default:
      return null;
  }

  return (
    <div>
      <Container maxWidth={"md"}>{banner}</Container>
      <div style={{ height: "2rem" }} />
    </div>
  );
}
