import { action, observable } from "mobx";
import { OwnerPreferences } from "./models";
import { HttpClient } from "../HttpClient";

export default class OwnerPreferencesManager {
  @observable preferences: OwnerPreferences = null;
  @observable loading: boolean = true;

  @action
  private setPreferences = (preferences: OwnerPreferences) => {
    this.preferences = preferences;
    this.loading = false;
  };

  loadPreferences = (): Promise<void> => {
    this.loading = true;
    return HttpClient.get<OwnerPreferences>(`owner/preferences/`).then(
      this.setPreferences
    );
  };

  updatePreferences = (updatedPreferences: OwnerPreferences): Promise<void> => {
    this.loading = true;
    return HttpClient.put<OwnerPreferences>(
      `owner/preferences/`,
      updatedPreferences
    ).then(this.setPreferences);
  };
}
