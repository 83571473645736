import React, { useEffect, useState } from "react";
import BasicInfoSection from "./sections/BasicInfoSection";
import { useObserver } from "mobx-react";
import BillingSection from "./sections/BillingSection";
import { useDocumentTitle, useProfileStore } from "../../hooks";
import { ICrumbProps } from "../../base/Crumbs";
import PageToolbar from "../../base/PageToolbar";
import RegisterHttpClient from "../../../storeroom/users/registration/RegisterHttpClient";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import { Alert } from "@material-ui/lab";
import { Container } from "@material-ui/core";

const ProfileToolbar = () => {
  const profileStore = useProfileStore();

  const [pending, setPending] = useState(false);

  const onSuccess = () => {
    window.location.reload();
    setPending(false);
  };

  const onFail = () => {
    alert("Registration failed");
    setPending(false);
  };

  const onSitterClick = () => {
    setPending(true);
    RegisterHttpClient.registerAsSitter().then(onSuccess).catch(onFail);
  };

  const onOwnerClick = () => {
    setPending(true);
    RegisterHttpClient.registerAsOwner().then(onSuccess).catch(onFail);
  };

  const crumbs: ICrumbProps = { currentLabel: "Account" };
  const actions: Array<IPageToolbarAction> = [];

  if (Boolean(profileStore.profile)) {
    if (!Boolean(profileStore.profile.sitter)) {
      actions.push({
        label: pending ? "Registration Pending..." : "Register as Sitter",
        onClick: onSitterClick,
        icon: null,
        disabled: pending,
      });
    }

    if (!Boolean(profileStore.profile.owner)) {
      actions.push({
        label: pending ? "Registration Pending..." : "Register as Owner",
        onClick: onOwnerClick,
        icon: null,
        disabled: pending,
      });
    }
  }

  return <PageToolbar crumbs={crumbs} actions={actions} />;
};

const RegisterAsBanner = () => {
  return (
    <Container maxWidth={"md"}>
      <Alert severity={"info"}>
        Hi! To get started, activate your sitter and/or owner account using the
        register actions above!
      </Alert>
    </Container>
  );
};

export default function MyProfilePage() {
  const profileStore = useProfileStore();
  useDocumentTitle("Account");

  useEffect(() => {
    profileStore.load();
  });

  return useObserver(() => {
    const profile = profileStore.profile;
    const missingRole = !Boolean(profile?.owner) && !Boolean(profile?.sitter);

    return profile === undefined ? null : (
      <div>
        <ProfileToolbar />
        {missingRole && <RegisterAsBanner />}
        <BasicInfoSection />
        {Boolean(profile.owner) && <BillingSection />}
      </div>
    );
  });
}
