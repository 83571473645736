import React, { useContext } from "react";
import {
  IReservationBase,
  IReservationForGuest,
  IReservationForHost,
  ReservationStatus,
} from "../../storeroom/reservations/models";
import { StepIconProps, Stepper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Container from "@material-ui/core/Container";
import Icon from "@material-ui/core/Icon";

const PaletteContext = React.createContext<"primary" | "secondary">("primary");

export interface IReservationTimelineProps {
  reservation: IReservationBase;
  secondary?: boolean;
}

function StepIcon(props: StepIconProps) {
  const themeColor = useContext(PaletteContext);

  return props.active || props.completed ? (
    <Icon color={themeColor}>check_circle</Icon>
  ) : (
    <Icon color={"disabled"}>radio_button_unchecked</Icon>
  );
}

export default function ReservationTimeline(props: IReservationTimelineProps) {
  const activeStep = getStepNumber(props);

  return (
    <PaletteContext.Provider value={props.secondary ? "secondary" : "primary"}>
      <Container maxWidth={"md"} disableGutters={true}>
        <div style={{ height: "2rem" }} />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ padding: 0 }}
        >
          <Step>
            <StepLabel StepIconComponent={StepIcon}>Requested</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={StepIcon}>In Review</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={StepIcon}>Accepted</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={StepIcon}>Checked In</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={StepIcon}>Checked Out</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={StepIcon}>Reviewed</StepLabel>
          </Step>
        </Stepper>
        <div style={{ height: "2rem" }} />
      </Container>
    </PaletteContext.Provider>
  );
}

const getStepNumber = (props: IReservationTimelineProps): number => {
  switch (props.reservation.status) {
    case ReservationStatus.requested:
      return 0;

    case ReservationStatus.in_review:
      return 1;

    case ReservationStatus.accepted:
      return 2;

    case ReservationStatus.started:
      return 3;

    case ReservationStatus.completed:
      if (props.secondary) {
        const guestReservation = props.reservation as IReservationForGuest;
        return guestReservation.home_review === null ? 4 : 5;
      } else {
        const hostReservation = props.reservation as IReservationForHost;
        return hostReservation.sitter_review === null ? 4 : 5;
      }

    default:
      return null;
  }
};
