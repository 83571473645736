import React, { useContext } from "react";
import {
  createStyles,
  DialogActions,
  Theme,
  Container,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AlbumStoreContext } from "../../../../storeroom/albums/AlbumStore";
import { useObserver } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      padding: "8px 0 1rem 0",
      position: "static",
      bottom: 0,
    },
  })
);

export interface IAlbumModalFooterProps {
  onCancel: () => void;
  onSave: () => void;
  secondary?: boolean;
}

export default function AlbumModalFooter(props: IAlbumModalFooterProps) {
  const store = useContext(AlbumStoreContext);
  const styles = useStyles();

  const onSave = () => {
    props.onSave();
  };

  const onReset = () => {
    store.reset();
  };

  return useObserver(() => (
    <Container maxWidth={"md"}>
      <DialogActions className={styles.dialogActions}>
        <Button onClick={onReset} disabled={!store.modified}>
          Reset
        </Button>

        <div style={{ flex: 1 }} />

        <Button onClick={props.onCancel}>Cancel</Button>

        <Button
          color={props.secondary ? "secondary" : "primary"}
          variant={"contained"}
          onClick={onSave}
          disabled={!store.modified || store.album.pictures.length === 0}
        >
          Save all changes
        </Button>
      </DialogActions>
    </Container>
  ));
}
