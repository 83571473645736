import { action, observable } from "mobx";
import {
  SitterSearchParams,
  SitterSearchResultDetails,
  SitterSearchResults,
} from "./models";
import { HttpClient } from "../HttpClient";
import moment from "moment";
import { deepCopy } from "../../utils";

export class SitterSearchManager {
  @observable searchParams: SitterSearchParams = null;
  @observable searchResults: SitterSearchResults = null;
  @observable searching: boolean = false;
  @observable searchResultDetails: SitterSearchResultDetails = null;

  constructor() {
    this.searchParams = {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: "",
    };
  }

  @action
  setStartDate(newDate: string) {
    this.searchParams.start_date = newDate;
  }

  @action
  setEndDate(newDate: string) {
    this.searchParams.end_date = newDate;
  }

  @action
  setSearchResults = (newResults: SitterSearchResults) => {
    this.searchResults = newResults;
    this.searching = false;
  };

  @action
  setSearchResultDetails = (newDetails: SitterSearchResultDetails) => {
    this.searchResultDetails = newDetails;
  };

  search = (): Promise<void> => {
    this.searching = true;
    return HttpClient.post<SitterSearchResults>(
      "sitters/search/run_search/",
      this.searchParams
    ).then(this.setSearchResults);
  };

  setPage = (newPage: number) => {
    const currentResults: SitterSearchResults = deepCopy(this.searchResults);
    currentResults.page_number = newPage;

    return HttpClient.post<SitterSearchResults>(
      "sitters/search/update_page_results/",
      currentResults
    ).then(this.setSearchResults);
  };

  loadDetails = (id: number) => {
    return HttpClient.get<SitterSearchResultDetails>(
      `sitters/search/${id}/`
    ).then(this.setSearchResultDetails);
  };
}
