import React, { useState } from "react";
import { useProfileStore } from "../../hooks";
import { useObserver } from "mobx-react";
import { AppBar, Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import RequiredTasksModal from "./RequiredTasksModal";

export default function SignUpBanner() {
  const profileStore = useProfileStore();
  const [showForm, setShowForm] = useState(false);

  const openForm = () => {
    setShowForm(true);
  };

  const hideForm = () => {
    setShowForm(false);
  };

  return useObserver(() => {
    const profile = profileStore.profile;

    return !Boolean(profile) || !profile.setup_needed ? null : (
      <AppBar position={"sticky"} color={"secondary"} style={{ zIndex: 10000 }}>
        <Container maxWidth={"md"} disableGutters={true}>
          <Toolbar style={{ height: "8rem" }}>
            <Typography variant={"h6"}>
              Uh Oh! Looks like you have some required tasks to complete before
              you can use the core features of Jetsyt
            </Typography>
            <div style={{ flexGrow: 1, marginLeft: "1rem" }} />
            <Button variant={"outlined"} color={"inherit"} onClick={openForm}>
              View Tasks
            </Button>
            <RequiredTasksModal show={showForm} onClose={hideForm} />
          </Toolbar>
        </Container>
      </AppBar>
    );
  });
}
