import React, { useContext } from "react";
import { PetBuilderContext } from "../../../storeroom/pets/builder/PetBuilderStore";
import { useObserver } from "mobx-react";
import { Stepper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

export default function PetBuilderStepper() {
  const store = useContext(PetBuilderContext);

  return useObserver(() => (
    <Stepper
      activeStep={store.currentStep}
      orientation={"vertical"}
      style={{
        padding: "12px 0",
        wordBreak: "break-word",
        position: "sticky",
        top: 0,
      }}
    >
      <Step>
        <StepLabel>Basic Information</StepLabel>
      </Step>

      <Step>
        <StepLabel>Description</StepLabel>
      </Step>

      <Step>
        <StepLabel>Pictures</StepLabel>
      </Step>
    </Stepper>
  ));
}
