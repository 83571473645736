import React from "react";
import {
  IPetBuilderSteps,
  IPetBuilderStepStores,
  PetBuilderStep,
} from "./models";
import { observable } from "mobx";
import PetBasicInfoStepStore from "./steps/PetBasicInfoStepStore";
import PetDescStepStore from "./steps/PetDescStepStore";
import PetAlbumStepStore from "./steps/PetAlbumStepStore";
import IBasePetBuilderStepStore from "./steps/IBasePetBuilderStepStore";
import PetBuilderFinishStore from "./steps/PetBuilderFinishStore";

export const PetBuilderSteps: IPetBuilderSteps = {
  BASIC_INFO: 0,
  DESCRIPTION: 1,
  ALBUM: 2,
  FINISH: 3,
};

export default class PetBuilderStore {
  @observable currentStep: typeof PetBuilderSteps[PetBuilderStep];
  @observable currentStepStore: IBasePetBuilderStepStore = null;

  private readonly _homeID: number;
  private readonly _userID: number;
  private _stepStores: IPetBuilderStepStores;

  constructor(homeID: number, userID: number) {
    this._homeID = homeID;
    this._userID = userID;
    this.reset();
  }

  getBasicInfoStepStore = () => this._stepStores.BASIC_INFO;
  getDescStepStore = () => this._stepStores.DESCRIPTION;
  getAlbumStepStore = () => this._stepStores.ALBUM;
  getFinishStepStore = () => this._stepStores.FINISH;

  isFinalStep = () => this.currentStep === PetBuilderSteps.FINISH;

  goBack = (to: PetBuilderStep) => {
    switch (to) {
      case PetBuilderSteps.BASIC_INFO:
        this.postInitialize();
        break;

      case PetBuilderSteps.DESCRIPTION:
        this.postBasicInfo();
        break;
    }
  };

  reset = () => {
    this._stepStores = {
      BASIC_INFO: new PetBasicInfoStepStore(),
      DESCRIPTION: new PetDescStepStore(),
      ALBUM: new PetAlbumStepStore(),
      FINISH: null,
    };

    this.postInitialize();
  };

  submit = () => {
    switch (this.currentStep) {
      case PetBuilderSteps.BASIC_INFO:
        this._stepStores.BASIC_INFO.submit().then(this.postBasicInfo);
        break;

      case PetBuilderSteps.DESCRIPTION:
        this._stepStores.DESCRIPTION.submit().then(this.postDesc);
        break;

      case PetBuilderSteps.ALBUM:
        this._stepStores.ALBUM.submit().then(this.postAlbum);
    }
  };

  postInitialize = () => {
    this.currentStep = PetBuilderSteps.BASIC_INFO;
    this.currentStepStore = this._stepStores.BASIC_INFO;
  };

  postBasicInfo = () => {
    this.currentStep = PetBuilderSteps.DESCRIPTION;
    this.currentStepStore = this._stepStores.DESCRIPTION;
  };

  postDesc = () => {
    this.currentStep = PetBuilderSteps.ALBUM;
    this.currentStepStore = this._stepStores.ALBUM;
  };

  postAlbum = () => {
    const basicInfoStore = this.getBasicInfoStepStore();
    const descStore = this.getDescStepStore();
    const albumStore = this.getAlbumStepStore();

    this._stepStores.FINISH = new PetBuilderFinishStore(
      this._homeID,
      this._userID,
      basicInfoStore.name,
      basicInfoStore.petType,
      basicInfoStore.breed,
      descStore.description,
      albumStore.pictures
    );

    this.currentStep = PetBuilderSteps.FINISH;
    this.currentStepStore = this._stepStores.FINISH;
  };
}

export const PetBuilderContext = React.createContext<PetBuilderStore>(null);
export const PetBuilderProvider = PetBuilderContext.Provider;
