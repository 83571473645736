import StripeHttpClient from "./StripeHttpClient";

export default class StripeStore {
  private readonly _billingProfileID: number;

  constructor(billingProfileID: number) {
    this._billingProfileID = billingProfileID;
  }

  afterPaymentMethodCreate = (paymentMethodData: any) => {
    return StripeHttpClient.addStripePayment(paymentMethodData.id);
  };
}
