import React from "react";
import { useHistory } from "react-router-dom";
import { ICrumbProps } from "../../base/Crumbs";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar from "../../base/PageToolbar";
import { useDocumentTitle } from "../../hooks";

export default function OwnerPageToolbar() {
  const history = useHistory();

  useDocumentTitle("Parent Profile");

  const crumbProps: ICrumbProps = {
    currentLabel: "Parent Profile",
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "Home",
      icon: "home",
      onClick: () => history.push("/home"),
    },
    {
      label: "Listing",
      icon: "wysiwyg",
      onClick: () => history.push("/listing"),
    },
  ];

  return <PageToolbar crumbs={crumbProps} actions={actions} />;
}
