import React from "react";
import { useVacancySearchResult } from "../../contexts";
import { IVacancySearchResultListing } from "../../../../storeroom/homes/searchService/models";
import { Section, sectionBodyStyle } from "../../../base/Section";
import AlbumGallery from "../../../albums/AlbumGallery";
import { Typography } from "@material-ui/core";

interface ISectionProps {
  listing: IVacancySearchResultListing;
}

export default function ListingPreviewPetsTab() {
  const listing = useVacancySearchResult().listing;

  return (
    <div>
      <ListingPreviewPetCareSection listing={listing} />
      <ListingPreviewPetsSections listing={listing} />
    </div>
  );
}

function ListingPreviewPetCareSection(props: ISectionProps) {
  return (
    <Section header={"Pet Care Routine"}>
      <Typography style={sectionBodyStyle}>
        {props.listing.pet_care_routine}
      </Typography>
    </Section>
  );
}

function ListingPreviewPetsSections(props: ISectionProps) {
  return (
    <div>
      {props.listing.pets.map((pet) => (
        <div key={pet.id}>
          <Section header={pet.name}>
            <AlbumGallery albumID={pet.album} />
            <Typography style={sectionBodyStyle}>{pet.description}</Typography>
          </Section>
        </div>
      ))}
    </div>
  );
}
