import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../storeroom/StoreContext";
import { deepCopy } from "../../../../utils";
import { useObserver } from "mobx-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

interface Props {
  show: boolean;
  onClose: () => void;
}

export default function ListingOtherNotesForm(props: Props) {
  const store = useContext(StoreContext);
  const listing = store.listingStore.listing;

  const [pending, setPending] = useState(false);
  const [notes, setNotes] = useState(listing.other_notes);

  const onChange = (event: any) => {
    setNotes(event.target.value);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    setPending(true);

    const updatedListing = deepCopy(listing);
    updatedListing.other_notes = notes;

    store.listingStore
      .update(updatedListing)
      .then(() => props.onClose())
      .catch(() => setPending(false));
  };

  return useObserver(() => (
    <Dialog open={props.show} maxWidth={"md"} fullWidth={true}>
      <form onSubmit={onSubmit} autoComplete={"off"}>
        <DialogTitle>Other Notes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Additional notes for the sitter. These notes are only visible to a
            sitter after a reservation has been accepted by you.
          </DialogContentText>

          <div style={{ height: "2em" }} />

          <TextField
            label={"Notes"}
            fullWidth
            defaultValue={notes}
            onChange={onChange}
            multiline
            rows={18}
          />
        </DialogContent>

        <DialogActions style={{ marginTop: "1em" }}>
          <Button onClick={props.onClose} disabled={pending}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            variant={"contained"}
            color={"primary"}
            disabled={pending}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ));
}
