import React from "react";
import { useObserver } from "mobx-react";
import Modal from "../../misc/Modal";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useBillingStore, useProfileStore } from "../../hooks";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export default function RequiredTasksModal(props: IProps) {
  const history = useHistory();
  const billingStore = useBillingStore();
  const profileStore = useProfileStore();

  const goToProfilePage = () => {
    history.push("/profile");
    props.onClose();
  };

  return useObserver(() => {
    const billingProfile = billingStore.billingProfile;
    const profile = profileStore.profile;

    const emailVerified = profile?.verification.email_verified;
    const phoneVerified = profile?.verification.phone_number_verified;
    const petParentSubscriptionPaid =
      billingProfile?.pet_parent_subscription_active;

    return (
      <Modal
        show={props.show}
        maxWidth={"sm"}
        headerChildren={<strong>Required Tasks</strong>}
        contentChildren={
          <React.Fragment>
            <List>
              <ListItem button onClick={goToProfilePage}>
                <ListItemIcon>
                  <Icon color={emailVerified ? "secondary" : "default"}>
                    {emailVerified ? "task_alt" : "radio_button_unchecked"}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={"Email Verification"} />
              </ListItem>
              <ListItem button onClick={goToProfilePage}>
                <ListItemIcon>
                  <Icon color={phoneVerified ? "secondary" : "default"}>
                    {phoneVerified ? "task_alt" : "radio_button_unchecked"}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={"Phone Verification"} />
              </ListItem>
              {profile.owner !== null && (
                <ListItem button onClick={goToProfilePage}>
                  <ListItemIcon>
                    <Icon
                      color={
                        petParentSubscriptionPaid ? "secondary" : "default"
                      }
                    >
                      {petParentSubscriptionPaid
                        ? "task_alt"
                        : "radio_button_unchecked"}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary={"Pet Parent Subscription"} />
                </ListItem>
              )}
            </List>
          </React.Fragment>
        }
        footerChildren={
          <React.Fragment>
            <Button onClick={props.onClose}>Close</Button>
          </React.Fragment>
        }
      />
    );
  });
}
