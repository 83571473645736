import { AxiosResponse } from "axios";
import { AuthStore } from "./auth/AuthStore";

const axios = require("axios").default;

axios.defaults.baseURL = process.env.REACT_APP_HOST;

export class HttpClient {
  static headers() {
    return { Authorization: `Bearer ${AuthStore.getAccessToken()}` };
  }

  static instance() {
    return axios.create({ headers: HttpClient.headers() });
  }

  static get<T>(urlPath: string, urlParams = {}): Promise<T> {
    return HttpClient.instance()
      .get(urlPath, { params: urlParams })
      .then((response: AxiosResponse) => response.data);
  }

  static post<T>(urlPath: string, payload: any): Promise<T> {
    return HttpClient.instance()
      .post(urlPath, payload)
      .then((response: AxiosResponse) => response.data);
  }

  static put<T>(urlPath: string, payload: any): Promise<T> {
    return HttpClient.instance()
      .put(urlPath, payload)
      .then((response: AxiosResponse) => response.data);
  }

  static delete(urlPath: string): Promise<any> {
    return HttpClient.instance()
      .delete(urlPath)
      .then((response: AxiosResponse) => response.data);
  }

  static postFile<T>(urlPath: string, formData: FormData): Promise<T> {
    return HttpClient.instance()
      .post(urlPath, formData)
      .then((response: AxiosResponse) => response.data);
  }

  static getImage(urlPath: string, urlParams = {}): Promise<any> {
    return HttpClient.instance().get(urlPath, {
      params: urlParams,
      responseType: "blob",
    });
  }
}
