import React, { useEffect, useState } from "react";
import { IConversation } from "../../../storeroom/conversations/models";
import { Section, SectionProps } from "../../base/Section";
import { useHistory } from "react-router-dom";
import MessageList from "../MessageList";
import { ConversationContext } from "../contexts";
import { ConversationStore } from "../../../storeroom/conversations/ConversationStore";

interface Props {
  conversationID: number;
  secondary?: boolean;
}

export default function RecentConversationSection({
  conversationID,
  secondary,
}: Props) {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [conversation, setConversation] = useState<IConversation>(null);

  useEffect(() => {
    setLoading(true);

    ConversationStore.getPreview(conversationID)
      .then((conversation: IConversation) => {
        setConversation(conversation);
        setLoading(false);
      })
      .catch((reason: any) => {
        setConversation(null);
        setLoading(false);
      });
  }, [conversationID]);

  const sectionProps: SectionProps = {
    header: "Recent messages",
    actionIcon: "launch",
    actionLabel: "Open Messenger",
    onActionClick: () => history.push(`/conversations/${conversationID}`),
    secondaryColor: secondary,
  };

  return (
    <Section {...sectionProps}>
      {loading ? (
        "Loading..."
      ) : Boolean(conversation) ? (
        <ConversationContext.Provider value={conversation}>
          <MessageList />
        </ConversationContext.Provider>
      ) : null}
    </Section>
  );
}
