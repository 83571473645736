import React from "react";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../storeroom/StoreContext";
import { RouterProps } from "../base/baseModels";
import { observer } from "mobx-react";
import VacancyToolbar, {
  VacancyLoadingToolbar,
} from "./toolbar/VacancyToolbar";
import AcceptedReservationSection from "./sections/AcceptedReservationSection";
import VacancyRequestListSection from "./sections/VacancyRequestListSection";
import VacancyDetailsSection from "./sections/VacancyDetailsSection";

interface Props extends RouterProps {}

@observer
class VacancyPage extends React.Component<Props, any> {
  static contextType = StoreContext;

  initialize = () => {
    this.context.vacancyStore.loadVacancy(this.props.match.params.id);
  };

  componentDidMount(): void {
    this.initialize();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initialize();
    }
  }

  render() {
    const vacancy = this.context.vacancyStore.vacancy;

    return vacancy === undefined ? (
      <div>
        <VacancyLoadingToolbar />
      </div>
    ) : (
      <div>
        <VacancyToolbar />
        <VacancyDetailsSection />
        <AcceptedReservationSection />
        <VacancyRequestListSection
          header={"Current Requests"}
          reservations={vacancy.current_requests}
        />
        <VacancyRequestListSection
          header={"Past Requests"}
          reservations={vacancy.past_requests}
        />
      </div>
    );
  }
}

export default withRouter(VacancyPage);
