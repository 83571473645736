import { ITrip, ITripListResponse } from "./models";
import { HttpClient } from "../HttpClient";

export default class TripsHttpClient {
  static create(trip: ITrip): Promise<ITrip> {
    return HttpClient.post<ITrip>("trips/", trip);
  }

  static update(trip: ITrip): Promise<ITrip> {
    return HttpClient.put<ITrip>(`trips/${trip.id}/`, trip);
  }

  static list(): Promise<ITripListResponse> {
    return HttpClient.get<ITripListResponse>("trips/get_trip_list/");
  }
}
