import { HttpClient } from "../HttpClient";

export default class ProfileHttpClient {
  static coverPhoto(): Promise<string> {
    return HttpClient.get<string>("profile/cover_photo/");
  }

  static sendPhoneVerification = (): Promise<void> => {
    return HttpClient.get("profile_verification/send_phone_verification/");
  };

  static verifyPhoneOTP = (otp: string): Promise<void> => {
    return HttpClient.post("profile_verification/verify_phone_otp/", { otp });
  };

  static sendEmailVerification = (): Promise<void> => {
    return HttpClient.get("profile_verification/send_email_verification/");
  };

  static verifyEmailOTP = (otp: string): Promise<void> => {
    return HttpClient.post("profile_verification/verify_email_otp/", { otp });
  };
}
