import React from "react";
import { useHistory } from "react-router-dom";
import { ICrumbProps } from "../../base/Crumbs";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PageToolbar from "../../base/PageToolbar";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import { useDocumentTitle } from "../../hooks";

export default function SitterPageToolbar() {
  const history = useHistory();

  useDocumentTitle("Sytter Profile");

  const crumbs: ICrumbProps = {
    currentLabel: "Sytter Profile",
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "My Trips",
      icon: "card_travel",
      onClick: () => history.push("/trips"),
    },
    {
      label: "Explore Homes",
      icon: "search",
      onClick: () => history.push(privateRoutePaths.vacancySearch()),
    },
    {
      label: "Preview Profile",
      icon: "preview",
      onClick: () => history.push("/sitters/search/preview"),
    },
  ];

  return <PageToolbar crumbs={crumbs} actions={actions} secondary={true} />;
}
