import { action, observable } from "mobx";
import { IReservationBase } from "./models";
import { HttpClient } from "../HttpClient";

export class ReservationStore {
  @observable reservation: IReservationBase = null;
  @observable loading: boolean = false;

  @action
  private setReservation = (newReservation: IReservationBase) => {
    this.reservation = newReservation;
    this.loading = false;
  };

  private action = (
    actionName: string,
    error?: (error: Response) => void
  ): Promise<void> => {
    return HttpClient.get<IReservationBase>(
      `reservations/${this.reservation.id}/${actionName}/`
    ).then(this.setReservation);
  };

  load = (id: number) => {
    this.loading = true;
    return HttpClient.get<IReservationBase>(`reservations/${id}/`).then(
      this.setReservation
    );
  };

  reload = () => {
    if (this.reservation !== null) {
      this.load(this.reservation.id);
    }
  };

  request = (
    vacancy: number,
    destination: string,
    startDate: string,
    endDate: string,
    message: string
  ): Promise<number> => {
    const payload = {
      vacancy: vacancy,
      destination: destination,
      start_date: startDate,
      end_date: endDate,
      message: message,
    };

    return HttpClient.post<number>("reservations/request/", payload);
  };

  review = (error?: (error: Response) => void): void => {
    this.action("review", error);
  };

  retract = (): Promise<void> => {
    return this.action("retract");
  };

  accept = () => {
    return this.action("accept");
  };

  reject = () => {
    return this.action("reject");
  };

  cancel = (): Promise<void> => {
    return this.action("cancel");
  };

  start = (error?: (error: Response) => void): void => {
    this.action("start", error);
  };

  complete = (error?: (error: Response) => void): void => {
    this.action("complete", error);
  };
}
