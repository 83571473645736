import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { Typography } from "@material-ui/core";
import PetDescriptionModal from "../modals/PetDescriptionModal";

export default function PetDescriptionSection() {
  const [showEditForm, setShowEditForm] = useState(false);

  const store = useContext(StoreContext);
  const pet = store.petStore.pet;

  const sectionProps: SectionProps = {
    header: "Description",
    actionIcon: "edit",
    actionLabel: "Edit Description",
    onActionClick: () => setShowEditForm(true),
  };

  return (
    <Section {...sectionProps}>
      <PetDescriptionModal
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <Typography style={sectionBodyStyle}>{pet.description}</Typography>
    </Section>
  );
}
