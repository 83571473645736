import FieldRow from "./FieldRow";
import {
  IThumbsCheckboxProps,
  ThumbsDownCheckbox,
  ThumbsUpCheckbox,
} from "../../base/misc/checkboxes";
import React from "react";

export interface IThumbsFieldProps {
  label: string;
  helpText?: string;
  thumbState: boolean;
  onChange: (thumbsUp: boolean) => void;
  secondary?: boolean;
}

export default function ThumbsField(props: IThumbsFieldProps) {
  const onThumbsUp = () => props.onChange(true);
  const onThumbsDown = () => props.onChange(false);

  const thumbsDownProps: IThumbsCheckboxProps = {
    onClick: onThumbsDown,
    secondary: props.secondary,
    selected: props.thumbState === null ? false : !props.thumbState,
  };

  const thumbsUpProps: IThumbsCheckboxProps = {
    onClick: onThumbsUp,
    secondary: props.secondary,
    selected: props.thumbState === null ? false : props.thumbState,
  };

  return (
    <FieldRow label={props.label} helpText={props.helpText}>
      <ThumbsDownCheckbox {...thumbsDownProps} />
      <div style={{ width: "1rem" }} />
      <ThumbsUpCheckbox {...thumbsUpProps} />
    </FieldRow>
  );
}
