import React from "react";
import PublicMenuBar from "./navbar/PublicMenuBar";
import PublicFooter from "./footer/PublicFooter";

export default function BasePublicPage(props: { children?: any }) {
  return (
    <div>
      <PublicMenuBar />
      {props.children}
      <PublicFooter />
    </div>
  );
}
