import React from "react";
import { IMenuItem, IMenuListProps, IMenuProps } from "./models";
import { useHistory } from "react-router-dom";
import { privateRoutePaths } from "../../../../routes/PrivateRoutes";
import MenuButtonList from "./MenuButtonList";
import { useTabletOrLarger } from "../../../hooks";
import MenuDropdown from "./MenuDropdown";

export default function SitterMenu(props: IMenuProps) {
  const history = useHistory();
  const isNarrow = !useTabletOrLarger();

  const menuItems: Array<IMenuItem> = [
    {
      label: "My Profile",
      onClick: () => history.push("/sitter"),
      icon: "person",
      color: "secondary",
    },
    {
      label: "My Trips",
      onClick: () => history.push("/trips"),
      icon: "card_travel",
      color: "secondary",
    },
    {
      label: "Explore Homes",
      onClick: () => history.push(privateRoutePaths.vacancySearch()),
      icon: "search",
      color: "secondary",
    },
  ];

  const menuListProps: IMenuListProps = {
    label: "Sytter",
    menuItems: menuItems,
  };

  return isNarrow || props.forceList ? (
    <MenuDropdown {...menuListProps} />
  ) : (
    <MenuButtonList {...menuListProps} />
  );
}
