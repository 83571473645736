import React from "react";
import { observer } from "mobx-react";
import { StoreContext } from "../../storeroom/StoreContext";
import DescriptionSection from "./sections/DescriptionSection";
import SitterPageToolbar from "./toolbar/SitterPageToolbar";
import AlbumSection from "../albums/AlbumSection";
import LoadingSection from "../base/LoadingSection";

@observer
class SitterPage extends React.Component<any, any> {
  componentDidMount(): void {
    this.context.sitterStore.load();
    this.context.profileStore.load();
  }

  onAlbumChange = () => {
    this.context.profileStore.loadCoverPhoto();
  };

  render() {
    const sitter = this.context.sitterStore.sitter;
    const profile = this.context.profileStore.profile;

    return sitter ? (
      <div>
        <SitterPageToolbar />
        <DescriptionSection />

        {profile ? (
          <AlbumSection
            album={profile.album}
            headerOverride={"My Pictures"}
            prevURL={"/sitter"}
            secondary={true}
            onAlbumChange={this.onAlbumChange}
          />
        ) : (
          <LoadingSection header={"My Pictures"} cards={true} />
        )}
      </div>
    ) : (
      <div>
        <SitterPageToolbar />
        <LoadingSection header={"About Me"} />
        <LoadingSection header={"My Pictures"} cards={true} />
      </div>
    );
  }
}

SitterPage.contextType = StoreContext;

export default SitterPage;
