import React from "react";
import { IPet } from "../../../storeroom/pets/models";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import AlbumGallery from "../../albums/AlbumGallery";

interface Props {
  pet: IPet;
}

export function PetSummarySection({ pet }: Props) {
  const history = useHistory();

  const sectionProps: SectionProps = {
    header: pet.name,
    actionIcon: "edit",
    actionLabel: `Edit`,
    onActionClick: () => history.push(`/pets/${pet.id}`),
  };

  return (
    <Section {...sectionProps}>
      <AlbumGallery albumID={pet.album} />
      <Typography style={sectionBodyStyle}>{pet.description}</Typography>
    </Section>
  );
}
