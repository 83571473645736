import React, { useState } from "react";
import {
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

export interface IPageToolbarAction {
  label: string;
  icon: string;
  onClick: () => void;
  forceIcon?: boolean;
  disabled?: boolean;
}

interface Props {
  actions: Array<IPageToolbarAction>;
  secondary?: boolean;
}

export default function PageToolbarActions(props: Props) {
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchor, setAnchor] = useState(null);

  const showActionsMenu = (event: any) => setAnchor(event.currentTarget);
  const hideActionsMenu = () => setAnchor(null);

  const onActionClick = (action: IPageToolbarAction) => {
    setAnchor(null);
    action.onClick();
  };

  return isNarrow && props.actions.length > 1 ? (
    <div>
      <Button
        variant={"outlined"}
        color={"inherit"}
        endIcon={<Icon>expand_more</Icon>}
        onClick={showActionsMenu}
        size={"small"}
      >
        Actions
      </Button>

      <Menu
        id={"actions-menu"}
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={hideActionsMenu}
        MenuListProps={{ onMouseLeave: hideActionsMenu }}
        style={{ marginTop: "3em" }}
      >
        {props.actions.map((action: IPageToolbarAction, index: number) => (
          <MenuItem
            onClick={() => onActionClick(action)}
            key={index}
            disabled={action.disabled}
          >
            <ListItemIcon>
              <Icon color={props.secondary ? "secondary" : "primary"}>
                {action.icon}
              </Icon>
            </ListItemIcon>
            <ListItemText>{action.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  ) : (
    <div>
      {props.actions.map((action: IPageToolbarAction, index: number) => (
        <Button
          variant={"outlined"}
          color={"inherit"}
          style={{
            marginRight: index + 1 === props.actions.length ? 0 : "1em",
          }}
          onClick={() => onActionClick(action)}
          startIcon={action.forceIcon ? <Icon>{action.icon}</Icon> : null}
          key={index}
          disabled={action.disabled}
          size={"small"}
        >
          {action.label}
        </Button>
      ))}
    </div>
  );
}
