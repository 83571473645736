import { IConversation, IConversationListItem } from "./models";
import { HttpClient } from "../HttpClient";

export default class ConversationHttpClient {
  static list = (): Promise<Array<IConversationListItem>> => {
    return HttpClient.get<Array<IConversationListItem>>("conversations/");
  };

  static retrieve = (id: number): Promise<IConversation> => {
    return HttpClient.get<IConversation>(`conversations/${id}/`);
  };

  static preview = (id: number): Promise<IConversation> => {
    return HttpClient.get<IConversation>(`conversations/${id}/preview/`);
  };
}
