import React, { useEffect, useState } from "react";
import AddTripStore from "../../../storeroom/trips/AddTripStore";
import { useSitterStore } from "../../hooks";
import { Dialog, DialogProps } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useObserver } from "mobx-react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { TextFieldChangeEvent } from "../../models";
import { Alert } from "@material-ui/lab";

export interface ICreateTripModalProps {
  show: boolean;
  afterCreate: (tripID: number) => void;
  afterCancel: () => void;
}

interface ICreateTripModalContentProps {
  store: AddTripStore;
}

export default function CreateTripModal(props: ICreateTripModalProps) {
  const sitterStore = useSitterStore();
  const [store, setStore] = useState<AddTripStore>(null);

  useEffect(() => {
    if (props.show) {
      setStore(new AddTripStore());
    }
  }, [props.show, sitterStore]);

  const onCancel = () => {
    props.afterCancel();
  };

  const onSubmit = () => {
    store
      .submit()
      .then((tripID) => props.afterCreate(tripID))
      .catch();
  };

  const dialogProps: DialogProps = {
    open: props.show,
    fullWidth: true,
    maxWidth: "xs",
  };

  return useObserver(() => {
    return (
      <Dialog {...dialogProps}>
        <DialogTitle>Add Trip</DialogTitle>

        {store && <CreateTripModalContent store={store} />}

        <DialogActions>
          <Button disabled={store?.pending} size={"small"} onClick={onCancel}>
            Cancel
          </Button>

          <Button
            disabled={store?.pending}
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
}

function CreateTripModalContent(props: ICreateTripModalContentProps) {
  const onDestinationChange = (event: TextFieldChangeEvent) => {
    props.store.setDestination(event.target.value);
  };

  const onStartChange = (event: TextFieldChangeEvent) => {
    props.store.setStartDate(event.target.value);
  };

  const onEndChange = (event: TextFieldChangeEvent) => {
    props.store.setEndDate(event.target.value);
  };

  return useObserver(() => {
    const store = props.store;

    return (
      <DialogContent>
        {store.error && <CreateTripModalAlert store={store} />}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Destination"}
              value={store.trip.location}
              onChange={onDestinationChange}
              autoFocus={true}
              fullWidth={true}
              color={"secondary"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={"Check In"}
              type={"date"}
              value={store.trip.start_date}
              onChange={onStartChange}
              fullWidth={true}
              color={"secondary"}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={"Check Out"}
              type={"date"}
              value={store.trip.end_date}
              onChange={onEndChange}
              fullWidth={true}
              color={"secondary"}
            />
          </Grid>
        </Grid>
      </DialogContent>
    );
  });
}

function CreateTripModalAlert(props: ICreateTripModalContentProps) {
  return useObserver(() => (
    <Alert severity={"error"} style={{ marginBottom: "1rem" }}>
      {props.store.error}
    </Alert>
  ));
}
