import { IVacancy } from "../homes/models";
import { observable } from "mobx";
import VacancyHttpClient from "./VacancyHttpClient";
import { deepCopy } from "../../utils";
import moment from "moment";

export default class VacancyStore {
  private _vacancy: IVacancy;

  @observable loading = false;
  @observable vacancy: IVacancy;
  @observable dirty = false;

  constructor(vacancy?: IVacancy, listing?: number) {
    if (Boolean(vacancy)) {
      this.postLoad(vacancy);
    } else {
      this.initializeNew(listing);
    }
  }

  private postLoad = (vacancy: IVacancy) => {
    this._vacancy = deepCopy(vacancy);
    this.vacancy = deepCopy(vacancy);
    this.loading = false;
    this.dirty = false;
  };

  private onLoadError = (error: any) => {
    this.vacancy = null;
    this.loading = false;
  };

  private initializeNew = (listing: number) => {
    const start = moment().add(1, "days");
    const end = moment().add(3, "days");

    this.postLoad({
      id: null,
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
      full_rental_only: true,
      listing: listing,
    });
  };

  setStartDate = (startDate: string) => {
    this.vacancy.start_date = startDate;
    this.postUpdate();
  };

  setEndDate = (endDate: string) => {
    this.vacancy.end_date = endDate;
    this.postUpdate();
  };

  setFullRental = (fullRental: boolean) => {
    this.vacancy.full_rental_only = fullRental;
    this.postUpdate();
  };

  postUpdate = () => {
    this.dirty = true;
  };

  save = (): Promise<void> => {
    if (this.vacancy.id === null) {
      return VacancyHttpClient.create(this.vacancy).then(this.postLoad);
    } else {
      return VacancyHttpClient.update(this.vacancy).then(this.postLoad);
    }
  };
}
