import { GridProps } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DialogContentText from "@material-ui/core/DialogContentText";

export interface IFieldRowProps {
  label: string;
  helpText?: string;
  children?: any;
}

export default function FieldRow(props: IFieldRowProps) {
  const fieldItemProps: GridProps = {
    item: true,
    xs: 3,
    style: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },
  };

  return (
    <React.Fragment>
      <Grid item xs={9}>
        <strong>{props.label}</strong>
        {props.helpText && (
          <DialogContentText>{props.helpText}</DialogContentText>
        )}
      </Grid>
      <Grid {...fieldItemProps}>{props.children}</Grid>
    </React.Fragment>
  );
}
