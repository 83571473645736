import {
  IReservationForHost,
  ReservationStatus,
} from "../../../storeroom/reservations/models";
import React, { useContext } from "react";
import { HostReservationContext } from "../contexts";
import { Container } from "@material-ui/core";
import {
  ApprovedBannerForHost,
  CanceledBannerForHost,
  NeedsSitterReviewBanner,
  RejectedBanner,
  RequestedByGuestBannerForHost,
} from "./banners";

export function ReservationBannerForHost() {
  const reservation: IReservationForHost = useContext(HostReservationContext);

  let banner = null;

  switch (reservation.status) {
    case ReservationStatus.in_review:
      banner = <RequestedByGuestBannerForHost />;
      break;

    case ReservationStatus.rejected:
      banner = <RejectedBanner />;
      break;

    case ReservationStatus.canceled:
      banner = <CanceledBannerForHost />;
      break;

    case ReservationStatus.accepted:
      banner = <ApprovedBannerForHost />;
      break;

    case ReservationStatus.completed:
      if (reservation.sitter_review === null) {
        banner = <NeedsSitterReviewBanner />;
        break;
      } else {
        return null;
      }

    default:
      return null;
  }

  return (
    <div>
      <Container maxWidth={"md"}>{banner}</Container>
      <div style={{ height: "2rem" }} />
    </div>
  );
}
