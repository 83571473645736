import React from "react";
import Modal from "../../../misc/Modal";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export interface IPublishErrorModalProps {
  show: boolean;
  onClose: () => void;
  errors: Array<string>;
}

export default function PublishErrorModal(props: IPublishErrorModalProps) {
  return (
    <Modal
      show={props.show}
      maxWidth={"sm"}
      headerChildren={<strong>Publish Error</strong>}
      contentChildren={
        <React.Fragment>
          <Typography variant={"body1"}>
            This listing was not published. Fix the following errors and try
            again:
          </Typography>

          <Typography variant={"body2"}>
            <ul>
              {props.errors?.map((error, index) => {
                return <li>{error}</li>;
              })}
            </ul>
          </Typography>
        </React.Fragment>
      }
      footerChildren={
        <React.Fragment>
          <Button
            variant={"contained"}
            size={"small"}
            onClick={props.onClose}
            color={"primary"}
          >
            Ok
          </Button>
        </React.Fragment>
      }
    />
  );
}
