import React from "react";
import BasePublicPage from "../BasePublicPage";
import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import PageToolbar from "../../base/PageToolbar";
import { ICrumbProps } from "../../base/Crumbs";

interface IFAQ {
  question: string;
  answer: any;
}

interface ISection {
  label: string;
  faqs: Array<IFAQ>;
}

interface IFAQProps {
  faq: IFAQ;
}

interface IFAQSectionProps {
  section: ISection;
}

const FAQ = (props: IFAQProps) => {
  return (
    <React.Fragment>
      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
        {props.faq.question}
      </Typography>

      <Typography variant={"body2"}>{props.faq.answer}</Typography>

      <div style={{ height: "3rem" }} />
    </React.Fragment>
  );
};

const FAQSection = (props: IFAQSectionProps) => {
  const { faqs } = props.section;

  return (
    <div>
      {faqs.map((faq, index) => {
        return <FAQ faq={faq} key={index} />;
      })}
    </div>
  );
};

export default function FAQPage() {
  const content: Array<ISection> = [
    {
      label: "",
      faqs: [
        {
          question: "How does Jetsyt Work?",
          answer:
            "Jetsyt allows pet parents to receive extremely low-cost in-home pet care when they are away by offering pet sitters free accommodations. The platform connect parents parents with pet sitters who want to travel and care for pets.",
        },
        {
          question: "What is the cost per month per member?",
          answer:
            "Please visit the Pricing page to get an in-depth look at the monthly costs for members here.",
        },
        {
          question: "How does the home insurance work?",
          answer:
            "Jetsyt is committed to ensuring all members are protected during stays and leaves. To do so, we've teamed up with the #1 short-let risk management company, SuperHog, to give market-leading prevention backed with $1m of protection. The cost of this insurance is baked into your monthly subscription.",
        },
        {
          question: "How can I get in contact with Jetsyt?",
          answer:
            "It's easy! Email us at hello@jetsyt.com and we will get in touch with you asap.",
        },
        {
          question: "Is my pet safe?",
          answer:
            "Pet safety is our number one concern and at the heart of what we do. We are committed to pet and home safety and ensure that every member on Jetsyt has passed a third-party background check to offer peace of mind and comfort to pet parents.\n" +
            "‍\n" +
            "All new Jetsyt members must pass an Enhanced Check, which is considered the most comprehensive criminal check on the market and includes (1) their social security number, name, and address must match (2) They aren't on any sex offender registry (3) They aren't on any terrorist watchlist (4) They aren't listed in the National Criminal Database* for any disqualifying criminal offenses (5) A manual search of county court records based on the person's address history over the last seven years.\n" +
            "\n" +
            "Also, all members must provide a detailed profile and personal information who are manually approved by our team.",
        },
        {
          question: "Are all members background checked?",
          answer:
            "Yes, every member on the Jetsyt platform must undergo a background check through out third-party partner, GuardHog.\n" +
            "‍\n" +
            "All new Jetsyt members must pass an Enhanced Check, which is considered the most comprehensive criminal check on the market and includes (1) their social security number, name, and address must match (2) They aren't on any sex offender registry (3) They aren't on any terrorist watchlist (4) They aren't listed in the National Criminal Database* for any disqualifying criminal offenses (5) A manual search of county court records based on the person's address history over the last seven years.\n" +
            "\n" +
            "Also, all members must provide a detailed profile and personal information who are manually approved by our team.",
        },
        {
          question: "Do you offer pet insurance?",
          answer:
            "At this time, Jetsyt does not offer health insurance for pets.",
        },
        {
          question: "Do I have to have home insurance?",
          answer:
            "Yes, however it is not a separate cost. The cost of this insurance is already baked into the monthly subscription of Owners. To learn more about our insurance policy, visit here.",
        },
      ],
    },
  ];

  const crumbs: ICrumbProps = {
    currentLabel: "FAQs",
  };

  return (
    <BasePublicPage>
      <PageToolbar crumbs={crumbs} actions={[]} secondary={true} />

      <Container maxWidth={"md"}>
        {content.map((section, index) => {
          return <FAQSection section={section} key={index} />;
        })}
      </Container>
    </BasePublicPage>
  );
}
