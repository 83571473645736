import { action, observable } from "mobx";
import { generateDefaultPet, IPet, IPetType } from "./models";
import { HttpClient } from "../HttpClient";

export class PetStore {
  @observable petList: Array<IPet> = undefined;
  @observable pet: IPet = undefined;
  @observable petTypeList: Array<IPetType> = undefined;

  @action
  private setPetList = (newList: Array<IPet>) => {
    this.petList = newList;
  };

  @action
  private setPet = (newPet: IPet) => {
    this.pet = newPet;
  };

  @action
  private setCategoryList = (newList: Array<IPetType>) => {
    this.petTypeList = newList;
  };

  @action
  private postAdd = (newPet: IPet): IPet => {
    this.pet = newPet;

    if (this.petList !== undefined) {
      this.petList.push(newPet);
    }

    return newPet;
  };

  @action
  private postUpdate = (updatedPet: IPet) => {
    this.pet = updatedPet;

    if (this.petList !== undefined) {
      this.petList = this.petList.map((pet) =>
        pet.id === updatedPet.id ? updatedPet : pet
      );
    }
  };

  @action
  private postRemove = (id: number) => {
    this.pet = generateDefaultPet();

    if (this.petList !== undefined) {
      this.petList = this.petList.filter((pet) => pet.id !== id);
    }
  };

  loadPetCategories = () => {
    if (this.petTypeList === undefined) {
      HttpClient.get<Array<IPetType>>(
        "pet_types/"
      ).then((response: Array<IPetType>) => this.setCategoryList(response));
    }
  };

  forceLoadPetList = () => {
    this.petList = undefined;
    this.loadPetList();
  };

  loadPetList = (error?: (error: Response) => void) => {
    if (this.petList === undefined) {
      HttpClient.get<Array<IPet>>("pets/")
        .then((response: Array<IPet>) => this.setPetList(response))
        .catch(error);
    }
  };

  loadPet = (id: number, error?: (error: Response) => void) => {
    this.setPet(undefined);
    HttpClient.get<IPet>(`pets/${id}/`)
      .then((response: IPet) => this.setPet(response))
      .catch(error);
  };

  add = (newPet: IPet): Promise<IPet> => {
    return HttpClient.post<IPet>(`pets/`, newPet).then((response: IPet) =>
      this.postAdd(response)
    );
  };

  update = (updatedPet: IPet, error?: (error: Response) => void): void => {
    HttpClient.put<IPet>(`pets/${updatedPet.id}/`, updatedPet)
      .then((response: IPet) => this.postUpdate(response))
      .catch(error);
  };

  submitUpdates = (updatedPet: IPet): Promise<void> => {
    return HttpClient.put<IPet>(`pets/${updatedPet.id}/`, updatedPet).then(
      this.postUpdate
    );
  };

  remove = (id: number, error?: (error: Response) => void): void => {
    HttpClient.delete(`pets/${id}/`)
      .then(() => this.postRemove(id))
      .catch(error);
  };

  delete = (id: number) => {
    return HttpClient.delete(`pets/${id}/`).then(() => this.postRemove(id));
  };
}
