import { IHomeReview } from "../models";
import { HttpClient } from "../../HttpClient";

export default class HomeReviewsHttpClient {
  static list = (): Promise<Array<IHomeReview>> => {
    return HttpClient.get<Array<IHomeReview>>("home_reviews/");
  };

  static retrieve = (id: number): Promise<IHomeReview> => {
    return HttpClient.get<IHomeReview>(`home_reviews/${id}/`);
  };

  static create = (review: IHomeReview): Promise<IHomeReview> => {
    return HttpClient.post<IHomeReview>("home_reviews/", review);
  };

  static update = (review: IHomeReview): Promise<IHomeReview> => {
    return HttpClient.put<IHomeReview>(`home_reviews/${review.id}/`, review);
  };
}
