import React, { useState } from "react";
import { ITrip } from "../../../storeroom/trips/models";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { useTripStore } from "../../hooks";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export interface ITripCancelModalProps {
  show: boolean;
  onClose: (tripCanceled: boolean) => void;
  trip: ITrip;
}

export default function TripCancelModal(props: ITripCancelModalProps) {
  const tripStore = useTripStore();
  const [submitted, setSubmitted] = useState(false);

  const onCancel = () => {
    props.onClose(false);
  };

  const onContinue = () => {
    setSubmitted(true);
    tripStore.remove(props.trip.id).then(() => {
      props.onClose(true);
      setSubmitted(false);
    });
  };

  return (
    <Dialog open={props.show} fullWidth maxWidth={"xs"}>
      <DialogTitle>Cancel Trip</DialogTitle>

      <DialogContent>
        <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
          Are you sure?
        </Typography>

        <Typography variant={"body1"}>
          This trip will be deleted along with all related reservations and
          requests. This cannot be reversed.
        </Typography>
        <div style={{ height: "1rem" }} />
      </DialogContent>

      <DialogActions>
        <Button disabled={submitted} onClick={onCancel} size={"small"}>
          Cancel
        </Button>

        <Button
          disabled={submitted}
          variant={"contained"}
          color={"secondary"}
          onClick={onContinue}
          size={"small"}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
