import { IBillingProfile, IStripeSessionInfo } from "./models";
import { HttpClient } from "../HttpClient";

export default class BillingHttpClient {
  static retrieve = (): Promise<IBillingProfile> => {
    return HttpClient.get("billing/");
  };

  static refreshBackgroundFeeStatus = (): Promise<IBillingProfile> => {
    return HttpClient.get("billing/refresh_background_fee_status/");
  };

  static createSubscriptionCheckoutSession = (
    priceID: string
  ): Promise<IStripeSessionInfo> => {
    return HttpClient.get("billing/get_subscription_checkout_session/", {
      price_id: priceID,
    });
  };

  static updateSubscriptionStatus = (): Promise<boolean> => {
    return HttpClient.get("billing/update_subscription_status/");
  };

  static createBackgroundFeeCheckoutSession = (): Promise<IStripeSessionInfo> => {
    return HttpClient.get("billing/get_background_fee_checkout_session/");
  };

  static updatePurchases = (): Promise<boolean> => {
    return HttpClient.get("billing/update_purchases/");
  };
}
