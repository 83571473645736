import React from "react";
import { IVacancyReservationListItem } from "../../../storeroom/homes/models";
import { useHistory } from "react-router-dom";
import { privateRoutePaths } from "../../../routes/PrivateRoutes";
import { useVacancyStore } from "../../hooks";
import { humanizeDateRange } from "../../../formattingService";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { Section } from "../../base/Section";

interface Props {
  header: string;
  reservations: Array<IVacancyReservationListItem>;
}

export default function VacancyRequestListSection(props: Props) {
  const history = useHistory();
  const vacancy = useVacancyStore().vacancy;

  const onReservationClick = (reservation: IVacancyReservationListItem) => {
    history.push(privateRoutePaths.reservationDetails(reservation.id), {
      vacancyID: vacancy.id,
      vacancyLabel: humanizeDateRange(vacancy.start_date, vacancy.end_date),
    });
  };

  return (
    <Section header={props.header}>
      <Container disableGutters={true}>
        {props.reservations.length === 0 ? (
          <Typography variant={"body1"}>No Requests</Typography>
        ) : (
          <Grid container spacing={2}>
            {props.reservations.map((reservation) => (
              <Grid item xs={6} key={reservation.id}>
                <Card>
                  <CardActionArea
                    onClick={() => onReservationClick(reservation)}
                  >
                    <CardMedia
                      component={"img"}
                      image={reservation.photo_url}
                      height={240}
                    />
                    <CardContent>
                      <Typography>{reservation.name}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Section>
  );
}
