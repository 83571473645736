import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "../showroom/auth/LoginPage";
import { IRoute } from "./AppRouter";
import { observer } from "mobx-react";
import { AuthStoreContext } from "../storeroom/StoreContext";
import FrontPage from "../showroom/welcome/FrontPage";
import AboutPage from "../showroom/welcome/aboutPage/AboutPage";
import PricesPage from "../showroom/welcome/pricesPage/PricesPage";
import RegistrationPage from "../showroom/users/setUp/RegistrationPage";
import CodeOfConductPage from "../showroom/welcome/codeOfConductPage/CodeOfConductPage";
import FAQPage from "../showroom/welcome/faqs/FAQPage";

export const publicRoutePaths = {
  front: () => "/",
  login: () => "/login",
  about: () => "/about",
  prices: () => "/prices",
  register: () => "/register",
  codeOfConduct: () => "/code_of_conduct",
  faqPage: () => "/faqs",
};

const routes: Array<IRoute> = [
  {
    path: publicRoutePaths.front(),
    component: <FrontPage />,
  },
  {
    path: publicRoutePaths.about(),
    component: <AboutPage />,
  },
  {
    path: publicRoutePaths.prices(),
    component: <PricesPage />,
  },
  {
    path: publicRoutePaths.login(),
    component: <LoginPage />,
  },
  {
    path: publicRoutePaths.register(),
    component: <RegistrationPage />,
  },
  {
    path: publicRoutePaths.codeOfConduct(),
    component: <CodeOfConductPage />,
  },
  {
    path: publicRoutePaths.faqPage(),
    component: <FAQPage />,
  },
];

export const publicRoutes = routes.map((route) => route.path);

@observer
class PublicRoutes extends React.Component<any, any> {
  static contextType = AuthStoreContext;

  componentDidMount(): void {
    this.context.authStore.verify();
  }

  render() {
    const authStatus = this.context.authStore.authStatus;

    return authStatus.verified ? (
      <Redirect to={"/profile"} />
    ) : (
      <Switch>
        {routes.map((route, index) => (
          <Route exact path={route.path} key={index}>
            {route.component}
          </Route>
        ))}
      </Switch>
    );
  }
}

export default PublicRoutes;
