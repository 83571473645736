import React, { useContext, useState } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { Section, sectionBodyStyle, SectionProps } from "../../base/Section";
import { Typography } from "@material-ui/core";
import PetCareModal from "../../pets/modals/PetCareModal";

export default function PetCareSection() {
  const [showEditForm, setShowEditForm] = useState(false);
  const store = useContext(StoreContext);

  const sectionProps: SectionProps = {
    header: "Pet Care Routine",
    actionIcon: "edit",
    actionLabel: "Edit Routine",
    onActionClick: () => setShowEditForm(true),
  };

  return (
    <Section {...sectionProps}>
      <PetCareModal
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <Typography style={sectionBodyStyle}>
        {store.homeStore.home.pet_care_requirements}
      </Typography>
    </Section>
  );
}
