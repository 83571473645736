import { ISitterReview } from "../models";
import { observable } from "mobx";
import SitterReviewsHttpClient from "./SitterReviewsHttpClient";
import { deepCopy } from "../../../utils";

export default class SitterReviewStore {
  private _review: ISitterReview;

  @observable review: ISitterReview;
  @observable loading = false;
  @observable dirty = false;
  @observable canSubmit = false;

  constructor(id?: number, reservationID?: number, sitterID?: number) {
    if (Boolean(id)) {
      this.load(id);
    } else {
      this.startNew(reservationID, sitterID);
    }
  }

  private load = (id: number) => {
    this.loading = true;
    SitterReviewsHttpClient.retrieve(id)
      .then(this.postLoad)
      .catch(this.onLoadError);
  };

  private startNew = (reservationID: number, sitterID: number) => {
    this.postLoad({
      id: null,
      reservation: reservationID,
      reviewer: null,
      rating: null,
      summary: "",
      sitter: sitterID,
      pet_care: null,
      house_rules: null,
      communication: null,
    });
  };

  private postLoad = (review: ISitterReview) => {
    this._review = deepCopy(review);
    this.review = deepCopy(review);
    this.loading = false;
    this.dirty = false;
  };

  private onLoadError = (error: any) => {
    this.review = null;
    this.loading = false;
  };

  reset = () => {
    this.review = deepCopy(this._review);
    this.dirty = false;
  };

  postUpdate = () => {
    this.dirty = true;

    const review = this.review;
    this.canSubmit =
      Boolean(review.rating) &&
      review.pet_care !== null &&
      review.house_rules !== null &&
      review.communication !== null;
  };

  setRating = (rating: 1 | 2 | 3 | 4 | 5) => {
    this.review.rating = rating;
    this.postUpdate();
  };

  setSummary = (summary: string) => {
    this.review.summary = summary;
    this.postUpdate();
  };

  setCommunication = (communication: boolean) => {
    this.review.communication = communication;
    this.postUpdate();
  };

  setPetCare = (petCare: boolean) => {
    this.review.pet_care = petCare;
    this.postUpdate();
  };

  setHouseRules = (houseRules: boolean) => {
    this.review.house_rules = houseRules;
    this.postUpdate();
  };

  save = (): Promise<void> => {
    return SitterReviewsHttpClient.updateOrCreate(this.review).then(
      this.postLoad
    );
  };
}
