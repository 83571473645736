import React from "react";
import { HostContactSection, HostDescriptionSection } from "../sections";
import { useGuestReservation } from "../../hooks";
import { isAccepted } from "../../../../storeroom/reservations/models";
import RecentConversationSection from "../../../conversations/sections/RecentConversationSection";

export default function PetParentTab() {
  const reservation = useGuestReservation();
  const showContactInfo = isAccepted(reservation);

  return (
    <div>
      {showContactInfo && <HostContactSection />}
      <RecentConversationSection
        conversationID={reservation.conversation}
        secondary={true}
      />
      <HostDescriptionSection />
    </div>
  );
}
