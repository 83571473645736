import React from "react";
import { useMobile } from "../../hooks";
import { DialogProps } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AlbumModalHeader from "./header/AlbumModalHeader";
import AlbumModalPreviewTab from "./body/AlbumModalPreviewTab";
import { IAlbumModalProps } from "./AlbumModal";
import DialogContent from "@material-ui/core/DialogContent";

export default function AlbumModalViewOnly(props: IAlbumModalProps) {
  const isNarrow = useMobile();

  const dialogProps: DialogProps = {
    open: props.show,
    onClose: props.onClose,
    scroll: "paper",
  };

  if (isNarrow) {
    dialogProps.fullScreen = true;
  } else {
    dialogProps.fullWidth = true;
    dialogProps.maxWidth = "md";
  }

  return (
    <Dialog {...dialogProps}>
      <AlbumModalHeader
        header={props.header}
        showCloseIcon={true}
        onClose={props.onClose}
        secondary={props.secondary}
      />

      <DialogContent style={{ padding: 0 }}>
        <AlbumModalPreviewTab hideHelpText={true} />
      </DialogContent>
    </Dialog>
  );
}
