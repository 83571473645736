import { HttpClient } from "../../HttpClient";

export interface IRegisterParams {
  email: string;
  password: string;
  phone: string;
  first_name: string;
  last_name: string;
}

export default class RegisterHttpClient {
  static register = (params: IRegisterParams): Promise<any> => {
    return HttpClient.post("registration/register/", params);
  };

  static registerAsOwner = () => {
    return HttpClient.get("profile/owner/register/");
  };

  static registerAsSitter = () => {
    return HttpClient.get("profile/sitter/register");
  };
}
