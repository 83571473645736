import React, { useContext } from "react";
import { AlbumStoreContext } from "../../../storeroom/albums/AlbumStore";
import { Dialog, DialogProps } from "@material-ui/core";
import AlbumModalBody from "./body/AlbumModalBody";
import AlbumModalFooter, {
  IAlbumModalFooterProps,
} from "./footer/AlbumModalFooter";
import AlbumSaveModal from "./AlbumSaveModal";
import AlbumModalHeader from "./header/AlbumModalHeader";
import { useMobile } from "../../hooks";
import { useObserver } from "mobx-react";

export interface IAlbumModalProps {
  show: boolean;
  onClose: (updated?: boolean) => void;
  header?: string;
  secondary?: boolean;
}

export default function AlbumModal(props: IAlbumModalProps) {
  const store = useContext(AlbumStoreContext);
  const isNarrow = useMobile();

  const onCancel = () => {
    store.reset();
    props.onClose(false);
  };

  const onSave = () => {
    store.save(onSaveFinish);
  };

  const onSaveFinish = () => {
    props.onClose(true);
  };

  const dialogProps: DialogProps = {
    open: props.show,
    scroll: "paper",
  };

  const footerProps: IAlbumModalFooterProps = {
    onCancel: onCancel,
    onSave: onSave,
    secondary: props.secondary,
  };

  return useObserver(() => {
    const pictureCount = store.galleryItems.length;

    if (isNarrow) {
      dialogProps.fullScreen = true;
    } else {
      dialogProps.fullWidth = true;
      dialogProps.maxWidth =
        pictureCount <= 1 ? "xs" : pictureCount === 2 ? "sm" : "md";
    }

    return (
      <Dialog {...dialogProps}>
        <AlbumModalHeader header={props.header} secondary={props.secondary} />
        <AlbumModalBody secondary={props.secondary} />
        <AlbumModalFooter {...footerProps} />
        <AlbumSaveModal />
      </Dialog>
    );
  });
}
