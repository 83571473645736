import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import TextField from "@material-ui/core/TextField";
import { usePetStore } from "../../../hooks";
import { Autocomplete } from "@material-ui/lab";
import { IPetType } from "../../../../storeroom/pets/models";
import { usePetBuilderStore } from "../hooks";

export default function PetBasicInfoForm() {
  return useObserver(() => (
    <div>
      <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
        Basic Information
      </Typography>
      <Spacer />
      <NameField />
      <Spacer />
      <PetTypeField />
      <Spacer />
      <BreedField />
    </div>
  ));
}

function Spacer() {
  return <div style={{ height: "1.5rem" }} />;
}

function NameField() {
  const basicInfoStore = usePetBuilderStore().getBasicInfoStepStore();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    basicInfoStore.setName(event.target.value);
  };

  return useObserver(() => (
    <TextField
      fullWidth
      label={"Name"}
      value={basicInfoStore.name}
      onChange={onChange}
      autoFocus
    />
  ));
}

function PetTypeField() {
  const petStore = usePetStore();
  const basicInfoStore = usePetBuilderStore().getBasicInfoStepStore();

  useEffect(() => {
    petStore.loadPetCategories();
  }, [petStore]);

  const onTypeChange = (event: any, value: any) => {
    basicInfoStore.setType(value ? value.name : "");
  };

  return useObserver(() => {
    const petTypeList = petStore.petTypeList;
    const petTypeChoices = petTypeList ? petTypeList : [];

    const selectedPetTypes = petTypeChoices
      ? petTypeChoices.filter(
          (petType) => petType.name === basicInfoStore.petType
        )
      : [];

    const selectedPetType =
      selectedPetTypes.length > 0 ? selectedPetTypes[0] : null;

    return (
      <Autocomplete
        value={selectedPetType}
        options={petTypeChoices}
        getOptionLabel={(option: IPetType) => option.display_name}
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={onTypeChange}
        renderInput={(params) => <TextField {...params} label={"Type"} />}
      />
    );
  });
}

function BreedField() {
  const basicInfoStore = usePetBuilderStore().getBasicInfoStepStore();

  const onBreedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    basicInfoStore.setBreed(event.target.value);
  };

  return useObserver(() => (
    <TextField
      fullWidth
      label={"Breed"}
      value={basicInfoStore.breed}
      onChange={onBreedChange}
    />
  ));
}
