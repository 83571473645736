import React from "react";
import { PetCareSection, PetSection } from "../sections";
import { useGuestReservation } from "../../hooks";
import { IReservationPet } from "../../../../storeroom/reservations/models";

export default function PetsTab() {
  const reservation = useGuestReservation();
  const pets = reservation.pets.pets;

  return (
    <div>
      <PetCareSection />

      {pets.map((pet: IReservationPet, index) => (
        <PetSection pet={pet} key={index} />
      ))}
    </div>
  );
}
