import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import Gallery from "react-photo-gallery";
import { AlbumStoreContext } from "../../storeroom/albums/AlbumStore";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import { Container } from "@material-ui/core";

export default function AlbumPreviewGallery() {
  const store = useContext(AlbumStoreContext);

  const photos = store.getPreviewItems();

  const skeletonProps: SkeletonProps = {
    variant: "rect",
    height: 250,
    animation: "wave",
  };

  return useObserver(() => {
    return store.loading ? (
      <Skeleton {...skeletonProps} />
    ) : photos.length === 1 ? (
      <Container maxWidth={"xs"}>
        <Gallery photos={photos} />
      </Container>
    ) : (
      <Gallery photos={photos} />
    );
  });
}
