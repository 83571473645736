import React from "react";
import {
  Button,
  Container,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import styles from "./page.module.sass";
import { useTabletOrLarger } from "../hooks";

export const headerStyle: React.CSSProperties = {
  fontSize: "1.5em",
  fontWeight: 300,
  lineHeight: "1em",
};

export const sectionBodyStyle: React.CSSProperties = {
  fontSize: "1em",
  fontWeight: 300,
};

interface ButtonProps {
  actionIcon?: string;
  actionLabel?: string;
  onActionClick?: () => void;
  secondaryColor?: boolean;
}

export interface SectionProps extends ButtonProps {
  header: string;
  children?: any;
  fullWidth?: boolean;
  groupedSection?: boolean;
}

function ActionButton(props: ButtonProps) {
  return props.actionIcon ? (
    <Button
      startIcon={<Icon>{props.actionIcon}</Icon>}
      className={styles.actionButton}
      color={props.secondaryColor ? "secondary" : "primary"}
      onClick={props.onActionClick}
    >
      {props.actionLabel}
    </Button>
  ) : null;
}

function ActionIconButton(props: ButtonProps) {
  return props.actionIcon ? (
    <IconButton
      className={styles.actionIconButton}
      color={props.secondaryColor ? "secondary" : "primary"}
      onClick={props.onActionClick}
    >
      <Icon>{props.actionIcon}</Icon>
    </IconButton>
  ) : null;
}

function ActionButtonGroup(props: ButtonProps) {
  return (
    <React.Fragment>
      <ActionIconButton {...props} />
      <ActionButton {...props} />
    </React.Fragment>
  );
}

export function Section(props: SectionProps) {
  const isTablet = useTabletOrLarger();
  const showActionBelowContent = isTablet && props.fullWidth;

  return (
    <Container
      maxWidth={"md"}
      className={styles.section}
      style={props.groupedSection ? { marginBottom: "1em" } : {}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={props.fullWidth ? 12 : 5}>
          <Typography style={headerStyle} className={styles.header}>
            {props.header}
          </Typography>
          {!showActionBelowContent && <ActionButtonGroup {...props} />}
        </Grid>

        <Grid item xs={12} md={props.fullWidth ? 12 : 7}>
          {props.children}
          {showActionBelowContent && <ActionButtonGroup {...props} />}
        </Grid>
      </Grid>
    </Container>
  );
}
