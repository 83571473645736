import React, { useState } from "react";
import PageToolbar from "../../base/PageToolbar";
import { ICrumbProps } from "../../base/Crumbs";
import { IPageToolbarAction } from "../../base/PageToolbarActions";
import PetBuilderModal from "../builder/PetBuilderModal";
import { IPet } from "../../../storeroom/pets/models";
import { usePetStore } from "../../hooks";

export default function PetListToolbar() {
  const petStore = usePetStore();
  const [showAddForm, setShowAddForm] = useState(false);

  const onAddClick = () => setShowAddForm(true);
  const onAddClose = (pet: IPet | null) => {
    if (pet !== null) {
      petStore.forceLoadPetList();
    }

    setShowAddForm(false);
  };

  const crumbs: ICrumbProps = {
    currentLabel: "My Pets",
    links: [
      {
        label: "My Home",
        url: "/home",
      },
    ],
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "Add Pet",
      icon: "add",
      forceIcon: true,
      onClick: onAddClick,
    },
  ];

  return (
    <PageToolbar crumbs={crumbs} actions={actions}>
      <PetBuilderModal show={showAddForm} onClose={onAddClose} />
    </PageToolbar>
  );
}
