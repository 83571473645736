import React from "react";
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default function ListLoadingSection() {
  return (
    <Container maxWidth={"md"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h5"} style={{ fontWeight: 100 }}>
            Loading Conversations
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
