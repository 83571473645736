import React from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

interface CrumbLink {
  label: string;
  url: string;
}

export interface ICrumbProps {
  links?: Array<CrumbLink>;
  currentLabel: string;
}

export default function Crumbs(props: ICrumbProps) {
  const history = useHistory();
  const onCrumbClick = (url: string) => history.push(url);

  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Breadcrumbs style={{ color: "#FFF" }}>
      {props.links?.map((link: CrumbLink, index: number) => (
        <Link
          color={"inherit"}
          onClick={() => onCrumbClick(link.url)}
          key={index}
          style={{ cursor: "pointer" }}
        >
          {isNarrow && index <= props.links.length - 2 ? (
            <span title={link.label}>..</span>
          ) : (
            link.label
          )}
        </Link>
      ))}

      <Typography variant={"h6"}>{props.currentLabel}</Typography>
    </Breadcrumbs>
  );
}
