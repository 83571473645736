import React, { useContext, useEffect, useState } from "react";
import PetBuilderStore, {
  PetBuilderContext,
  PetBuilderProvider,
  PetBuilderSteps,
} from "../../../storeroom/pets/builder/PetBuilderStore";
import { useHomeStore, useProfileStore } from "../../hooks";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useObserver } from "mobx-react";
import { useOwnerStyles } from "../../base/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PetBuilderStepper from "./PetBuilderStepper";
import PetBuilderFooter from "./PetBuilderFooter";
import PetBasicInfoForm from "./steps/PetBasicInfoForm";
import PetDescriptionForm from "./steps/PetDescriptionForm";
import { IPet } from "../../../storeroom/pets/models";
import PetBuilderAlbumForm from "./steps/PetBuilderAlbumForm";
import PetBuilderFinishForm from "./steps/PetBuilderFinishForm";

interface IProps {
  show: boolean;
  onClose: (pet: IPet | null) => void;
}

export default function PetBuilderModal(props: IProps) {
  const home = useHomeStore().home;
  const profile = useProfileStore().profile;
  const [store, setStore] = useState<PetBuilderStore>(null);

  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (home && profile) {
      setStore(new PetBuilderStore(home.id, profile.user.id));
    }
  }, [home, profile, props]);

  return useObserver(() => {
    const width =
      store?.currentStep === PetBuilderSteps.BASIC_INFO ? "sm" : "md";
    const disableBackdropClick = !isNarrow || store?.isFinalStep();
    const onClose = () => props.onClose(null);

    return store ? (
      <Dialog
        open={props.show}
        maxWidth={width}
        fullWidth={true}
        disableBackdropClick={disableBackdropClick}
        onClose={onClose}
      >
        <PetBuilderProvider value={store}>
          <ModalHeader />
          <ModalBody />
          <PetBuilderFooter onClose={props.onClose} />
        </PetBuilderProvider>
      </Dialog>
    ) : null;
  });
}

function ModalHeader() {
  const classes = useOwnerStyles();
  return (
    <DialogTitle disableTypography={true} className={classes.modalHeader}>
      <Typography
        variant={"h6"}
        color={"inherit"}
        style={{ fontWeight: "bold" }}
      >
        New Pet
      </Typography>
    </DialogTitle>
  );
}

function ModalBody() {
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DialogContent>
      <Grid container spacing={2}>
        {!isNarrow && (
          <Grid item xs={4}>
            <PetBuilderStepper />
          </Grid>
        )}

        <Grid item xs={isNarrow ? 12 : 8}>
          <div style={{ height: "0.5em" }} />
          <PetBuilderForm />
          <div style={{ height: "1em" }} />
        </Grid>
      </Grid>
    </DialogContent>
  );
}

function PetBuilderForm() {
  const store = useContext(PetBuilderContext);

  return useObserver(() => {
    switch (store.currentStep) {
      case PetBuilderSteps.BASIC_INFO:
        return <PetBasicInfoForm />;

      case PetBuilderSteps.DESCRIPTION:
        return <PetDescriptionForm />;

      case PetBuilderSteps.ALBUM:
        return <PetBuilderAlbumForm />;

      case PetBuilderSteps.FINISH:
        return <PetBuilderFinishForm />;

      default:
        return <div>Not Implemented!</div>;
    }
  });
}
