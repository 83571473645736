import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { IReservationForHost } from "../../../storeroom/reservations/models";
import { HostReservationContext } from "../contexts";
import { Section, SectionProps } from "../../base/Section";
import { Typography } from "@material-ui/core";
import Gallery from "react-photo-gallery";
import { IPicture } from "../../../storeroom/albums/models";

function galleryPhoto(picture: IPicture) {
  return {
    src: picture.url,
    width: picture.width,
    height: picture.height,
  };
}

export default function ReservationGuestSection() {
  const history = useHistory();
  const reservation: IReservationForHost = useContext(HostReservationContext);

  const sectionProps: SectionProps = {
    header: "Sytter",
    actionIcon: "launch",
    actionLabel: "Details",
    onActionClick: () => history.push(`/sitters/search/${reservation.trip}`),
  };

  return (
    <Section {...sectionProps}>
      <Typography variant={"h6"}>{reservation.guest.name}</Typography>
      <Gallery photos={reservation.guest.album.pictures.map(galleryPhoto)} />
    </Section>
  );
}
