import React, { useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { ReservationStore } from "../../storeroom/reservations/ReservationStore";
import { useDocumentTitle, useReservationStore } from "../hooks";
import { useObserver } from "mobx-react";
import PageToolbar from "../base/PageToolbar";
import ReservationForGuestPage from "./ReservationForGuestPage";
import LoadingSection from "../base/LoadingSection";
import { ICrumbProps } from "../base/Crumbs";
import ReservationForHostPage from "./ReservationForHostPage";

export default function ReservationPage() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const reservationStore: ReservationStore = useReservationStore();

  useDocumentTitle("Reservation");

  let secondary = false;
  const crumbs: ICrumbProps = {
    currentLabel: "Loading...",
  };

  if (location.state !== undefined) {
    if ("tripID" in location.state) {
      secondary = true;
      crumbs.links = [
        {
          label: "My Trips",
          url: "/trips",
        },
        {
          label: location.state["tripLabel"],
          url: `/trips/${location.state["tripID"]}`,
        },
      ];
    } else if ("vacancyID" in location.state) {
      crumbs.links = [
        {
          label: "My Vacancies",
          url: "/vacancies",
        },
        {
          label: location.state["vacancyLabel"],
          url: `/vacancies/${location.state["vacancyID"]}`,
        },
      ];
    }
  }

  useEffect(() => {
    reservationStore.load(id).catch(() => {
      alert("Error loading reservation");
      history.goBack();
    });
  }, [id, reservationStore, history]);

  return useObserver(() => {
    return reservationStore.loading ? (
      <div>
        <PageToolbar crumbs={crumbs} actions={[]} secondary={secondary} />
        <LoadingSection header={" "} cards={true} fullWidth={true} />
      </div>
    ) : reservationStore.reservation?.is_guest ? (
      <ReservationForGuestPage />
    ) : Boolean(reservationStore.reservation) ? (
      <ReservationForHostPage />
    ) : (
      <div>Error</div>
    );
  });
}
