import React from "react";
import { AppBar, Container, Toolbar, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ICrumbProps } from "../../../base/Crumbs";
import { IPageToolbarAction } from "../../../base/PageToolbarActions";
import PageToolbar from "../../../base/PageToolbar";

export default function MyListingToolbar() {
  const history = useHistory();

  const crumbProps: ICrumbProps = {
    currentLabel: "Listing",
    links: [
      {
        label: "My Home",
        url: "/home",
      },
    ],
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "Preview",
      icon: "compare",
      onClick: () => history.push("/listing/preview"),
    },
    {
      label: "Vacancies",
      icon: "card_travel",
      onClick: () => history.push("/vacancies"),
    },
  ];

  return <PageToolbar crumbs={crumbProps} actions={actions} />;
}

export function ReadOnlyMyListingToolbar() {
  return (
    <AppBar position={"sticky"} style={{ marginBottom: "2em" }} elevation={4}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Toolbar>
          <Typography variant={"h6"} style={{ marginRight: "2em" }}>
            My Listing
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
