import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../storeroom/StoreContext";
import { Section, sectionBodyStyle } from "../../../base/Section";
import { Typography } from "@material-ui/core";
import ListingDescriptionForm from "../forms/ListingDescriptionForm";

export default function DescriptionSection() {
  const store = useContext(StoreContext);
  const listing = store.listingStore.listing;

  const [showEditForm, setShowEditForm] = useState(false);

  return (
    <Section
      header={"Description"}
      actionIcon={"edit"}
      actionLabel={"Edit Description"}
      onActionClick={() => setShowEditForm(true)}
    >
      <ListingDescriptionForm
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
      />
      <Typography style={sectionBodyStyle}>{listing.description}</Typography>
    </Section>
  );
}
