import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../storeroom/StoreContext";
import { OwnerPreferences } from "../../storeroom/owners/models";
import { useObserver } from "mobx-react";
import { Button } from "@material-ui/core";
import { deepCopy } from "../../utils";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CheckboxChangeEvent } from "../models";
import Modal from "../misc/Modal";

interface Props {
  show: boolean;
  onClose: (accepted: boolean) => void;
}

export default function OwnerPreferencesForm(props: Props) {
  const store = useContext(StoreContext);
  const preferencesManager = store.ownerStore.preferencesManager;
  const preferences = preferencesManager.preferences;

  const [pending, setPending] = useState(false);
  const [localOnly, setLocalOnly] = useState(preferences?.local_only);
  const [maxDistance, setMaxDistance] = useState(preferences?.max_distance);

  useEffect(() => {
    setPending(false);
    setLocalOnly(preferences?.local_only);
    setMaxDistance(preferences?.max_distance);
  }, [preferences, props]);

  const onSubmit = (event: any) => {
    event.preventDefault();

    setPending(true);

    const updatedPreferences: OwnerPreferences = deepCopy(preferences);

    updatedPreferences.local_only = localOnly;
    updatedPreferences.max_distance = maxDistance;

    preferencesManager
      .updatePreferences(updatedPreferences)
      .then(() => props.onClose(true))
      .catch(() => setPending(false));
  };

  const onIncludePartialChange = (event: CheckboxChangeEvent) => {
    preferences.include_partial = event.target.checked;
  };

  return useObserver(() => (
    <Modal
      show={props.show}
      maxWidth={"sm"}
      onFormSubmit={onSubmit}
      headerChildren={<strong>Preferences</strong>}
      contentChildren={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              label={"Include sitters traveling for a portion of the dates."}
              control={
                <Checkbox
                  checked={preferences?.include_partial}
                  onChange={onIncludePartialChange}
                  color={"primary"}
                />
              }
            />
          </Grid>
        </Grid>
      }
      footerChildren={
        <React.Fragment>
          <Button onClick={() => props.onClose(false)} disabled={pending}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            variant={"contained"}
            color={"primary"}
            disabled={pending}
          >
            Save
          </Button>
        </React.Fragment>
      }
    />
  ));
}
