import React, { useState } from "react";
import { TabsProps } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AlbumModalPreviewTab from "./AlbumModalPreviewTab";
import AlbumModalEditTab from "./AlbumModalEditTab";
import DialogContent from "@material-ui/core/DialogContent";

export interface IAlbumModalBodyProps {
  secondary?: boolean;
}

export default function AlbumModalBody(props: IAlbumModalBodyProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setSelectedTab(newTab);
  };

  const tabsProps: TabsProps = {
    value: selectedTab,
    indicatorColor: props.secondary ? "secondary" : "primary",
    textColor: props.secondary ? "secondary" : "primary",
  };

  return (
    <DialogContent>
      <Tabs {...tabsProps} onChange={onTabChange}>
        <Tab label={"Edit"} />
        <Tab label={"Preview"} />
      </Tabs>

      <TabPanel index={selectedTab} />
    </DialogContent>
  );
}

function TabPanel({ index }: { index: number }) {
  switch (index) {
    case 0:
      return <AlbumModalEditTab />;

    case 1:
      return <AlbumModalPreviewTab />;

    default:
      return null;
  }
}
