import React from "react";
import { Button } from "@material-ui/core";
import { IMenuListProps } from "./models";

export default function MenuButtonList(props: IMenuListProps) {
  return (
    <React.Fragment>
      {props.menuItems.map((menuItem) => (
        <Button
          onClick={menuItem.onClick}
          style={{ marginRight: "1rem" }}
          key={menuItem.label}
        >
          {menuItem.label}
        </Button>
      ))}
    </React.Fragment>
  );
}
