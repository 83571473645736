import { action, observable } from "mobx";
import { SitterPreferences } from "./models";
import { HttpClient } from "../HttpClient";

export default class SitterPreferencesManager {
  @observable preferences: SitterPreferences = null;
  @observable loading: boolean = true;

  @action
  private setPreferences = (preferences: SitterPreferences) => {
    this.preferences = preferences;
    this.loading = false;
  };

  getHomeTypeDisplayNameList = () => {
    if (this.preferences.home_types.length === 0) {
      return "Any";
    } else {
      const descriptors = this.preferences.home_types.split("||");
      const displayNames = this.preferences.home_type_options
        .filter((option) => descriptors.includes(option.value))
        .map((option) => option.label);

      return displayNames.join(", ");
    }
  };

  getPetsDisplayNameList = () => {
    if (this.preferences.pet_types.length === 0) {
      return "Any";
    } else {
      const descriptors = this.preferences.pet_types.split(",");
      const displayNames = this.preferences.pet_options
        .filter((option) => descriptors.includes(option.value))
        .map((option) => option.label);

      return displayNames.join(", ");
    }
  };

  getAmenitiesDisplayNameList = () => {
    if (this.preferences.amenities.length === 0) {
      return "Any";
    } else {
      const descriptors = this.preferences.amenities.split(",");
      const displayNames = this.preferences.amenity_options
        .filter((option) => descriptors.includes(option.value))
        .map((option) => option.label);

      return displayNames.join(", ");
    }
  };

  loadPreferences = (): Promise<void> => {
    if (Boolean(this.preferences)) {
      return Promise.resolve(null);
    }

    this.loading = true;
    return HttpClient.get<SitterPreferences>(`sitter/preferences/`).then(
      this.setPreferences
    );
  };

  updatePreferences = (
    updatedPreferences: SitterPreferences
  ): Promise<void> => {
    this.loading = true;
    return HttpClient.put<SitterPreferences>(
      `sitter/preferences/`,
      updatedPreferences
    ).then(this.setPreferences);
  };
}
