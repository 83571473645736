import React, { FormEvent, useEffect, useState } from "react";
import { usePetStore } from "../../hooks";
import { TextFieldChangeEvent } from "../../models";
import { useObserver } from "mobx-react";
import Modal from "../../misc/Modal";
import { IPet, IPetType } from "../../../storeroom/pets/models";
import { deepCopy } from "../../../utils";
import {
  Button,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export interface IPetDetailsModal {
  show: boolean;
  onClose: () => void;
}

export default function PetDetailsModal(props: IPetDetailsModal) {
  const petStore = usePetStore();
  const pet = petStore.pet;

  const [name, setName] = useState(pet?.name);
  const [petType, setPetType] = useState(pet?.type);
  const [breed, setBreed] = useState(pet?.breed);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.show) {
      petStore.loadPetCategories();
      setSubmitted(false);
      setError("");

      setName(pet?.name);
      setPetType(pet?.type);
      setBreed(pet?.breed);
    }
  }, [props.show, pet, petStore]);

  const onNameChange = (event: TextFieldChangeEvent) => {
    setName(event.target.value);
  };

  const onTypeChange = (event: any) => {
    setPetType(event.target.value);
  };

  const onBreedChange = (event: TextFieldChangeEvent) => {
    setBreed(event.target.value);
  };

  const onError = () => {
    setSubmitted(false);
    setError("Error saving pet details");
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const updatedPet: IPet = deepCopy(petStore.pet);
    updatedPet.name = name;
    updatedPet.type = petType;
    updatedPet.breed = breed;

    setSubmitted(true);
    petStore.submitUpdates(updatedPet).then(props.onClose).catch(onError);
  };

  const onDelete = () => {
    petStore
      .delete(petStore.pet.id)
      .then(props.onClose)
      .catch(() => {
        setSubmitted(false);
        setError("Pet could not be deleted.");
      });
  };

  return useObserver(() => {
    const pet = petStore.pet;
    const petTypes = petStore.petTypeList;

    return pet && petTypes ? (
      <Modal
        show={props.show}
        maxWidth={"xs"}
        onFormSubmit={onSubmit}
        headerChildren={<strong>Basic Information</strong>}
        contentChildren={
          <React.Fragment>
            {error && (
              <Alert severity={"error"} style={{ marginBottom: "1rem" }}>
                {error}
              </Alert>
            )}

            <FormGroup style={{ margin: "0 0 1em 0" }}>
              <TextField
                fullWidth
                value={name}
                onChange={onNameChange}
                label={"Name"}
              />
            </FormGroup>

            <FormGroup>
              <FormControl>
                <InputLabel htmlFor={"petTypeSelect"}>Type</InputLabel>
                <Select
                  onChange={onTypeChange}
                  value={petType}
                  fullWidth
                  inputProps={{ id: "petCategorySelect" }}
                >
                  {petTypes.map((petType: IPetType) => (
                    <MenuItem value={petType.name} key={petType.name}>
                      {petType.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>

            <FormGroup style={{ margin: "1em 0" }}>
              <TextField
                label={"Breed"}
                fullWidth
                value={breed}
                onChange={onBreedChange}
              />
            </FormGroup>
          </React.Fragment>
        }
        footerChildren={
          <React.Fragment>
            <IconButton onClick={onDelete}>
              <Icon fontSize={"small"}>delete</Icon>
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <Button onClick={props.onClose} disabled={submitted}>
              Cancel
            </Button>
            <Button
              type={"submit"}
              variant={"contained"}
              color={"primary"}
              disabled={submitted}
            >
              Submit
            </Button>
          </React.Fragment>
        }
      />
    ) : null;
  });
}
