import React, { useContext, useState } from "react";
import {
  IReservationForGuest,
  IReservationForHost,
  ReservationStatus,
} from "../../../storeroom/reservations/models";
import { GuestReservationContext, HostReservationContext } from "../contexts";
import { Alert } from "@material-ui/lab";
import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { useReservationStore } from "../../hooks";
import { ReservationStore } from "../../../storeroom/reservations/ReservationStore";
import HomeReviewModal from "../../reviews/modals/HomeReviewModal";
import { useGuestReservation, useHostReservation } from "../hooks";
import { IHomeReview, ISitterReview } from "../../../storeroom/reviews/models";
import SitterReviewModal from "../../reviews/modals/SitterReviewModal";

export function RequestedByGuestBannerForGuest() {
  const reservation: IReservationForGuest = useContext(GuestReservationContext);
  const reservationStore: ReservationStore = useReservationStore();

  return (
    <Alert
      severity={"warning"}
      action={
        <Button
          color={"inherit"}
          variant={"outlined"}
          size={"small"}
          onClick={reservationStore.retract}
        >
          Retract
        </Button>
      }
    >
      <Typography style={{ fontWeight: "bold" }}>
        {reservation.status_display}
      </Typography>
      <Typography variant={"body2"}>
        {reservation.status === ReservationStatus.requested
          ? "Pending review by host."
          : "Request read. Awaiting host decision."}
      </Typography>
    </Alert>
  );
}

export function RequestedByGuestBannerForHost() {
  const reservation: IReservationForHost = useContext(HostReservationContext);
  const reservationStore: ReservationStore = useReservationStore();

  return (
    <Alert
      severity={"warning"}
      action={
        <ButtonGroup color={"inherit"} size={"small"}>
          <Button onClick={reservationStore.reject}>Reject</Button>
          <Button onClick={reservationStore.accept}>Approve</Button>
        </ButtonGroup>
      }
    >
      <Typography style={{ fontWeight: "bold" }}>
        {reservation.status_display}
      </Typography>
      <Typography variant={"body2"}>Pending your review.</Typography>
    </Alert>
  );
}

export function RejectedBanner() {
  return (
    <Alert severity={"error"}>
      <Typography style={{ fontWeight: "bold" }}>Rejected</Typography>
    </Alert>
  );
}

export function CanceledBanner() {
  return (
    <Alert severity={"error"}>
      <Typography style={{ fontWeight: "bold" }}>Canceled</Typography>
    </Alert>
  );
}

export function CanceledBannerForHost() {
  return (
    <Alert severity={"error"}>
      <Typography style={{ fontWeight: "bold" }}>Canceled by Sytter</Typography>
    </Alert>
  );
}

export function ApprovedBannerForGuest() {
  return (
    <Alert severity={"success"}>
      <Typography style={{ fontWeight: "bold" }}>Accepted</Typography>
      <Typography variant={"body2"}>
        Congrats! Your request for a reservation has been accepted! Be sure to
        review all home and pet care routines with the owner. This is very
        important! The safety and comfort of our members and pets are at the
        heart of Jetsyt. Always follow our Member Code of Conduct.
      </Typography>
    </Alert>
  );
}

export function ApprovedBannerForHost() {
  return (
    <Alert severity={"success"}>
      <Typography style={{ fontWeight: "bold" }}>Accepted</Typography>
      <Typography variant={"body2"}>
        Great! You've accepted the reservation. The safety and comfort of our
        members and pets are at the heart of Jetsyt. Always follow our Member
        Code of Conduct.
      </Typography>
    </Alert>
  );
}

export function NeedsHomeReviewBanner() {
  const reservationStore = useReservationStore();
  const reservation = useGuestReservation();
  const [show, setShow] = useState(false);

  const onClose = (homeReview: IHomeReview) => {
    if (homeReview !== null) {
      reservationStore.reload();
    }
    setShow(false);
  };

  return (
    <Alert
      severity={"warning"}
      action={
        <Button
          color={"inherit"}
          variant={"outlined"}
          size={"small"}
          onClick={() => setShow(true)}
        >
          Review
        </Button>
      }
    >
      <Typography style={{ fontWeight: "bold" }}>Review Needed</Typography>
      <Typography variant={"body2"}>
        We hope you enjoyed your stay! Please leave a review.
      </Typography>
      <HomeReviewModal
        show={show}
        onClose={onClose}
        reservationID={reservation.id}
      />
    </Alert>
  );
}

export function NeedsSitterReviewBanner() {
  const reservationStore = useReservationStore();
  const reservation = useHostReservation();
  const [show, setShow] = useState(false);

  const onClose = (review: ISitterReview) => {
    if (review !== null) {
      reservationStore.reload();
    }
    setShow(false);
  };

  return (
    <Alert
      severity={"warning"}
      action={
        <Button
          color={"inherit"}
          variant={"outlined"}
          size={"small"}
          onClick={() => setShow(true)}
        >
          Review
        </Button>
      }
    >
      <Typography style={{ fontWeight: "bold" }}>Review Needed</Typography>
      <Typography variant={"body2"}>
        We hope the sitter served you well! Please leave a review.
      </Typography>
      <SitterReviewModal
        show={show}
        onClose={onClose}
        reservationID={reservation.id}
        sitterID={reservation.guest.sitter_id}
      />
    </Alert>
  );
}
