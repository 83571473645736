import React from "react";
import { useConversationStore } from "../hooks";
import { ICrumbProps } from "../base/Crumbs";
import { privateRoutePaths } from "../../routes/PrivateRoutes";
import PageToolbar from "../base/PageToolbar";
import { useObserver } from "mobx-react";
import { IPageToolbarAction } from "../base/PageToolbarActions";
import { useHistory } from "react-router-dom";

export function ConversationPageLoadingToolbar() {
  const crumbs: ICrumbProps = {
    currentLabel: "Loading...",
    links: [
      { label: "Conversations", url: privateRoutePaths.conversationList() },
    ],
  };

  return <PageToolbar crumbs={crumbs} actions={[]} />;
}

export default function ConversationPageToolbar() {
  const history = useHistory();
  const conversationStore = useConversationStore();

  return useObserver(() => {
    const { conversation } = conversationStore;

    const crumbs: ICrumbProps = {
      currentLabel: conversation.partner.name,
      links: [
        { label: "Conversations", url: privateRoutePaths.conversationList() },
      ],
    };

    const actions: Array<IPageToolbarAction> = [
      {
        label: "View Reservation",
        onClick: () =>
          history.push(
            privateRoutePaths.reservationDetails(conversation.reservation)
          ),
        icon: "wysiwyg",
      },
    ];

    return <PageToolbar crumbs={crumbs} actions={actions} />;
  });
}
