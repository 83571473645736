import React, { useEffect, useState } from "react";
import { useVacancyStore } from "../../hooks";
import { Section, SectionProps } from "../../base/Section";
import { useObserver } from "mobx-react";
import VacancyEditModal from "../modals/VacancyEditModal";
import VacancyStore from "../../../storeroom/vacancies/VacancyStore";
import { Typography } from "@material-ui/core";
import { humanizeDateRange } from "../../../formattingService";

export default function VacancyDetailsSection() {
  const vacancyStore = useVacancyStore();
  const vacancy = vacancyStore.vacancy;

  const [store, setStore] = useState<VacancyStore>(null);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    if (showEditForm) {
      setStore(new VacancyStore(vacancy));
    }
  }, [showEditForm, vacancy]);

  const onModalClose = (vacancyID: number) => {
    if (Boolean(vacancyID)) {
      vacancyStore.loadVacancy(vacancyID);
    }

    setShowEditForm(false);
  };

  const sectionProps: SectionProps = { header: "Details" };

  const hasActiveReservations =
    Boolean(vacancy.accepted_reservation) ||
    vacancy.current_requests?.length > 0;

  if (!hasActiveReservations) {
    sectionProps.actionIcon = "edit";
    sectionProps.actionLabel = "Edit Details";
    sectionProps.onActionClick = () => setShowEditForm(true);
  }

  return useObserver(() => {
    return (
      <Section {...sectionProps}>
        {store && (
          <VacancyEditModal
            store={store}
            show={showEditForm}
            onClose={onModalClose}
          />
        )}

        <Typography variant={"body1"}>
          {humanizeDateRange(vacancy.start_date, vacancy.end_date)}
        </Typography>

        <Typography variant={"body1"}>
          {vacancy.full_rental_only
            ? "Only allow sitters that can commit to the full stay"
            : "Allow sitters to request stays for any portion of the vacancy"}
        </Typography>
      </Section>
    );
  });
}
