import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles, Theme } from "@material-ui/core";

export const useOwnerStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  })
);

export const useSitterStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  })
);
