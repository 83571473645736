import React from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { RouterProps } from "../../base/baseModels";
import { IVacancySearchResultDetails } from "../../../storeroom/homes/searchService/models";
import { ICrumbProps } from "../../base/Crumbs";
import PageToolbar from "../../base/PageToolbar";
import { VacancySearchResultProvider } from "../contexts";
import ListingPreviewTabs from "../preview/ListingPreviewTabs";
import { useDocumentTitle } from "../../hooks";

interface Props extends RouterProps {}

interface State {
  vacancy: IVacancySearchResultDetails;
}

class MyListingPreviewPage extends React.Component<any, State> {
  static contextType = StoreContext;

  constructor(props: Props) {
    super(props);
    this.state = { vacancy: null };
  }

  initialize = () => {
    this.context.listingStore
      .preview()
      .then((response: IVacancySearchResultDetails) =>
        this.setState({ vacancy: response })
      );
  };

  componentDidMount(): void {
    this.initialize();
  }

  render() {
    const { vacancy } = this.state;

    return vacancy ? (
      <VacancySearchResultProvider value={vacancy}>
        <PreviewToolbar />
        <ListingPreviewTabs />
      </VacancySearchResultProvider>
    ) : (
      <div>
        <PreviewToolbar />
      </div>
    );
  }
}

function PreviewToolbar() {
  useDocumentTitle("Listing Preview");

  const crumbProps: ICrumbProps = {
    currentLabel: "Preview",
    links: [
      {
        label: "My Home",
        url: "/home",
      },
      {
        label: "Listing",
        url: "/listing",
      },
    ],
  };

  return <PageToolbar crumbs={crumbProps} actions={[]} />;
}

export default MyListingPreviewPage;
