import React from "react";
import { TextField } from "@material-ui/core";
import { useObserver } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import { usePetBuilderStore } from "../hooks";

export default function PetDescriptionForm() {
  const petDescStore = usePetBuilderStore().getDescStepStore();

  const onDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    petDescStore.setDescription(event.target.value);
  };

  return useObserver(() => {
    return (
      <div>
        <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
          Description
        </Typography>

        <DialogContentText>
          Help sytters get to know your pet. Include information like
          personality, likes, dislikes, and special quirks.
        </DialogContentText>

        <div style={{ height: "1em" }} />

        <TextField
          label={"Description"}
          fullWidth
          defaultValue={petDescStore.description}
          onChange={onDescChange}
          multiline
          rows={18}
          autoFocus
        />
      </div>
    );
  });
}
