import React from "react";
import { observer } from "mobx-react";
import { RouterProps } from "../../base/baseModels";
import AlbumSection from "../../albums/AlbumSection";
import { Section, sectionBodyStyle } from "../../base/Section";
import {
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../../storeroom/StoreContext";
import SitterSearchResultDetailsToolbar, {
  SitterSearchResultDetailsLoadingToolbar,
} from "../toolbar/SitterSearchResultDetailsToolbar";
import LoadingSection from "../../base/LoadingSection";
import { SitterSearchResultDetails } from "../../../storeroom/sitters/models";
import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { ITestimonial } from "../../../storeroom/reviews/models";
import { Alert } from "@material-ui/lab";
import AlertTitle from "@material-ui/lab/AlertTitle";

interface Props extends RouterProps {}

interface ISectionProps {
  result: SitterSearchResultDetails;
}

const sectionBodyStyleOverride = {
  ...sectionBodyStyle,
  fontSize: "1em",
};

@observer
class SitterSearchResultDetailsPage extends React.Component<Props, any> {
  static contextType = StoreContext;

  componentDidMount(): void {
    this.loadSearchDetails();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadSearchDetails();
    }
  }

  loadSearchDetails = () => {
    this.context.sitterSearchManager
      .loadDetails(this.props.match.params.id)
      .then();
  };

  render() {
    const searchResultDetails = this.context.sitterSearchManager
      .searchResultDetails;

    return searchResultDetails ? (
      <div>
        <SitterSearchResultDetailsToolbar />

        <Section header={""} fullWidth={true}>
          <Alert severity={"error"}>
            <AlertTitle>Sorry!</AlertTitle>
            The feature to send a request to sytters is not completed yet. We
            are working on it and will make it available as soon as possible.
          </Alert>
        </Section>

        <AlbumSection
          album={searchResultDetails.sitter.album}
          viewOnly={true}
          headerOverride={searchResultDetails.sitter.name}
        />

        <Section header={"Description"}>
          <Typography style={sectionBodyStyleOverride}>
            {searchResultDetails.sitter.sitter_description}
          </Typography>
        </Section>

        <Section header={"Preferred Pets"}>
          <Typography style={sectionBodyStyleOverride}>
            {searchResultDetails.sitter.preferences.pet_types.length === 0
              ? "Any"
              : searchResultDetails.sitter.preferences.pet_options
                  .filter((petOption: any) =>
                    searchResultDetails.sitter.preferences.pet_types.includes(
                      petOption.value
                    )
                  )
                  .map((petOption: any) => petOption.label)
                  .join(", ")}
          </Typography>
        </Section>

        <SitterReviewsSection result={searchResultDetails} />
      </div>
    ) : (
      <div>
        <SitterSearchResultDetailsLoadingToolbar />
        <LoadingSection header={"Pictures of Sitter"} cards={true} />
        <LoadingSection header={"Description"} />
        <LoadingSection header={"Preferred Pets"} cards={true} />
      </div>
    );
  }
}

export default withRouter(SitterSearchResultDetailsPage);

interface IReadOnlyTab {
  title: string;
  icon: string;
  subtitle: string | number;
  color: "disabled" | "primary" | "secondary";
}

interface IReadOnlyTabsProps {
  tabs: Array<IReadOnlyTab>;
}

function ReadOnlyTabs(props: IReadOnlyTabsProps) {
  const tabs = props.tabs;
  const gridSize = tabs.length === 3 ? 4 : 3;

  return (
    <Grid container spacing={0} style={{ textAlign: "center" }}>
      {tabs.map((average: IReadOnlyTab) => (
        <Grid item xs={gridSize}>
          <Typography variant={"body2"}>{average.title}</Typography>
          <div style={{ height: "1rem" }} />
          <div>
            <Icon fontSize={"large"} color={average.color}>
              {average.icon}
            </Icon>
          </div>
          {average.subtitle}
        </Grid>
      ))}
    </Grid>
  );
}

function SitterReviewsSection(props: ISectionProps) {
  const { averages, testimonials } = props.result.sitter.review_summary;

  const averagesList: Array<IReadOnlyTab> = [
    {
      title: "Rating",
      icon: "star",
      subtitle: `${averages.rating} / 5`,
      color: "primary",
    },
    {
      title: "Pet Care",
      icon: "thumb_up",
      subtitle: `${averages.pet_care}%`,
      color: averages.pet_care > 50 ? "primary" : "disabled",
    },
    {
      title: "House Rules",
      icon: "thumb_up",
      subtitle: `${averages.house_rules}%`,
      color: averages.house_rules > 50 ? "primary" : "disabled",
    },
    {
      title: "Communication",
      icon: "thumb_up",
      subtitle: `${averages.communication}%`,
      color: averages.communication > 50 ? "primary" : "disabled",
    },
  ];

  return averages.rating === null ? (
    <Section header={"Reviews"}>This sitter has not been reviewed yet.</Section>
  ) : (
    <Section header={"Reviews"}>
      <ReadOnlyTabs tabs={averagesList} />

      <Typography variant={"body1"} style={{ marginTop: "3rem" }}>
        Recent testimonials:
      </Typography>

      <List>
        {testimonials.map((testimonial: ITestimonial, index: number) => (
          <ListItem alignItems={"flex-start"} key={index}>
            <ListItemAvatar>
              <Avatar src={testimonial.reviewer.avatar} />
            </ListItemAvatar>

            <ListItemText
              primary={
                <Typography variant={"body1"}>
                  {testimonial.comments}
                </Typography>
              }
              secondary={testimonial.reviewer.name}
            />
          </ListItem>
        ))}
      </List>
    </Section>
  );
}
