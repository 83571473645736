import {
  IReservationForGuest,
  IReservationForHost,
} from "../../storeroom/reservations/models";
import { useContext } from "react";
import { GuestReservationContext, HostReservationContext } from "./contexts";

export function useGuestReservation(): IReservationForGuest {
  return useContext(GuestReservationContext);
}

export function useHostReservation(): IReservationForHost {
  return useContext(HostReservationContext);
}
