import React from "react";
import BasePublicPage from "./BasePublicPage";
import { Parallax } from "react-parallax";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import primaryPhoto from "../../images/frontPage/primary.jpeg";
import forPetParentsPhoto from "../../images/frontPage/forPetParents.jpeg";
import forTravelingSittersPhoto from "../../images/frontPage/forTravelingSitters.jpg";
import forPetsPhoto from "../../images/frontPage/forPets.jpg";
import { useTabletOrLarger } from "../hooks";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { publicRoutePaths } from "../../routes/PublicRoutes";

export default function FrontPage() {
  return (
    <BasePublicPage>
      <ParallaxSection />
      <MeetJetsytSection />
    </BasePublicPage>
  );
}

function ParallaxSection() {
  const history = useHistory();

  const onLoginClick = () => {
    history.push(publicRoutePaths.login());
  };

  const onSignUpClick = () => {
    history.push(publicRoutePaths.register());
  };

  const containerStyle: React.CSSProperties = {
    height: "60vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  const textContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    textAlign: "center",
    color: "white",
  };

  const headingStyle: React.CSSProperties = {
    fontFamily: "Playfair Display",
    fontWeight: 700,
  };

  const buttonStyle: React.CSSProperties = {
    width: "100%",
  };

  return (
    <Parallax bgImage={primaryPhoto} strength={200}>
      <div style={containerStyle}>
        <Container maxWidth={"md"} style={textContainerStyle}>
          <Container
            maxWidth={"xs"}
            disableGutters={true}
            style={{ marginLeft: 0 }}
          >
            <Typography variant={"h3"} style={headingStyle}>
              Connecting Visiting Pet Sitters and Traveling Pet Parents
            </Typography>

            <div style={{ height: "2rem" }} />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  style={buttonStyle}
                  size={"large"}
                  onClick={onLoginClick}
                >
                  Member Log In
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant={"contained"}
                  style={buttonStyle}
                  size={"large"}
                  onClick={onSignUpClick}
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </div>
    </Parallax>
  );
}

interface IMeetJetsytCardProps {
  image: any;
  title: string;
  body: string;
}

function MeetJetsytCard(props: IMeetJetsytCardProps) {
  const isWide = useTabletOrLarger();
  const height = isWide ? 200 : 400;

  const titleStyle: React.CSSProperties = {
    textAlign: isWide ? "left" : "center",
    fontWeight: 700,
  };

  const bodyStyle: React.CSSProperties = {
    textAlign: isWide ? "left" : "center",
  };

  return (
    <Card elevation={0}>
      <CardMedia image={props.image} style={{ height: height }} />
      <CardContent style={{ padding: "16px 0" }}>
        <Typography variant={"h6"} style={titleStyle}>
          {props.title}
        </Typography>

        <Typography variant={"body2"} style={bodyStyle}>
          {props.body}
        </Typography>
      </CardContent>
    </Card>
  );
}

function MeetJetsytSection() {
  const isWide = useTabletOrLarger();

  const headerStyle: React.CSSProperties = {
    textAlign: isWide ? "left" : "center",
  };

  return (
    <Container
      maxWidth={"md"}
      style={{ marginTop: "6rem", marginBottom: "6rem" }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} style={headerStyle}>
          <Typography variant={"h4"}>Meet Jetsyt</Typography>
          <Typography
            variant={"body1"}
            style={{ fontSize: "1.2rem", fontWeight: 100 }}
          >
            Jetsyt helps traveling pet parents find free in-home pet care in
            exchange for free accommodations for visiting pet sitters. Become
            part of the Jetsyt pet loving community.
          </Typography>
          <div style={{ height: "2rem" }} />
        </Grid>

        <Grid item xs={12} md={4}>
          <MeetJetsytCard
            image={forPetParentsPhoto}
            title={"For Pet Parents"}
            body={
              "Pet parents have peace of mind knowing their pet is cared for in the comfort of their own home."
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <MeetJetsytCard
            image={forTravelingSittersPhoto}
            title={"For Traveling Sitters"}
            body={
              "Sitters are given free accommodations and a furry companion during their travels."
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <MeetJetsytCard
            image={forPetsPhoto}
            title={"For Pets"}
            body={
              "And, most importantly, pets are where they want to be — at home."
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
}
