import { IAmenity, IHomeAmenity } from "./models";
import { action, observable } from "mobx";
import { HttpClient } from "../HttpClient";

export class AmenityStore {
  @observable homeAmenityList: Array<IHomeAmenity> = undefined;
  amenityList: Array<IAmenity> = undefined;

  @action
  private setHomeAmenityList = (newList: Array<IHomeAmenity>) => {
    this.homeAmenityList = newList;
  };

  @action
  private setAmenityList = (newList: Array<IAmenity>) => {
    this.amenityList = newList;
  };

  @action
  private postAdd = (newHomeAmenity: IHomeAmenity) => {
    this.homeAmenityList = [...this.homeAmenityList, newHomeAmenity];
  };

  @action
  private postUpdate = (updatedAmenity: IHomeAmenity) => {
    this.homeAmenityList = this.homeAmenityList.map((amenity) =>
      amenity.id === updatedAmenity.id ? updatedAmenity : amenity
    );
  };

  @action
  private postRemove = (id: number) => {
    this.homeAmenityList = this.homeAmenityList.filter(
      (amenity) => amenity.id !== id
    );
  };

  loadAmenityList = (error?: (error: Response) => void) => {
    if (this.amenityList === undefined) {
      HttpClient.get<Array<IAmenity>>("amenities/")
        .then((response) => this.setAmenityList(response))
        .catch(error);
    }
  };

  loadHomeAmenityList = (error?: (error: Response) => void) => {
    if (this.homeAmenityList === undefined) {
      HttpClient.get<Array<IHomeAmenity>>("home_amenities/")
        .then((response) => this.setHomeAmenityList(response))
        .catch(error);
    }
  };

  add = (newAmenity: IHomeAmenity, error?: (error: Response) => void) => {
    HttpClient.post<IHomeAmenity>("home_amenities/", newAmenity)
      .then((response) => this.postAdd(response))
      .catch(error);
  };

  update = (
    updatedAmenity: IHomeAmenity,
    error?: (error: Response) => void
  ) => {
    HttpClient.put<IHomeAmenity>(
      `home_amenities/${updatedAmenity.id}/`,
      updatedAmenity
    )
      .then((response) => this.postUpdate(response))
      .catch(error);
  };

  remove = (id: number, error?: (error: Response) => void) => {
    HttpClient.delete(`home_amenities/${id}/`)
      .then(() => this.postRemove(id))
      .catch(error);
  };
}
