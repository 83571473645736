import React, { FormEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { StoreContext } from "../../../storeroom/StoreContext";
import { observer } from "mobx-react";
import { reaction } from "mobx";
import { IHome } from "../../../storeroom/homes/models";

interface Props {
  show: boolean;
  onClose: () => void;
}

interface State {
  submitted: boolean;
  wipHome: IHome;
  error: string;
  disposer: any;
}

@observer
class CheckInInstructionFormDialog extends React.Component<Props, State> {
  static contextType = StoreContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      submitted: false,
      wipHome: null,
      error: "",
      disposer: undefined,
    };
  }

  componentDidMount(): void {
    this.initializeHome();
    this.setState({ disposer: this.homeUpdateReaction() });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!prevProps.show && this.props.show) {
      this.initializeHome();
    }
  }

  componentWillUnmount(): void {
    if (this.state.disposer !== undefined) {
      this.state.disposer();
    }
  }

  initializeHome = () => {
    const wipHome = JSON.parse(JSON.stringify(this.context.homeStore.home));
    this.setState({ wipHome: wipHome });
  };

  homeUpdateReaction = () => {
    return reaction(
      () => this.context.homeStore.home,
      () => {
        if (this.state.submitted) {
          this.onClose();
        }
      }
    );
  };

  onClose = () => {
    this.setState({ submitted: false }, () => {
      this.props.onClose();
    });
  };

  onError = () => {
    this.setState({ error: "Invalid changes.", submitted: false });
  };

  onSubmit = (event: FormEvent) => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      this.context.homeStore.update(this.state.wipHome, this.onError);
    });
  };

  onInstrChange = (event: any) => {
    const home = this.state.wipHome;
    home.check_in_instructions = event.target.value;
    this.setState({ wipHome: home });
  };

  render() {
    const home = this.context.homeStore.home;
    const { submitted } = this.state;

    return home !== undefined ? (
      <Dialog open={this.props.show} maxWidth={"md"} fullWidth={true}>
        <form onSubmit={this.onSubmit} autoComplete={"off"}>
          <DialogTitle>Check-in Instructions</DialogTitle>

          <DialogContent>
            <TextField
              label={"Check-in Instructions"}
              fullWidth
              defaultValue={home.check_in_instructions}
              onChange={this.onInstrChange}
              multiline
              rows={18}
            />
          </DialogContent>

          <DialogActions style={{ marginTop: "1em" }}>
            <Button onClick={this.onClose} disabled={submitted}>
              Cancel
            </Button>
            <Button
              type={"submit"}
              variant={"contained"}
              color={"primary"}
              disabled={submitted}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ) : (
      <div />
    );
  }
}

export default CheckInInstructionFormDialog;
