import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConversationStore } from "../../storeroom/conversations/ConversationStore";
import { ConversationStoreProvider } from "../../storeroom/StoreContext";
import ConversationPageToolbar, {
  ConversationPageLoadingToolbar,
} from "./ConversationPageToolbar";
import { useObserver } from "mobx-react";
import { ConversationContext } from "./contexts";
import MessageList from "./MessageList";
import { Container } from "@material-ui/core";
import SendMessageForm from "./SendMessageForm";
import { useConversationListStore } from "../hooks";

export default function ConversationPage() {
  const { id } = useParams();
  const conversationListStore = useConversationListStore();
  const [conversationStore, setConversationStore] = useState<ConversationStore>(
    null
  );

  useEffect(() => {
    setConversationStore(new ConversationStore(id));
  }, [id]);

  if (conversationStore !== null) {
    if (!conversationStore.loading) {
      if (Boolean(conversationListStore.conversations)) {
        conversationListStore.updateUnreadFlag(
          conversationStore.conversation.id
        );
      }
    }
  }

  return useObserver(() => {
    return conversationStore === null ? (
      <ConversationPageLoadingToolbar />
    ) : conversationStore.loading ? (
      <ConversationPageLoadingToolbar />
    ) : (
      <ConversationStoreProvider
        value={{ conversationStore: conversationStore }}
      >
        <ConversationPageToolbar />

        <ConversationContext.Provider value={conversationStore.conversation}>
          <Container maxWidth={"md"}>
            <MessageList />
          </Container>
        </ConversationContext.Provider>

        <SendMessageForm />
      </ConversationStoreProvider>
    );
  });
}
