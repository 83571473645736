import React from "react";
import { action, observable } from "mobx";
import { IAlbum, IAlbumSaveState, IGalleryItem, IPicture } from "./models";
import AlbumsHttpClient from "./AlbumsHttpClient";
import PictureHttpClient from "./PictureHttpClient";

export class AlbumStore {
  @observable loading: boolean = false;
  @observable album: IAlbum = null;
  @observable galleryItems: Array<IGalleryItem> = [];
  @observable modified: boolean = false;
  @observable saveState: IAlbumSaveState = {
    saving: false,
    deletePictureProgress: 0,
    uploadPictureProgress: 0,
    updatePictureProgress: 0,
  };

  private readonly _albumID: number;
  private _album: IAlbum;
  private _picsToRemove: Array<IPicture> = [];

  constructor(albumID: number) {
    this._albumID = albumID;
    this.initialize();
  }

  private initialize = () => {
    if (!this.loading) {
      this.loading = true;
      AlbumsHttpClient.retrieve(this._albumID).then((album: IAlbum) => {
        this._album = JSON.parse(JSON.stringify(album));
        this.reset();
      });
    }
  };

  getMaxPictureCount = () => {
    switch (this._album?.subject_model) {
      case "Home":
        return 10;

      case "Profile":
        return 1;

      default:
        return 3;
    }
  };

  @action private setAlbum = (newAlbum: IAlbum) => {
    this.album = newAlbum;
    this.setGalleryItems();
    this.loading = false;
  };

  @action reset = () => {
    this.setAlbum(JSON.parse(JSON.stringify(this._album)));
    this._picsToRemove = [];
    this.modified = false;
    this.saveState = {
      saving: false,
      deletePictureProgress: 0,
      uploadPictureProgress: 0,
      updatePictureProgress: 0,
    };
  };

  private setGalleryItems = () => {
    this.galleryItems = this.album.pictures.map((picture: IPicture) => ({
      src: picture.url,
      width: picture.width,
      height: picture.height,
    }));
  };

  getPreviewItems = (): Array<IGalleryItem> => {
    return JSON.parse(JSON.stringify(this.galleryItems.slice(0, 3)));
  };

  changeSequence = (oldPosition: number, newPosition: number) => {
    this.album.pictures = this.album.pictures
      .map((picture: IPicture, index: number) =>
        reSequencePicture(picture, index, oldPosition, newPosition)
      )
      .sort(sortPictures);

    this.modified = true;
    this.setGalleryItems();
  };

  removePicture = (index: number) => {
    const picToRemove = JSON.parse(JSON.stringify(this.album.pictures[index]));
    this._picsToRemove.push(picToRemove);

    const filteredPics = this.album.pictures.filter(
      (pic, subIndex) => subIndex !== index
    );
    this.album.pictures = filteredPics.map((pic, subIndex) => ({
      ...pic,
      sequence: subIndex,
    }));

    this.modified = true;
    this.setGalleryItems();
  };

  removePictureBySrc = (src: string) => {
    const srcList = this.album.pictures.map((pic) => pic.url);
    const indexToRemove = srcList.indexOf(src);
    if (indexToRemove >= 0) {
      this.removePicture(indexToRemove);
    }
  };

  addPictures = (files: Array<File>) => {
    this.addPicture(files, 0);
  };

  private addPicture = (files: Array<File>, index: number) => {
    if (index < files.length) {
      const file = files[index];

      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      img.onload = () => {
        this.album.pictures.push({
          id: null,
          url: img.src,
          picture: file,
          subject: this._albumID,
          uploaded_by: this.album.owner,
          sequence: this.album.pictures.length,
          width: img.width,
          height: img.height,
        });

        this.addPicture(files, index + 1);
      };
    } else if (files.length > 0) {
      this.modified = true;
      this.setGalleryItems();
    }
  };

  save = (callback?: () => void) => {
    this.saveState.saving = true;
    this.deletePictures(0, callback);
  };

  private deletePictures = (index: number, callback?: () => void) => {
    const pictureCount = this._picsToRemove.length;
    this.saveState.deletePictureProgress = 100 * (index / pictureCount);

    if (index < pictureCount) {
      const picture = this._picsToRemove[index];
      PictureHttpClient.delete(picture.id)
        .then(() => this.deletePictures(index + 1, callback))
        .catch(() => this.deletePictures(index + 1, callback));
    } else {
      this.uploadPictures(0, callback);
    }
  };

  private uploadPictures = (index: number, callback?: () => void) => {
    const pictureCount = this.album.pictures.length;
    this.saveState.uploadPictureProgress = 100 * (index / pictureCount);

    if (index < pictureCount) {
      const picture = this.album.pictures[index];

      if (picture.id === null) {
        PictureHttpClient.post(picture, picture.picture)
          .then(() => this.uploadPictures(index + 1, callback))
          .catch(() => this.uploadPictures(index + 1, callback));
      } else {
        this.uploadPictures(index + 1, callback);
      }
    } else {
      this.updatePictures(0, callback);
    }
  };

  private updatePictures = (index: number, callback?: () => void) => {
    const pictureCount = this.album.pictures.length;
    this.saveState.updatePictureProgress = 100 * (index / pictureCount);

    if (index < pictureCount) {
      const picture = this.album.pictures[index];

      if (picture.id !== null) {
        PictureHttpClient.update(picture)
          .then(() => this.updatePictures(index + 1, callback))
          .catch(() => this.updatePictures(index + 1, callback));
      } else {
        this.updatePictures(index + 1, callback);
      }
    } else {
      this.postSave(callback);
    }
  };

  private postSave = (callback?: () => void) => {
    this.initialize();
    if (callback) {
      callback();
    }
  };
}

const sortPictures = (a: IPicture, b: IPicture) => {
  return a.sequence < b.sequence
    ? -1
    : a.sequence > b.sequence
    ? 1
    : a.id > b.id
    ? -1
    : 1;
};

const reSequencePicture = (
  picture: IPicture,
  index: number,
  oldPosition: number,
  newPosition: number
) => {
  picture.sequence = calculateNewSequence(
    index,
    picture.sequence,
    oldPosition,
    newPosition
  );
  return picture;
};

const calculateNewSequence = (
  index: number,
  oldSequence: number,
  movedFrom: number,
  movedTo: number
) => {
  if (index === movedFrom) {
    return movedTo;
  } else if (movedTo < movedFrom) {
    if (oldSequence >= movedTo && oldSequence < movedFrom) {
      return oldSequence + 1;
    }
  } else if (movedTo > movedFrom) {
    if (oldSequence <= movedTo && oldSequence > movedFrom) {
      return oldSequence - 1;
    }
  }

  return oldSequence;
};

const AlbumStoreContext = React.createContext<AlbumStore>(null);
const AlbumStoreProvider = AlbumStoreContext.Provider;
export { AlbumStoreContext, AlbumStoreProvider };
