import StripeStore from "./StripeStore";
import { observable } from "mobx";
import { IBillingProfile, IStripeSessionInfo } from "./models";
import BillingHttpClient from "./BillingHttpClient";

export default class BillingStore {
  private readonly _billingProfileID: number;

  stripeStore: StripeStore = null;

  @observable loading: boolean = false;
  @observable updatingStatus: boolean = false;
  @observable billingProfile: IBillingProfile;

  constructor(billingProfileID: number) {
    this._billingProfileID = billingProfileID;
    this.stripeStore = new StripeStore(billingProfileID);
    this.load();
  }

  setProfile = (profile: IBillingProfile) => {
    this.billingProfile = profile;
    this.loading = false;
    this.updatingStatus = false;
  };

  load = () => {
    this.loading = true;
    BillingHttpClient.retrieve().then(this.setProfile);
  };

  addStripePayment = (paymentMethodData: any) => {
    return this.stripeStore
      .afterPaymentMethodCreate(paymentMethodData)
      .then(this.setProfile);
  };

  refreshBackgroundFeeStatus = () => {
    this.updatingStatus = true;
    BillingHttpClient.refreshBackgroundFeeStatus().then(this.setProfile);
  };

  startSubscription = (priceID: string): Promise<IStripeSessionInfo> => {
    return BillingHttpClient.createSubscriptionCheckoutSession(priceID);
  };

  startBackgroundFee = (): Promise<IStripeSessionInfo> => {
    return BillingHttpClient.createBackgroundFeeCheckoutSession();
  };

  updatePurchases = (): Promise<boolean> => {
    this.updatingStatus = true;
    return BillingHttpClient.updateSubscriptionStatus().then(
      (success: boolean) => {
        this.updatingStatus = false;
        return success;
      }
    );
  };
}
