import React, { useContext } from "react";
import { StoreContext } from "../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import { Container, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

export default function SearchResultsHeader() {
  const store = useContext(StoreContext);
  const searchManager = store.sitterSearchManager;
  const searchResults = searchManager.searchResults;

  const onPageChange = (event: any, value: number) => {
    searchManager
      .setPage(value)
      .catch(() => alert("Request could not be completed."));
  };

  return useObserver(() => (
    <Container maxWidth={"md"}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant={"h5"} style={{ fontWeight: 100 }}>
            {searchManager.searchResults === null
              ? "Start searching..."
              : `Found ${searchResults.results.length} sitters`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {searchResults?.results.length > 0 && (
            <Pagination
              count={Math.ceil(
                searchResults.results.length / searchResults.page_size
              )}
              page={searchResults?.page_number}
              onChange={onPageChange}
              color={"primary"}
              style={{ float: "right" }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  ));
}
