import React, { useContext } from "react";
import { AlbumStoreContext } from "../../../storeroom/albums/AlbumStore";
import { Dialog, DialogProps } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useObserver } from "mobx-react";

export default function AlbumSaveModal() {
  const store = useContext(AlbumStoreContext);

  return useObserver(() => {
    const dialogProps: DialogProps = {
      open: store.saveState.saving,
      disableBackdropClick: true,
      fullWidth: true,
      maxWidth: "sm",
    };

    return (
      <Dialog {...dialogProps}>
        <DialogTitle>Saving Album</DialogTitle>

        <DialogContent>
          <Typography variant={"body1"}>Delete Removed Pictures</Typography>
          <LinearProgress
            variant={"determinate"}
            value={store.saveState.deletePictureProgress}
          />

          <div style={{ height: "2rem" }} />

          <Typography variant={"body1"}>Upload New Pictures</Typography>
          <LinearProgress
            variant={"determinate"}
            value={store.saveState.uploadPictureProgress}
          />

          <div style={{ height: "2rem" }} />

          <Typography variant={"body1"}>Update Sequencing</Typography>
          <LinearProgress
            variant={"determinate"}
            value={store.saveState.updatePictureProgress}
          />

          <div style={{ height: "2rem" }} />
        </DialogContent>
      </Dialog>
    );
  });
}
