import React, { useContext } from "react";
import { AlbumStoreContext } from "../../../../storeroom/albums/AlbumStore";
import { useDropzone } from "react-dropzone";
import { useObserver } from "mobx-react";

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export default function AlbumModalDropzone() {
  const store = useContext(AlbumStoreContext);
  const maxPicCount = store.getMaxPictureCount();

  const onDropAccept = (files: Array<File>) => {
    store.addPictures(files);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: maxPicCount - store.album.pictures.length,
    onDropAccepted: onDropAccept,
    disabled: store.album.pictures.length === maxPicCount,
  });

  return useObserver(() => {
    const dropZoneLabel =
      store.album.pictures.length >= maxPicCount
        ? "Limit reached"
        : "Drop new pictures here or click to open selector";

    return (
      <div>
        <div {...getRootProps({ style: baseStyle })}>
          <input {...getInputProps()} />
          <p style={{ textAlign: "center" }}>{dropZoneLabel}</p>
        </div>
      </div>
    );
  });
}
