import { action, observable } from "mobx";
import {
  IVacancySearchParams,
  IVacancySearchResultDetails,
  IVacancySearchResults,
} from "./models";
import { HttpClient } from "../../HttpClient";
import moment from "moment";

export class VacancySearchStore {
  @observable searchParams: IVacancySearchParams = undefined;
  @observable searchResults: IVacancySearchResults = null;
  @observable searching: boolean = false;

  @action
  private setSearchParams = (newParams: IVacancySearchParams) => {
    this.searchParams = newParams;
  };

  @action
  setDestination = (newDestination: string) => {
    this.searchParams.destination = newDestination;
  };

  @action
  setStartDate = (newDate: string) => {
    this.searchParams.start_date = newDate;

    const startMoment = moment(newDate);
    const endMoment = moment(this.searchParams.end_date);
    if (endMoment <= startMoment) {
      this.searchParams.end_date = startMoment
        .add("1", "days")
        .format("YYYY-MM-DD");
    }
  };

  @action
  setEndDate = (newDate: string) => {
    this.searchParams.end_date = newDate;
  };

  @action
  private setSearchResults = (newResults: IVacancySearchResults) => {
    this.searchResults = newResults;
    this.searching = false;
  };

  load = (error?: (error: Response) => void) => {
    if (this.searchParams === undefined) {
      HttpClient.get<IVacancySearchParams>(
        "vacancies/search/get_search_params/"
      )
        .then((response) => this.setSearchParams(response))
        .catch(error);
    }
  };

  canSearch = () => {
    return (
      Boolean(this.searchParams.destination) &&
      Boolean(this.searchParams.start_date) &&
      Boolean(this.searchParams.end_date)
    );
  };

  search = () => {
    this.searching = true;
    return HttpClient.post<IVacancySearchResults>(
      "vacancies/search/run_search/",
      this.searchParams
    ).then(this.setSearchResults);
  };

  changePage = (newPage: number) => {
    const currentResults = JSON.parse(JSON.stringify(this.searchResults));
    currentResults.page_number = newPage;

    return HttpClient.post<IVacancySearchResults>(
      "vacancies/search/update_page_results/",
      currentResults
    ).then(this.setSearchResults);
  };

  loadResultDetails = (
    vacancyID: number
  ): Promise<IVacancySearchResultDetails> => {
    return HttpClient.get<IVacancySearchResultDetails>(
      `vacancies/search/${vacancyID}/details/`
    );
  };
}
