import { observable } from "mobx";
import { IListTrip, ITripListResponse } from "./models";
import TripsHttpClient from "./TripsHttpClient";

export default class TripListStore {
  @observable loading = false;
  @observable futureTrips: Array<IListTrip> = [];
  @observable pastTrips: Array<IListTrip> = [];

  constructor() {
    this.load();
  }

  private load = () => {
    this.loading = true;
    TripsHttpClient.list().then(this.postLoad);
  };

  private postLoad = (trips: ITripListResponse) => {
    this.futureTrips = trips.future;
    this.pastTrips = trips.completed;
    this.loading = false;
  };
}
