import React, { useState } from "react";
import Container, {
  ContainerProps,
} from "@material-ui/core/Container/Container";
import {
  ButtonProps,
  createStyles,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import BasePublicPage from "../BasePublicPage";
import { useMobile } from "../../hooks";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import { publicRoutePaths } from "../../../routes/PublicRoutes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signUpButton: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      },
    },
  })
);

interface IPriceCardProps {
  title: string;
  subtitle: string;
  annualMode?: boolean;
  monthlyPrice: number;
  monthlyPriceSubtitle: string;
  annualPrice: number;
  annualPriceSubtitle: string;
  annualChip: string;
}

const petParentCardProps: IPriceCardProps = {
  title: "Pet Parent",
  subtitle: "For pet owners",
  monthlyPrice: 36,
  monthlyPriceSubtitle: "",
  annualPrice: 369,
  annualPriceSubtitle: "",
  annualChip: "One Month Free",
};

const sitterCardProps: IPriceCardProps = {
  title: "Sytter",
  subtitle: "For traveling pet sitters\n",
  monthlyPrice: 0,
  monthlyPriceSubtitle: "",
  annualPrice: 0,
  annualPriceSubtitle: "",
  annualChip: "Always Free",
};

const bothCardProps: IPriceCardProps = {
  title: "Both",
  subtitle: "For both pet owners and sitters",
  monthlyPrice: 36,
  monthlyPriceSubtitle: "",
  annualPrice: 369,
  annualPriceSubtitle: "",
  annualChip: "One Month Free",
};

export default function PricesPage() {
  const isNarrow = useMobile();
  const [annualMode, setAnnualMode] = useState(false);

  return (
    <BasePublicPage>
      <HeaderSection annualMode={annualMode} updateAnnualMode={setAnnualMode} />

      <Container maxWidth={"md"} style={{ marginBottom: "3rem" }}>
        <Grid container spacing={isNarrow ? 4 : 3}>
          <PetParentCard annualMode={annualMode} {...petParentCardProps} />
          <PetParentCard annualMode={annualMode} {...sitterCardProps} />
          <PetParentCard annualMode={annualMode} {...bothCardProps} />
        </Grid>
      </Container>
    </BasePublicPage>
  );
}

interface IHeaderSectionProps {
  annualMode: boolean;
  updateAnnualMode: (annual: boolean) => void;
}

function HeaderSection(props: IHeaderSectionProps) {
  const isNarrow = useMobile();

  const typographyProps: TypographyProps = {
    variant: isNarrow ? "h5" : "h4",
    style: {
      fontFamily: "Playfair Display",
      fontWeight: 500,
    },
  };

  const containerProps: ContainerProps = {
    maxWidth: "md",
    style: {
      textAlign: "center",
      padding: isNarrow ? "4rem 1rem" : "6rem 1rem",
    },
  };

  const inactiveButtonProps: ButtonProps = {
    variant: "outlined",
    disableElevation: true,
  };

  const activeButtonProps: ButtonProps = {
    variant: "contained",
    color: "secondary",
    disableElevation: true,
    style: { fontWeight: 700 },
  };

  const monthlyButtonProps = props.annualMode
    ? inactiveButtonProps
    : activeButtonProps;

  const annualButtonProps = props.annualMode
    ? activeButtonProps
    : inactiveButtonProps;

  monthlyButtonProps.onClick = () => props.updateAnnualMode(false);
  annualButtonProps.onClick = () => props.updateAnnualMode(true);

  return (
    <Container {...containerProps}>
      <Typography {...typographyProps}>
        Become Part of the Jetsyt Community
      </Typography>

      <div style={{ height: "2rem" }} />

      <ButtonGroup size={"large"}>
        <Button {...monthlyButtonProps}>Monthly Billing</Button>
        <Button {...annualButtonProps}>Yearly Billing</Button>
      </ButtonGroup>
    </Container>
  );
}

function PetParentCard(props: IPriceCardProps) {
  const history = useHistory();

  const onSignUpClick = () => {
    history.push(publicRoutePaths.register());
  };

  const priceInfo = props.annualMode
    ? `$${props.annualPrice}/yr`
    : `$${props.monthlyPrice}/mo`;

  const classes = useStyles();

  return (
    <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
      <Paper elevation={4} style={{ padding: "2.5rem 1rem" }}>
        <Typography variant={"h5"}>{props.title}</Typography>

        <Typography variant={"body1"}>{props.subtitle}</Typography>

        <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
          <Button
            variant={"outlined"}
            className={classes.signUpButton}
            onClick={onSignUpClick}
          >
            Sign Up
          </Button>
        </div>

        <Typography variant={"h5"}>{priceInfo}</Typography>

        <Typography variant={"body1"}>{props.monthlyPriceSubtitle}</Typography>

        {props.annualMode && (
          <div>
            <div style={{ height: "1.5rem" }} />
            <Chip
              color={"primary"}
              label={<Typography variant={"h6"}>{props.annualChip}</Typography>}
            />
          </div>
        )}
      </Paper>
    </Grid>
  );
}
