import React, { useContext } from "react";
import { AlbumStoreContext } from "../../../../storeroom/albums/AlbumStore";
import { useObserver } from "mobx-react";
import { Skeleton } from "@material-ui/lab";
import Gallery from "react-photo-gallery";
import DialogContentText from "@material-ui/core/DialogContentText";

interface IProps {
  hideHelpText?: boolean;
}

export default function AlbumModalPreviewTab(props: IProps) {
  const store = useContext(AlbumStoreContext);

  return useObserver(() => {
    return (
      <div>
        {!props.hideHelpText && (
          <div style={{ margin: "2rem 0" }}>
            <DialogContentText>
              This is how the album will appear to other users. Try different
              browser sizes to preview how the album adapts.
            </DialogContentText>
          </div>
        )}

        {store.loading ? (
          <Skeleton variant={"rect"} height={250} animation={"wave"} />
        ) : (
          <Gallery photos={[...store.galleryItems]} />
        )}
      </div>
    );
  });
}
