import React, { useContext, useState } from "react";
import { Section, sectionBodyStyle } from "../../base/Section";
import { useObserver } from "mobx-react";
import { Typography } from "@material-ui/core";
import { StoreContext } from "../../../storeroom/StoreContext";
import SitterDescriptionForm from "../forms/SitterDescriptionForm";

export default function DescriptionSection() {
  const store = useContext(StoreContext);
  const sitter = store.sitterStore.sitter;

  const [showForm, setShowForm] = useState(false);

  return useObserver(() => (
    <Section
      header={"About Me"}
      actionIcon={"edit"}
      actionLabel={"Edit About Me"}
      onActionClick={() => setShowForm(true)}
      secondaryColor={true}
    >
      <SitterDescriptionForm
        show={showForm}
        onClose={() => setShowForm(false)}
      />
      <Typography style={sectionBodyStyle}>
        {sitter.sitter_description}
      </Typography>
    </Section>
  ));
}
