import React, { useEffect, useState } from "react";
import { IHome } from "../../../storeroom/homes/models";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useHomeStore } from "../../hooks";
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ISelectOption } from "../../../storeroom/models";
import { TextFieldChangeEvent } from "../../models";
import { deepCopy } from "../../../utils";

export interface IHomeConfigModalProps {
  show: boolean;
  onClose: () => void;
}

interface ITextFieldProps {
  defaultValue: any;
  onChange: (event: TextFieldChangeEvent) => void;
}

export default function HomeConfigModal(props: IHomeConfigModalProps) {
  const homeStore = useHomeStore();

  const [home, setHome] = useState<IHome>(null);
  const [error, setError] = useState<string>("");
  const [submitted, setSubmitted] = useState(false);
  const [homeTypeOptions, setHomeTypeOptions] = useState([]);

  useEffect(() => {
    if (props.show) {
      setHome(deepCopy(homeStore.home));
      setHomeTypeOptions(homeStore.getHomeTypeOptions());
    }
  }, [props.show, homeStore]);

  const finish = () => {
    setError("");
    setSubmitted(false);
    props.onClose();
  };

  const isValid = (): boolean => {
    const errors = [];

    if (!Boolean(home.home_type)) {
      errors.push("Home Type is required");
    }

    if (errors.length === 0) {
      return true;
    } else {
      setError(errors.join(". "));
      return false;
    }
  };

  const onError = () => {
    setError("Update failed");
    setSubmitted(false);
  };

  const onSubmit = () => {
    if (isValid()) {
      setSubmitted(true);
      homeStore.save(home).then(finish).catch(onError);
    }
  };

  const onHomeTypeChange = (event: any, newValue: string | ISelectOption) => {
    home.home_type = typeof newValue === "string" ? newValue : newValue?.value;
  };

  const onBedsChange = (event: TextFieldChangeEvent) => {
    home.beds = parseInt(event.target.value);
  };

  const onBedroomsChange = (event: TextFieldChangeEvent) => {
    home.bedrooms = parseInt(event.target.value);
  };

  const onBathroomsChange = (event: TextFieldChangeEvent) => {
    home.bathrooms = parseInt(event.target.value);
  };

  const getOptionLabel = (option: ISelectOption | string): string => {
    return typeof option === "string"
      ? homeStore.getHomeTypeDisplayName(option)
      : option.label;
  };

  const getOptionSelected = (
    option: ISelectOption,
    value: string | ISelectOption
  ) => {
    const selectedValue = typeof value === "string" ? value : value.value;
    return option.value === selectedValue;
  };

  const homeTypeSelectProps = {
    options: homeTypeOptions,
    defaultValue: home?.home_type,
    onChange: onHomeTypeChange,
    getOptionLabel: getOptionLabel,
    getOptionSelected: getOptionSelected,
    fullWidth: true,
    renderInput: (params: any) => <TextField {...params} label={"Type"} />,
  };

  return (
    <Dialog open={props.show} fullWidth maxWidth={"xs"}>
      <DialogTitle>Home Configuration</DialogTitle>

      <DialogContent>
        {Boolean(error) && <ErrorAlert error={error} />}

        {Boolean(home) ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete {...homeTypeSelectProps} />
            </Grid>

            <BedsField defaultValue={home.beds} onChange={onBedsChange} />

            <BedroomsField
              defaultValue={home.bedrooms}
              onChange={onBedroomsChange}
            />

            <BathroomsField
              defaultValue={home.bathrooms}
              onChange={onBathroomsChange}
            />
          </Grid>
        ) : (
          <div style={{ height: "2rem" }} />
        )}
      </DialogContent>

      <DialogActions style={{ padding: "1rem" }}>
        <Button disabled={submitted} onClick={finish} size={"small"}>
          Cancel
        </Button>

        <Button
          variant={"contained"}
          color={"primary"}
          disabled={submitted}
          onClick={onSubmit}
          size={"small"}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ErrorAlert({ error }: { error: string }) {
  return (
    <div>
      <Alert severity={"error"}>{error}</Alert>
      <div style={{ height: "1rem" }} />
    </div>
  );
}

function BedsField(props: ITextFieldProps) {
  return (
    <Grid item xs={4}>
      <TextField
        label={"Beds"}
        type={"number"}
        fullWidth
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </Grid>
  );
}

function BedroomsField(props: ITextFieldProps) {
  return (
    <Grid item xs={4}>
      <TextField
        label={"Bedrooms"}
        type={"number"}
        fullWidth
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </Grid>
  );
}

function BathroomsField(props: ITextFieldProps) {
  return (
    <Grid item xs={4}>
      <TextField
        label={"Bathrooms"}
        type={"number"}
        fullWidth
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </Grid>
  );
}
