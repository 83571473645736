import React from "react";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

export interface IThumbsCheckboxProps {
  label?: string;
  selected: boolean;
  onClick: () => void;
  secondary?: boolean;
}

export function ThumbsUpCheckbox(props: IThumbsCheckboxProps) {
  const color = props.secondary ? "secondary" : "primary";

  return (
    <FormControlLabel
      label={props.label}
      control={
        <Checkbox
          checked={props.selected}
          icon={<ThumbUpOutlinedIcon />}
          checkedIcon={<ThumbUpIcon color={color} />}
          onChange={props.onClick}
        />
      }
      style={{ marginRight: 0 }}
    />
  );
}

export function ThumbsDownCheckbox(props: IThumbsCheckboxProps) {
  const color = props.secondary ? "secondary" : "primary";

  return (
    <FormControlLabel
      label={props.label}
      control={
        <Checkbox
          checked={props.selected}
          icon={<ThumbDownOutlinedIcon />}
          checkedIcon={<ThumbDownIcon color={color} />}
          onChange={props.onClick}
        />
      }
      style={{ marginRight: 0 }}
    />
  );
}
