import React, { useContext } from "react";
import { StoreContext } from "../../../../storeroom/StoreContext";
import { useObserver } from "mobx-react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  createStyles,
  Grid,
  Theme,
  Icon,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Pagination, RatingProps, Skeleton } from "@material-ui/lab";
import { privateRoutePaths } from "../../../../routes/PrivateRoutes";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Rating from "@material-ui/lab/Rating";
import useTheme from "@material-ui/core/styles/useTheme";

const StyledRating = withStyles((theme: Theme) =>
  createStyles({
    iconFilled: {
      color: theme.palette.secondary.main,
    },
  })
)(Rating);

function PipeSeparatedList() {
  const theme = useTheme();

  const style: React.CSSProperties = {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  };

  return <span style={style}>&nbsp;|&nbsp;</span>;
}

export default function VacancySearchResultsSection() {
  const history = useHistory();
  const store = useContext(StoreContext);
  const searchStore = store.vacancySearchStore;
  const searchParams = searchStore.searchParams;
  const searchResults = searchStore.searchResults;

  const onPageChange = (event: any, value: number) => {
    searchStore
      .changePage(value)
      .catch(() => alert("Request could not be completed."));
  };

  const onCardClick = (vacancyID: number) => {
    history.push(
      `${privateRoutePaths.vacancySearchResult(vacancyID)}?destination=${
        searchParams.destination
      }&start=${searchParams.start_date}&end=${searchParams.end_date}`
    );
  };

  const ratingProps: RatingProps = {
    precision: 0.1,
    readOnly: true,
    size: "small",
  };

  return useObserver(() => (
    <Container
      maxWidth={"lg"}
      style={{ marginTop: "2em" }}
      disableGutters={true}
    >
      {searchStore.searching ? (
        <Grid container spacing={2}>
          {[1, 2, 3].map((elem: number) => (
            <Grid item xs={12} sm={6} lg={4} key={elem}>
              <Skeleton variant={"rect"} animation={"wave"} height={240} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {searchStore.searchResults?.page_results.map((result) => (
            <Grid item xs={12} sm={6} lg={4} key={result.vacancy_id}>
              <Card elevation={4}>
                <CardActionArea onClick={() => onCardClick(result.vacancy_id)}>
                  <CardMedia
                    component={"img"}
                    image={result.picture.url}
                    title={"Home"}
                    height={"240"}
                  />
                  <CardContent>
                    <Typography
                      variant={"body1"}
                      style={{ fontWeight: 700, whiteSpace: "nowrap" }}
                    >
                      {result.title}
                    </Typography>

                    <StyledRating {...ratingProps} value={result.rating} />
                    <div style={{ height: "0.5rem" }} />

                    <Typography variant={"body1"}>
                      {result.home_type} <PipeSeparatedList />{" "}
                      {result.pet_summary} <PipeSeparatedList />{" "}
                      <Icon style={{ fontSize: "1rem", lineHeight: 1.3 }}>
                        location_on
                      </Icon>
                      {` ${result.distance} miles`}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}

          <Grid xs={12}>
            <div style={{ height: "2rem" }} />
            {searchResults?.results.length > 0 && (
              <Pagination
                count={Math.ceil(
                  searchResults.results.length / searchResults.page_size
                )}
                page={searchResults?.page_number}
                onChange={onPageChange}
                color={"secondary"}
                style={{ float: "right" }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  ));
}
