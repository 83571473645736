import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { StoreContext } from "../../storeroom/StoreContext";
import { observer } from "mobx-react";
import AlbumSection from "../albums/AlbumSection";
import BasicPetInfoSection from "./sections/BasicPetInfoSection";
import PetDescriptionSection from "./sections/PetDescriptionSection";
import PetPageToolbar from "./toolbars/PetPageToolbar";

interface State {
  showDetailsForm: boolean;
  showDescriptionForm: boolean;
}

@observer
class PetPage extends React.Component<any, State> {
  static contextType = StoreContext;

  constructor(props: any) {
    super(props);

    this.state = {
      showDetailsForm: false,
      showDescriptionForm: false,
    };
  }

  initialize = () => {
    this.context.petStore.loadPet(this.props.match.params.id);
  };

  componentDidMount(): void {
    this.initialize();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initialize();
    }
  }

  render() {
    const pet = this.context.petStore.pet;
    if (pet === undefined) {
      return (
        <div>
          <PetPageToolbar pet={null} />
        </div>
      );
    } else if (pet.id === null) {
      return <Redirect to={"/pets"} />;
    }

    return (
      <div>
        <PetPageToolbar pet={pet} />
        <BasicPetInfoSection />
        <PetDescriptionSection />

        <AlbumSection
          album={pet.album}
          headerOverride={`Pictures of ${pet.name}`}
        />
      </div>
    );
  }
}

export default withRouter(PetPage);
