import React, { FormEvent, useState } from "react";
import { AppBar, createStyles, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { useConversationStore } from "../hooks";
import { useObserver } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: "auto",
      bottom: 0,
      paddingBottom: "0.5em 0",
    },
  })
);

export default function SendMessageForm() {
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down("xs"));
  const conversationStore = useConversationStore();

  const [message, setMessage] = useState("");

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!conversationStore.sendingMessage) {
      onTextSubmit();
    }
  };

  const onTextSubmit = () => {
    conversationStore
      .addTextMessage(message)
      .then(() => setMessage(""))
      .catch(() => {
        alert("Message not sent");
        conversationStore.sendingMessage = false;
      });
  };

  const onImageSelect = (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      conversationStore.addImageMessage(file).catch(() => {
        alert("Image not sent");
        conversationStore.sendingMessage = false;
      });
    }
  };

  return useObserver(() => (
    <AppBar
      position={"fixed"}
      elevation={4}
      className={classes.appBar}
      color={"default"}
    >
      <Toolbar>
        <Container maxWidth={"md"} disableGutters={true}>
          <form autoComplete={"off"} onSubmit={onFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={isNarrow ? 2 : 1}>
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  disabled={conversationStore.sendingMessage}
                  onClick={() =>
                    document.getElementById("pictureSelect").click()
                  }
                >
                  <Icon>image</Icon>
                </Button>
              </Grid>

              <Grid item xs={isNarrow ? 7 : 9}>
                <TextField
                  value={message}
                  placeholder={"Send message..."}
                  fullWidth
                  color={"secondary"}
                  onChange={(e) => setMessage(e.target.value)}
                  disabled={conversationStore.sendingMessage}
                />
              </Grid>

              <Grid item xs={isNarrow ? 3 : 2}>
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  endIcon={<Icon>send</Icon>}
                  onClick={onTextSubmit}
                  disabled={conversationStore.sendingMessage}
                >
                  Send
                </Button>
              </Grid>
            </Grid>

            <input
              id={"pictureSelect"}
              hidden
              type={"file"}
              onChange={onImageSelect}
              accept={"image/png, image/jpeg"}
            />
          </form>
        </Container>
      </Toolbar>
    </AppBar>
  ));
}
