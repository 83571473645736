import React from "react";
import "./App.css";
import { AuthStoreProvider, IAuthStoreContext } from "./storeroom/StoreContext";
import AppRouter from "./routes/AppRouter";
import Theme from "./Theme";
import { AuthStore } from "./storeroom/auth/AuthStore";
import MaintenanceModePage from "./showroom/base/static/MaintenanceModePage";
import RegistrationStore from "./storeroom/users/registration/RegistrationStore";
import { useDocumentTitle } from "./showroom/hooks";

function App() {
  useDocumentTitle("Jetsyt");

  if (process.env.REACT_APP_MAINTENANCE_MODE === "1") {
    return (
      <Theme>
        <MaintenanceModePage />
      </Theme>
    );
  } else {
    const authStore: IAuthStoreContext = {
      authStore: new AuthStore(),
      registrationStore: new RegistrationStore(),
    };

    return (
      <AuthStoreProvider value={authStore}>
        <Theme>
          <AppRouter />
        </Theme>
      </AuthStoreProvider>
    );
  }
}

export default App;
