import React from "react";
import { useHistory } from "react-router-dom";
import { ICrumbProps } from "../base/Crumbs";
import { IPageToolbarAction } from "../base/PageToolbarActions";
import PageToolbar from "../base/PageToolbar";

export default function HomePageToolbar() {
  const history = useHistory();

  const crumbs: ICrumbProps = {
    currentLabel: "My Home",
  };

  const actions: Array<IPageToolbarAction> = [
    {
      label: "Pets",
      icon: "pets",
      onClick: () => history.push("/pets"),
    },
    {
      label: "Listing",
      icon: "wysiwyg",
      onClick: () => history.push("/listing"),
    },
  ];

  return <PageToolbar crumbs={crumbs} actions={actions} />;
}
