import React from "react";
import { AppBar, Container, Toolbar } from "@material-ui/core";
import Crumbs, { ICrumbProps } from "./Crumbs";
import PageToolbarActions, { IPageToolbarAction } from "./PageToolbarActions";

export interface PageToolbarProps {
  crumbs: ICrumbProps;
  actions: Array<IPageToolbarAction>;
  children?: any;
  secondary?: boolean;
}

export default function PageToolbar(props: PageToolbarProps) {
  return (
    <AppBar
      position={"sticky"}
      style={{ marginBottom: "2em" }}
      elevation={4}
      color={props.secondary ? "secondary" : "primary"}
    >
      <Container maxWidth={"md"} disableGutters={true}>
        <Toolbar>
          {props.children}
          <Crumbs {...props.crumbs} />
          <div style={{ flexGrow: 1 }} />
          <PageToolbarActions
            actions={props.actions}
            secondary={props.secondary}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
