import React from "react";
import { useGuestReservation } from "../hooks";
import { Section, sectionBodyStyle } from "../../base/Section";
import { Typography } from "@material-ui/core";
import AlbumGallery from "../../albums/AlbumGallery";
import { IReservationPet } from "../../../storeroom/reservations/models";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import AlbumSection from "../../albums/AlbumSection";

function TextSection(props: {
  label: string;
  message?: string;
  children?: any;
}) {
  return (
    <Section header={props.label}>
      <Typography style={sectionBodyStyle}>{props.message}</Typography>
      {props.children}
    </Section>
  );
}

function MiniAlbumSection(props: {
  label: string;
  album: number;
  children?: any;
}) {
  return (
    <Section header={props.label}>
      <AlbumGallery albumID={props.album} />
      {props.children}
    </Section>
  );
}

export function HomeAlbumSection() {
  const reservation = useGuestReservation();
  const album = reservation.listing.home.album;
  return (
    <AlbumSection
      headerOverride={"Pictures of Home"}
      album={album}
      viewOnly={true}
      secondary={true}
    />
  );
}

export function HomeAddressSection() {
  const reservation = useGuestReservation();
  const { address1, address2, city, state, zip } = reservation.listing.home;
  const address = `${address1} ${address2}, ${city}, ${state}  ${zip}`;

  return <TextSection label={"Address"} message={address} />;
}

export function CheckInInstructionsSection() {
  const reservation = useGuestReservation();
  const checkIn = reservation.listing.home.check_in_instructions;
  return <TextSection label={"Checking In"} message={checkIn} />;
}

export function DescriptionSection() {
  const reservation = useGuestReservation();
  const description = reservation.listing.description;
  return <TextSection label={"About the Home"} message={description} />;
}

export function RoomAndBedSection() {
  const reservation = useGuestReservation();
  const { beds, bedrooms, bathrooms } = reservation.listing.home;
  return (
    <TextSection label={"Room and Bed"}>
      <Typography variant={"body1"}>Beds: {beds}</Typography>
      <Typography variant={"body1"}>Bedrooms: {bedrooms}</Typography>
      <Typography variant={"body1"}>Bathrooms: {bathrooms}</Typography>
    </TextSection>
  );
}

export function HomeAmenitiesSection() {
  const reservation = useGuestReservation();
  const amenities = reservation.listing.home.amenities;
  return (
    <Section header={"Amenities"}>
      <List>
        {amenities.map((amenity) => (
          <ListItem key={amenity.id}>
            <ListItemIcon>
              <Icon>{amenity.icon}</Icon>
            </ListItemIcon>
            <ListItemText>{amenity.display_name}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Section>
  );
}

export function NeighborhoodSection() {
  const reservation = useGuestReservation();
  const neighborhood = reservation.listing.neighborhood;
  return <TextSection label={"Neighborhood"} message={neighborhood} />;
}

export function PetCareSection() {
  const reservation = useGuestReservation();
  const routine = reservation.pets.routine;
  return <TextSection label={"Pet Care"} message={routine} />;
}

export function PetSection(props: { pet: IReservationPet }) {
  return (
    <MiniAlbumSection label={props.pet.name} album={props.pet.album}>
      <Typography variant={"body1"}>
        {props.pet.breed} ({props.pet.type})
      </Typography>

      <div style={{ height: "1rem" }} />

      <Typography variant={"body1"}>{props.pet.description}</Typography>
    </MiniAlbumSection>
  );
}

export function HostDescriptionSection() {
  const reservation = useGuestReservation();
  const host = reservation.host;
  return (
    <TextSection label={`About ${host.name}`} message={host.description} />
  );
}

export function HostContactSection() {
  const reservation = useGuestReservation();
  const host = reservation.host;
  return (
    <TextSection label={"Contact"}>
      <Typography variant={"body1"}>{host.phone_number}</Typography>
      <Typography variant={"body1"}>{host.email}</Typography>
    </TextSection>
  );
}

export function HomeRulesSection() {
  const reservation = useGuestReservation();
  return (
    <TextSection
      label={"Home Rules"}
      message={reservation.listing.home.home_rules}
    />
  );
}
